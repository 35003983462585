import { Image } from "@mantine/core";
import React from "react";


export default () => {
  let styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      paddingBottom: 10,
      paddingTop: 10,
      width: "100%",
      backgroundColor: "rgb(245, 245, 245)",

      position: "fixed",
      bottom: 0,
      flexWrap: "wrap",
      boxShadow: "rgb(229 229 229) 0px 1px 0px inset",
    },
  };

  return (
    <div className={"description"} style={styles.container}>
      <div
        style={{
          display: "flex",
          minWidth: 210,
          paddingTop: 5,
          paddingBottom: 5,
          justifyContent: "center",
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <a
            style={{ color: "#9ca6af" }}
            href={"https://bookitup.de/datenschutz"}
          >
            Datenschutz
          </a>{" "}
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center", color: "#9ca6af" }}>
          |
        </div>
        <div style={{ marginLeft: 10, alignSelf: "center" }}>
          <a
            style={{ color: "#9ca6af" }}
            href={"https://bookitup.de/impressum"}
          >
            Impressum
          </a>{" "}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          minWidth: 210,
          paddingTop: 5,
          paddingBottom: 5,
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center", alignSelf: "center" }}>
          <a href={"https://bookitup.de"} target={"_blank"}>
          <Image width={32} src={"/favicon/favicon-32.png"} />
          </a>
        </div>
        <div style={{ alignSelf: "center", color: "#9ca6af", marginLeft: 10 }}>
          <div>Powered by</div>
          <a
            style={{ color: "#9ca6af" }}
            href={"https://bookitup.de"}
            target={"_blank"}
          >
            bookitup
          </a>
        </div>
      </div>
    </div>
  );
};
