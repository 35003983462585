import React, {useContext, useEffect, useState} from "react";
import {fetchQuestionnaire} from "../../Service/restapi/sharedContractService";
import {LoadingAnimation} from "../../Service/ResourceAdapter";

export default ({resource, children, ...rest}) => {
    const [questionnaire, setQuestionnaire] = useState(undefined);

    useEffect(() => {
        getContract();
    }, [resource]);

    function getContract() {
        fetchQuestionnaire(resource.entityId).then(resp => {
            if (resp.ok) {
                resp.json().then(questionnaire => {
                    setQuestionnaire(questionnaire);
                })
            } else {
                console.error("could not fetch questionnaire " + resource.entityId);
            }
        }).catch(error => {
            console.error({error});
            console.error("could not fetch questionnaire " + resource.entityId);
        })
    }

    if (!questionnaire) {
        return <LoadingAnimation/>
    }

    return (
        React.cloneElement(children, {
            document: questionnaire,
            ...rest
        })
    )
}