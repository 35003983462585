import React, {forwardRef, useState} from "react";
import {Input, Label} from "reactstrap";
import {InputHelp} from "./InputHelp";
import {Trans, withI18n} from "@lingui/react";
import {dateFormatService} from "../../Service/DateFormatService";
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import de from 'date-fns/locale/de';
import PropTypes from "prop-types";

registerLocale('de', de)

Date.prototype.isValid = function () {
    // If the date object is invalid it
    // will return 'NaN' on getTime()
    // and NaN is never equal to itself.
    return this.getTime() === this.getTime();
};

Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.addHours = function(hours) {
    let date = new Date(this.valueOf());
    date.setHours(date.getHours() + hours);
    return date;
}

export const EditableDateInput = withI18n()(({finishCallback, value: initialValue, placeholder, i18n, patchField, left, dataTour, labelStyle, disabled, ...rest}) => {
    const [value, setValue] = useState(initialValue ? new Date(initialValue) : initialValue);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const ref = React.createRef()

    let styles = {
        error: {
            borderColor: "red",
        },
        input: {
            marginBottom: 0,
        },
        label: {
            color: "#8ca3b5",
            fontWeight: 800,
            ...labelStyle
        },
        inputContainer: {
            marginBottom: 9,
        },
        customInput: {
            width: 110,
        }
    };

    function save(val) {
        let dateVal;
        if (val == null) {
            dateVal = null;
        } else {
            dateVal = new Date(val);
            dateVal.setHours(2); // if date was deleted, hours is set to 00:00, that result in a day before on saving
        }

        setLoading(true);

        finishCallback({[patchField]: dateVal})
            .catch((error) => {
                setError(error.message);
            }).then(() => {
                setLoading(false);
            });

    }
    setDefaultLocale('de');

    const handleOnBlur = ({ target: { value } }) => {
        // TODO: handle e.g. 4.5.20 instead of 04.05.2020
        if (value === "" || !isValidDate(value)) {
            setValue(null);
            save(null);
        } else {
            setValue(dateFormatService.getDateOfDeLocale(value));
            save(dateFormatService.getDateOfDeLocale(value));
        }
    };

    // Validates that the input string is a valid date formatted as "dd.mm.yyyy"
    function isValidDate(dateString) {
        if (!/^\d{1,2}.\d{1,2}.\d{4}$/.test(dateString) && !/^\d{1,2}.\d{1,2}.\d{2}$/.test(dateString))
            return false;
        return true;
    }

    const CustomInput = forwardRef(({ value, onClick }, ref) => {
        const [tmpValue, setTmpValue] = useState(value);

        return (
            <Input
                data-testid={"customInput"}
                ref={ref}
                disabled={disabled}
                onKeyUp={e => {
                    switch (e.key) {
                        case 'Enter':
                            handleOnBlur(e)
                            break;
                        case "Escape":
                            finishCallback({[patchField]: initialValue}).catch((error) => setError(error.message)).then(() => setLoading(false));
                    }
                }}
                onBlur={tmpValue !== value ? handleOnBlur : null}
                onChange={(e) => {setTmpValue(e.target.value); }}
                value={tmpValue}
                className={loading ? "form-control loading" : "form-control"}
                style={styles.customInput}
                onClick={onClick}>

            </Input>
        );
    });

    return (
        <div style={styles.inputContainer} data-tour={dataTour}>
            <DatePicker dateFormat="P"
                        isClearable selected={value} onChange={(val) => {setValue(val); save(val)}}
                        onBlur={(e) => {}}
                        style={error ? {...styles.error, ...styles.input} : styles.input}
                        //onChangeRaw={event => console.log(event.target.value)}
                        popperPlacement={left ? "bottom-end" : "bottom-start"}

                        showYearDropdown
                        yearDropdownItemNumber={1}
                        showWeekNumbers
                        //onCalendarClose={handleCalendarClose}
                        //onCalendarOpen={handleCalendarOpen}
                        //strictParsing
                        customInput={<CustomInput ref={ref}/>}
                        openToDate={value}
                        scrollableYearDropdown
                        disabledKeyboardNavigation
                        {...rest}
            />

            <Label sm={12} style={styles.label}><Trans id={placeholder || ''}/></Label>
            {error ? <InputHelp errorDescription={error}/> : null}
        </div>
    )

});

EditableDateInput.propTypes = {
    value: PropTypes.string,
    finishCallback: PropTypes.func,
    autoFocus: PropTypes.bool,
};

EditableDateInput.defaultProps = {
    autoFocus: true,
};

/*
<UserAgent mobile tablet>
                <input className={"form-control"} type={"date"} onChange={(e) => {this.handleDayClick(e.target.value, this.props.name)}} value={value} placeholder={""} />
            </UserAgent>
            <UserAgent computer>
*/