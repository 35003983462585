import React from "react";
import PropTypes from "prop-types";
import {SimpleLabelWrapper} from "../Formatter/SimpleLabelWrapper";

/*
Editable hält den State, ob gerade editiert wird oder nicht. Je nachdem wird entweder der "label", oder der "edit" angezeigt.
Der "labelWrapper" ist was umschaltet - siehe dazu "SimpleLabelWrapper".
Das kriegt ne "startEditCallback" Methode rein gereicht, die dem Editable signalisiert das umgeschaltet werden soll.
*/

export class Editable extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            error: false
        }
    }

    render() {
        const {label, labelWrapper, edit, value, onChange, tabIndex, placeholder, inputField, remove, editOnly, patchField, ...rest} = this.props;
        const {editing} = this.state;

        if (this.state.error || editing || editOnly) {
            const innerProps = {
                finishCallback: (newValue) => {
                    if (value != newValue[patchField] && !(value === null && newValue[patchField] === "") && !(value === "" && newValue[patchField] === null)) {
                        return onChange(newValue).then(() => {
                            this.setState({editing: false, error: false});
                        }).catch((error) => {
                            this.setState({editing: true, error: true});
                            throw error;
                        });

                    } else {
                        this.setState({editing: false});
                        return Promise.resolve();
                    }


                },
                value: value,
                placeholder: placeholder,
                remove: remove,
                patchField: patchField,
                ...rest
            };

            let editInner;
            if (typeof edit === "object") {
                editInner = React.cloneElement(edit, innerProps, inputField);
            } else {
                let EditTag = edit;
                editInner = <EditTag {...innerProps}/>
            }

            return editInner;
        } else {
            const innerProps = {
                value: value,
                patchField: patchField,
                ...rest
            };

            let wrapperInner;
            if (typeof label === "object") {
                wrapperInner = React.cloneElement(label, innerProps);
            } else {
                let LabelTag = label;
                wrapperInner = <LabelTag {...innerProps}/>
            }

            // ----------------

            const outerProps = {
                placeholder: placeholder,
                tabIndex: tabIndex,
                startEditCallback: () => this.setState({editing: true})
            };

            let wrapperOuter;
            if (typeof labelWrapper === "object") {
                wrapperOuter = React.cloneElement(labelWrapper, outerProps, wrapperInner)
            } else {
                let LabelWrapper = labelWrapper;
                wrapperOuter = <LabelWrapper {...outerProps}>
                    {wrapperInner}
                </LabelWrapper>
            }

            return wrapperOuter;
        }
    }
}

Editable.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
    edit: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
    labelWrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
    placeholder: PropTypes.string
};

Editable.defaultProps = {
    labelWrapper: SimpleLabelWrapper,
    label: ({value}) => value
};