import {ajaxActions} from "./AjaxActions";

export const file = {
    download
};

function download(url, fileName) {

    let filename = "";
    if (fileName) {
        filename = fileName;
    }
    return ajaxActions.getBlobResponse(url).then((resp) => {
        resp.blob().then((blob) => {
            blobToFile(blob, resp.headers, filename)
        }).catch((resp) => {
            console.error("Could not load blob from response of url " + url);
            throw resp;
        }).finally(() => {
            return resp;
        })

    });
}

export const blobToFile = (blob, headers, filename) => {
    let disposition = headers?.get('Content-Disposition')
    if (disposition) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    let mediaType = "application/octet-stream";
    if (filename.includes(".pdf")) {
        mediaType = "application/pdf";
    }
    const url = window.URL.createObjectURL(new Blob([blob], {type: mediaType}));
    const link = document.createElement('a');
    link.href = url;
    if (filename === "") {
        filename = "Document.pdf"
    }
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

};


