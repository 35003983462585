import {ajaxActions} from "../AjaxActions";

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

export const fetchResource = (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId).then(resp => {
    return resp;
});

export const fetchResources = () => ajaxActions.get(BASE_URL + "/resources").then(resp => {
    return resp;
});

export const fetchResourceFormSubmission = (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/formSubmissions").then(resp => {
    return resp;
});

export const getProcessActions = (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/processActions").then(resp => {
    return resp;
});

export const putProcessAction = (resourceId, state) => ajaxActions.put(BASE_URL + "/resources/" + resourceId + "/processActions/" + state).then(resp => {
    return resp;
});

export const patchResourceValues = (resourceId, patch) => ajaxActions.patch(BASE_URL + "/resources/" + resourceId + "/values", patch).then(resp => {
    return resp;
});
