import React, {useState} from "react";
import {Label} from "reactstrap";
import {InputHelp} from "./InputHelp";
import PropTypes from "prop-types";
import {Trans} from "@lingui/react";

export const EditableTextBlockWrapper = ({finishCallback, value: initialValue, placeholder, patchField, ...rest}) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    let styles = {
        error: {
            borderColor: "red",
        },
        input: {
            marginBottom: 0,
        },
        label: {
            color: "#8ca3b5",
            fontWeight: 800,
        },
        inputContainer: {
            marginBottom: 9,
        }
    };

    return (
        <div style={styles.inputContainer}>
            <textarea rows={14} onBlur={() => {setLoading(true); finishCallback({[patchField]: value}).catch((error) => setError(error.message)).then(() => setLoading(false))}}
                   className={loading ? "loading form-control" : "form-control"}
                   style={error ? {...styles.error, ...styles.input} : styles.input}
                   onChange={e => setValue(e.target.value)}
                   onKeyUp={e => {
                       switch (e.key) {
                           case "Escape":
                               finishCallback({[patchField]: initialValue}).catch((error)  => setError(error.message)).then(() => setLoading(false));
                       }
                   }}
                   value={value != null ? value : ""} {...rest}/>
            <Label sm={12} style={styles.label}><Trans id={placeholder || ''}/></Label>
            {error ? <InputHelp errorDescription={error}/> : null}
        </div>
    )

};

EditableTextBlockWrapper.propTypes = {
    value: PropTypes.string,
    finishCallback: PropTypes.func,
    autoFocus: PropTypes.bool,
};

EditableTextBlockWrapper.defaultProps = {
    autoFocus: true,
};