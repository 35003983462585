import {Icon} from "../../Atoms/Icons";
import React from "react";


export const NavigationGroupIcon = ({isParent, active, value, collapsed}) => {

    let styles = {
        icon: {
            color: "#afaeae",
            alignSelf: "center",
            marginLeft: collapsed ? "auto" : "unset",
            marginRight: collapsed ? "auto" : "unset",
        },
        active: {
            color: "#8ca3b5",
            maxWidth: isParent ? "unset" : 30,
            flex: isParent ? "unset" : 1,
        }
    };

    return (
        <Icon style={active ? {...styles.icon, ...styles.active} : styles.icon} value={value}/>
    )
};