import React, {useState} from "react";
import {Tooltip} from "reactstrap";
import {withI18n} from "@lingui/react";

export const ToolTip = ({target, text, placement}) => {
    const [show, setShow] = useState(false);

    function toggle() {
        setShow(!show);
    }

    if (placement === undefined) {
        placement = "top";
    }

    return (
        <Tooltip placement={placement} isOpen={show} target={target}
                 toggle={toggle}>
            {text}
        </Tooltip>
    );
};

export const TransToolTip = withI18n()((props) => {
    return <ToolTip {...props} text={props.i18n._(props.text || '', props.values)}/>
});
