import {EditableProperty} from "./EditableProperty";
import PropTypes from "prop-types";

export const EditableFormAttributeValueProperty = ({entity, formSubmissionId, changeHandler, patchField, placeholder, editFormatter, labelFormatter, name, ...rest}) => {

    return <EditableProperty
        dto={entity}
        changeHandler={changeHandler}
        patchField={patchField}
        placeholder={placeholder}
        targetUrl={process.env.REACT_APP_SHARE_SERVICE_URL + "/formSubmissions/" + formSubmissionId + "/values"}
        editFormatter={editFormatter}
        labelFormatter={labelFormatter}
        name={name}
        formSubmissionId={formSubmissionId}
        {...rest}
    />
};

EditableFormAttributeValueProperty.propTypes = {
    patchField: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
    labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
    changeHandler: PropTypes.func
};