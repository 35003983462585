//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import {Trans} from "@lingui/react";
import {LoadingAnimation} from "../Service/ResourceAdapter"
import {TransButtonDefault, TransButtonPrimaryWithSpinner} from "../Atoms/Buttons";
import {fetchFile} from "../Service/restapi/fileShareService";
import React, {useEffect, useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import { Space } from "@mantine/core";
import { blobToFile } from "../Service/FileActions";

export default ({entityType, entityId, fileName}) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState(undefined);

    function onDocumentLoadSuccess(numPages) {
        setNumPages(numPages._pdfInfo.numPages);
    }

    function previousPage() {
        setPageNumber(pageNumber-1);
    }

    function nextPage() {
        setPageNumber(pageNumber+1);
    }

    useEffect(() => {
        fetchFile(entityType, entityId, fileName).then(resp => {
            if (resp.ok) {
                resp.blob().then(blob => {
                    setFile(blob);
                }).catch((error) => {
                    console.error("no blob file");
                    console.error({error});
                });
            } else {

            }
        }).catch(error => {
            console.error({error});
            console.error("Error while getting any response from server. Maybe no internet connection or server down.")
        })

    }, [entityType, entityId, fileName]);

    return (
        <div className={"view-file"}>
            {numPages > 0 && <span style={{textAlign: "center", display: "block"}}><Trans id={"common.loading.pdf.pagexofy"} values={{0: pageNumber, 1: numPages}}/></span>}
            {pageNumber > 1 && <TransButtonDefault onClickHandler={previousPage} text={"actions.prev.page"}/>}{' '}
            {pageNumber < numPages && <TransButtonDefault style={{float: "right"}} onClickHandler={nextPage} text={"actions.next.page"}/>}{' '}

            <br/><br/>
            {file === undefined && <div>Laden...</div>}
            {file !== undefined && <Document
                file = {file}
                loading={<LoadingAnimation />}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(e) => {
                    console.error(e);
                 }}
                error={<Trans id={"document.generate.error"}/>}
            >
                <Page pageNumber={pageNumber} width={1000}/>
            </Document>}
        <Space h="xl" />
        <center>
            <TransButtonPrimaryWithSpinner
                icon="DOWNLOAD"
                style={{ height: 50, marginLeft: 10, maxWidth: 400 }}
                onClickHandler={() => blobToFile(file, null, fileName)}
                text="Herunterladen"
            />
        </center>
        </div>
    );

};