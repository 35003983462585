const taxInPercentage = (tax) => {
  return 1 + tax / 100;
};

const roundPrice = (price) => {
  return Math.round(price * 100.0) / 100.0;
};

const calculateNetPrice = (grossPrice, tax) => {
  return roundPrice(grossPrice / taxInPercentage(tax));
};

const calculateNetGrossPrice = (taxType, value, tax) => {
  switch (taxType) {
    case "net":
      return calculateNetPrice(value, tax);
    default:
      return value;
  }
};

const getDiscountAmount = (discountType, discountValue, grossTotal) => {
  if (discountType === "ABSOLUTE") {
    return discountValue;
  }
  return grossTotal * (discountValue / 100);
};

const getDiscountPercentageValue = (
  discountType,
  discountValue,
  grossTotal
) => {
  if (discountType === "ABSOLUTE") {
    return discountValue / grossTotal;
  }
  return discountValue / 100;
};

const getDocumentSummary = (document, positions, values) => {
  if (!positions) {
    return null;
  }
  const { taxType, discount } = document;
  const { value: discountValue, type: discountType, description: discountDescription } = discount;
  let sum = 0;

  positions
    .filter((p) => !p.optional || values[p.customAttributeId] || values[p.id])
    .forEach((p) => {
      sum += taxType === "net" ? p.netPrice : p.price;
    });

  const discountPercentage = getDiscountPercentageValue(
    discountType,
    discountValue,
    sum
  );

  const vats = getVatTaxTotal(taxType, positions, discountPercentage, values);

  let tmp = 0;
  Object.keys(vats).forEach((key) => {
    tmp += roundPrice(vats[key][0]);
  });

  const discountAmount = sum * discountPercentage;
  const sumAfterDiscount = sum - discountAmount;
  const finalNetto = sumAfterDiscount - tmp;
  const finalBrutto = sumAfterDiscount + tmp;

  return {
    sum,
    discountAmount,
    sumAfterDiscount,
    vats,
    discountValue,
    discountType,
    finalNetto,
    finalBrutto,
    discountDescription
  };
};

const getVatTaxTotal = (taxType, positions, discountPercentage, values) => {
  const taxVatTotal = {};
  const documentPercentDiscount = 1 - discountPercentage;
  const positionsGroupByTaxRate = {};

  positionsGroupByTaxRate["7"] = positions.filter(
    (p) =>
      (!p.optional || values[p.customAttributeId] || values[p.id]) &&
      p.tax === 7
  );
  positionsGroupByTaxRate["19"] = positions.filter(
    (p) =>
      (!p.optional || values[p.customAttributeId] || values[p.id]) &&
      p.tax === 19
  );

  Object.keys(positionsGroupByTaxRate).forEach((taxRate) => {
    let overallPrice = 0;
    if (positionsGroupByTaxRate[taxRate].length > 0) {
      positionsGroupByTaxRate[taxRate].forEach(
        (p) =>
          (overallPrice += calculateNetGrossPrice(taxType, p.price, taxRate))
      );

      const roundedPrice = overallPrice * documentPercentDiscount;
      const tax = calculateTaxes(taxType, roundedPrice, taxRate);
      taxVatTotal[taxRate] = [tax, roundPrice];
    }
  });
  return taxVatTotal;
};

const calculateTaxes = (taxType, netOrGrossPrice, taxRate) => {
  const vatPercentage = taxRate / 100;
  const vat =
    taxType === "gross"
      ? netOrGrossPrice - netOrGrossPrice / (1 + vatPercentage)
      : netOrGrossPrice * vatPercentage;
  return roundPrice(vat);
};

export const DocumentService = {
  roundPrice,
  calculateNetPrice,
  getDiscountAmount,
  getDiscountPercentageValue,
  getVatTaxTotal,
  calculateNetGrossPrice,
  getDocumentSummary,
};
