import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {withI18n} from "@lingui/react";

/**
 * DateFormatter is a formatter which formats a date in the appropriate locate, but
 * returns a dash for empty or null values.
 */
export const DateFormatter = withI18n()(({value, i18n, big, disabled}) => {
    const format = i18n._("format.date.long");
    if (value !== "" && value !== null && value !== undefined) {
        value = moment(value).format(format);
    }

    return (
        <span className={"form-control " + (disabled ? "disabled" : "")} style={{height: big ? "100%" : "unset", width: 107}}>{(value === "" || value === null || value === undefined) ? "-" : value }</span>
    );
});

DateFormatter.propTypes = {
    value: PropTypes.string,
};