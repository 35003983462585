import { useContext, useEffect, useState } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import { FileShareS } from "../../Service/restapi/fileShareService";
import { Image, Paper } from "@mantine/core";
import { Icon } from "../../Atoms/Icons";
import Moment from "react-moment";
import { TransTextInputField } from "../../Atoms/InputFields";

const ProviderSignature = ({ signedAt }) => {
  const { contact } = useContext(ResourceContext);
  const [loading, setLoading] = useState(false);
  const [customSignature, setCustomSignature] = useState();

  let styles = {
    container: {
      marginTop: 20,
      marginBottom: 10,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    sign: {
      fontFamily: "SafiarSignature, Courier",
      // paddingTop: 0,
      fontSize: 32,
      height: 50,
      maxWidth: 250,
    },
  };

  useEffect(() => {
    setLoading(true);
    FileShareS.fetchSignature()
      .then((signature) => {
        if (signature) {
          setCustomSignature(signature);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  if (!contact) {
    return null;
  }

  if (loading) {
    return <LoadingAnimation style={{}} />;
  }
  const { firstName, lastName } = contact;

  return (
    <>
      <h5 style={{ marginBottom: 0 }}>{`${firstName} ${lastName}`}</h5>
      <center>
        <div style={{ marginLeft: 20, alignSelf: "center", height: 50 }}>
          <Icon value={"BOOKED"} style={{ color: "green" }} /> Unterzeichnet am
          {"  "}
          <Moment format="L">{signedAt}</Moment>
        </div>
      </center>
      {customSignature ? (
        <Paper mb="xl">
          <Image
            alt="partnerSign"
            width="100%"
            height={150}
            src={URL.createObjectURL(customSignature)}
            fit="contain"
          />
        </Paper>
      ) : (
        <TransTextInputField
          disabled
          style={{ marginRight: 10 }}
          inputStyles={styles.sign}
          name="sign"
          value={`${firstName} ${lastName}`}
          placeholder={`${firstName} ${lastName}`}
        />
      )}
    </>
  );
};

export default ProviderSignature;
