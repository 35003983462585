import React, { useContext } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import { isDefined } from "../../Utils/utils";

const ContactMailPhone = ({ inline, isCustomer, withPartner }) => {
  const { contact, customerContact, loading } = useContext(ResourceContext);
  const partner = customerContact ? customerContact.partner : undefined;

  if (loading) {
    return <LoadingAnimation />;
  }

  let styles = {
    listStyle: {
      display: inline ? "inline-flex" : "block",
      listStyleType: inline ? "none" : "none",
      marginBottom: 5,
      paddingLeft: 0,
      flexWrap: "wrap",
    },
    itemStyle: {
      marginRight: inline ? 20 : "unset",
      fontSize: 12,
    },
  };

  const { email, phoneNumber, phoneNumberAlt } = isCustomer
    ? customerContact
    : contact;
  return (
    <ul style={styles.listStyle}>
      {email !== null && (
        <li style={{ ...styles.itemStyle, listStyleType: "none" }}>
          <div>
            {email}
            {withPartner && partner && isDefined(partner.email) && ` und ${partner.email}`}
          </div>
        </li>
      )}
      {phoneNumber !== null && phoneNumber !== "" && (
        <li style={styles.itemStyle}>
          Tel: <a href={"tel:" + phoneNumber}>{phoneNumber}</a>
          {withPartner && partner && isDefined(partner.phoneNumber) && (
            <>
              {'  '}und <a href={"tel:" + partner.phoneNumber}>{partner.phoneNumber}</a>
            </>
          )}
        </li>
      )}
      {phoneNumberAlt && (
        <li style={styles.itemStyle}>
          Phone: <a href={"tel:" + phoneNumberAlt}>{phoneNumberAlt}</a>
        </li>
      )}
    </ul>
  );
};

export default ContactMailPhone;
