import React from "react";
import {Spinner} from "reactstrap";
import {Icon} from "../Icons";
import {withI18n} from "@lingui/react";
import {TransToolTip} from "./ToolTip";

const TooltipButton = ({disabled, className, id, onClick, icon, style, text, placement, isLoading}) => {

    let styles = {
        button: {
            cursor: "pointer",
            padding: "5px !important",
            alignSelf: "center",
        }
    };

    return (
        <span style={styles.button}>
            <span id={'Tooltip-' + id} className={className +  " btn btn-icon"}
                             color={"icon"} disabled={disabled}
                  onClick={onClick? onClick : null} style={{...style}}>
                {isLoading ? <Spinner size="sm" color="dark" /> : (icon ? <Icon
                    style={{height: "100%"}} value={icon}/> : null)}
            </span>

            <TransToolTip text={text} placement={placement} target={'Tooltip-' + id} />

        </span>
    );
};

export default withI18n()((props) => {
    return <TooltipButton {...props} text={props.i18n._(props.text || '', props.values)}/>
});
