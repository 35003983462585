import ContractParties from "./ContractParties";
import ContactInfo from "../Contact/ContactInfo";

const ContractHead = ({ contract, resource }) => {
  return (
    <>
      {contract.contractHead === "PARTIES" && (
        <ContractParties contract={contract} resourceId={resource.id} />
      )}
      {contract.contractHead === "LETTERHEAD" && (
        <ContactInfo addressOnly={true} isCustomer />
      )}
    </>
  );
};

export default ContractHead;
