import AnswerList from "./AnswerList";

export default ({
  formSubmission,
  question,
  values,
  handleChange,
  columnStyles,
}) => {
  let styles = {
    container: {
      marginBottom: 5,
    },
    label: {
      fontWeight: 600,
    },
    subtext: {},
    firstColumn: {
      ...columnStyles.firstColumn,
    },
    textColumn: {
      ...columnStyles.textColumn,
    },
    priceColumn: {
      ...columnStyles.priceColumn,
    },
  };

  let patchField = question.customAttributeId
    ? question.customAttributeId
    : question.id;
  return (
    <div style={styles.container}>
      <div
        style={styles.subtext}
        dangerouslySetInnerHTML={{ __html: question.question }}
      />

      {question.description && question.description !== "" && (
        <p
          style={{ color: "gray", fontSize: 12 }}
          dangerouslySetInnerHTML={{ __html: question.description }}
        />
      )}

      <AnswerList
        answers={question.answer}
        additionalAnswer={question.additionalAnswer}
        questionId={question.id}
        patchField={patchField}
        formSubmission={formSubmission}
        values={values}
        handleChange={handleChange}
        attributeType={question.attributeType}
      />
    </div>
  );
};
