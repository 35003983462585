import React, {useState} from "react";
export const FocusedNodeContext = React.createContext(undefined);

export const FocusedNodeContextProvider = (props) => {

    const [focusedNode, setFocusedNode] = useState(undefined);
    const [listPosition, setListPosition] = useState(undefined);

    return (
        <FocusedNodeContext.Provider value={{
            focusedNode: focusedNode,
            setFocusedNode: (node) => {
                setFocusedNode(node);
            },
            listPosition: listPosition,
            setListPosition: (position) => {
                setListPosition(position)
            }
        }}>
            {props.children}

        </FocusedNodeContext.Provider>
    )
};