import React from "react";
import PropTypes from "prop-types";

/**
 * StringFormatter is a formatter which simply passes strings trough, but
 * returns a dash for empty or null values.
 */
export const StringFormatter = ({value, style, disabled}) => {
    let styles = {
        span: {
            height: "100%",
            ...style
        }
    };
    return (
        <span className={"form-control " + (disabled ? "disabled" : "")} style={styles.span}>{(value === "" || value === null || value === undefined) ? "-" : value}</span>
    )
};

StringFormatter.propTypes = {
    value: PropTypes.string,
};