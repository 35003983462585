import React, {useEffect, useState} from "react";
import PrevNextButtons from "../Molecules/PrevNextButtons";
import en from "../locales/en/messages";
import de from "../locales/de/messages";
import {I18nProvider} from "@lingui/react";
import PdfViewerExternNavigation from "../Molecules/PdfViewerExternNavigation";

const FrontBackPageHelper = ({resource, document, children, ...rest}) => {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [frontCoverPageCount] = useState(document.frontCoverPageCount ? document.frontCoverPageCount : 0);
    const [backCoverPageCount] = useState(document.backCoverPageCount ? document.backCoverPageCount : 0);

    useEffect(() => {
        let pages = 1;
        if (document.frontCoverFileName) {
            pages += document.frontCoverPageCount;
        }
        if (document.backCoverFileName) {
            pages += document.backCoverPageCount;

        }
        setTotalPages(pages);
    }, [])

    if (frontCoverPageCount >= currentPage) {
        return <>
            {document.frontCoverFileName && <PdfViewerExternNavigation currentPage={currentPage} entityType={"profiles"} entityId={document.profileId}
                                                                       fileName={document.frontCoverFileName}/>}

            <PrevNextButtons totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
    }

    if ((currentPage > frontCoverPageCount+1) && (currentPage <= totalPages)) {
        return <>

        {document.backCoverFileName && <PdfViewerExternNavigation currentPage={currentPage - (1 + frontCoverPageCount)} entityType={"profiles"} entityId={document.profileId}
                                                                  fileName={document.backCoverFileName}/>}
            <PrevNextButtons totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
    }

    return (
        <div>
            <>
                {React.cloneElement(children, {
                    resource: resource,
                    document: document,
                    ...rest
                })}
                {totalPages !== 1 && <PrevNextButtons totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>}
            </>
        </div>
    )

}

export default FrontBackPageHelper;