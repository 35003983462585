import { useContext, useRef, useState } from "react";
import { TransButtonDefault } from "../../Atoms/Buttons";
import { WindowContext } from "../../Service/Context/WindowContext";
import CanvasDraw from "@win11react/react-canvas-draw";

const ContractDrawSign = ({ onChange }) => {
  const canvasRef = useRef(null);
  const { windowWidth } = useContext(WindowContext);

  function clear() {
    canvasRef.current.clear();
    onChange(undefined)
  }

  function undo() {
    canvasRef.current.undo();
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <CanvasDraw
        ref={canvasRef}
        onChange={() => onChange(canvasRef.current.canvasContainer.children[1].toDataURL())}
        canvasWidth={"100%"}
        lazyRadius={0}
        loadTimeOffset={0}
        canvasHeight={200}
        brushRadius={2}
        style={{
          boxShadow:
            "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)",
        }}
      />
      <center style={{ marginTop: 15 }}>
        <TransButtonDefault
          style={{ marginRight: 5 }}
          text={windowWidth > 500 ? "Unterschrift löschen" : "Löschen"}
          onClickHandler={clear}
        />
        <TransButtonDefault
          text={windowWidth > 500 ? "Rückgängig machen" : "Rückgängig"}
          onClickHandler={undo}
        />
      </center>
    </div>
  );
};

export default ContractDrawSign;
