import React from "react";

export const NavigationGroupText = ({value, isParent}) => {


    let styles = {
        subListItemText: {
            padding: isParent ? "unset" : "2px 15px",
            fontSize: (isParent) ? 14 : "unset",
            marginLeft: isParent ? 12 : "unset",
        },
    };

    return (
        <span style={{...styles.subListItemText, flex: 1}}>{value}</span>
    )
}