import React, { useContext } from "react";
import { EditableCheckbox } from "../../Atoms/inputs/EditableCheckbox";
import { EditableFormAttributeValueProperty } from "../../Atoms/EditableProperties/EditableFormAttributeValueProperty";
import { WindowContext } from "../../Service/Context/WindowContext";
import { DocumentService } from "../../Service/DocumentService";
import { FormatS } from "../../Service/FormatS";
import { Group, Text } from "@mantine/core";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  formSubmission,
  position,
  taxType,
  values,
  handleChange,
  columnStyles,
}) => {
  const { windowWidth } = useContext(WindowContext);
  const { discount, priceUnit, price, netPrice, tax } = position;
  const { amount: discountAmount } = discount;

  let styles = {
    container: {
      marginBottom: 5,
      display: "flex",
    },
    label: {
      fontWeight: 600,
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
    subtext: {},
    firstColumn: {
      ...columnStyles.firstColumn,
    },
    textColumn: {
      ...columnStyles.textColumn,
    },
    priceColumn: {
      ...columnStyles.priceColumn,
    },
  };

  let patchField = position.customAttributeId
    ? position.customAttributeId
    : position.id;
  let keys = [];
  let obj;

  for (obj in values) {
    if (values[obj]) {
      keys.push(obj);
    }
  }

  return (
    <>
      <div style={styles.container}>
        <div style={styles.firstColumn}>
          {position.optional && (
            <EditableFormAttributeValueProperty
              formSubmissionId={formSubmission.id}
              patchField={patchField}
              changeHandler={(p) => {
                handleChange(patchField, p[patchField]);
              }}
              entity={values}
              values={keys}
              labelValue={position.id + ""}
              editFormatter={EditableCheckbox}
              multiple={false}
              editOnly={true}
              disabled={formSubmission.lastSubmitted}
            />
          )}
        </div>
        <div style={styles.textColumn}>
          <div style={styles.label}>
            <div dangerouslySetInnerHTML={{ __html: position.label }} />
          </div>
          <div
            className={"description"}
            style={styles.subtext}
            dangerouslySetInnerHTML={{ __html: position.subtext }}
          />
        </div>
        {windowWidth > 800 && (
          <div style={styles.priceColumn}>
            {position.amount} {position.unit}
          </div>
        )}
        {windowWidth > 800 && (
          <div style={styles.priceColumn}>
            {priceUnit && (
              <div>
                {FormatS.formatNumberCurrencyWithUnitSign(
                  DocumentService.calculateNetGrossPrice(
                    taxType,
                    priceUnit,
                    tax
                  )
                )}
              </div>
            )}
          </div>
        )}
        <div style={styles.priceColumn}>
          <div>
            <b>
              {FormatS.formatNumberCurrencyWithUnitSign(taxType === 'net' ? netPrice : price)}
            </b>
          </div>
        </div>
      </div>
      {discountAmount > 0 && (
        <Group position="right">
          <Text
            size="xs"
          >{`Rabatt: ${FormatS.formatNumberCurrencyWithUnitSign(
            discountAmount
          )}`}</Text>
        </Group>
      )}
    </>
  );
};
