import {TransButtonPrimaryWithSpinner} from "../Atoms/Buttons";
import React, {useContext, useState} from "react";
import {file} from "../Service/FileActions";

export default ({formSubmission, text}) => {
    const [loading, setLoading] = useState(false);

    function downloadPdf() {
        setLoading(true);
        file.download(process.env.REACT_APP_SHARE_SERVICE_URL + "/formSubmissions/" + formSubmission.id + "/pdf", "Dokument.pdf").then(resp => {
            if (resp.ok) {

            } else {
                console.error("could not download pdf of formSubmission " + formSubmission.id);
            }
        }).catch(error => {
            console.error({error})
            console.error("could not download pdf of formSubmission " + formSubmission.id);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <center>
            <TransButtonPrimaryWithSpinner icon="DOWNLOAD" isLoading={loading} style={{height: 50, marginLeft: 10, maxWidth: 400 }} onClickHandler={downloadPdf} text={text}/>
        </center>
    )
}