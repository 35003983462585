import React, { useEffect, useState } from "react";
import { useJwtCookie } from "../Service/CookiesProvider";

const PageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = useJwtCookie(); 
  const [fixPageHeader, setFixPageHeader] = useState<boolean>(false);

  const handleScroll = (e: any) => {
    if (e.target.documentElement.scrollTop > 50) {
      setFixPageHeader(true)
    } else {
      setFixPageHeader(false)
    }
  }
  // empty deps -> only called once on mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // return fn that will be called on unmounting
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      document.body.classList.remove("loginarea");
    } else {
      document.body.classList.add("loginarea");
    }
  }, [isAuthenticated]);

  return (
    <div 
      className={!isAuthenticated? 
      'loginarea' : 'wrap fix-page-header'
    }>
      { children }
    </div>
  );
}

export default PageContainer;