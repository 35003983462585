import React, { useEffect, useState } from "react";
import ContactInfo from "../Contact/ContactInfo";
import ContactInjectWrapper from "../Contact/ContactInjectWrapper";
import { fetchStandardLayoutContent } from "../../Service/restapi/profileService";

const ContractParties = ({ contract, resourceId }) => {
  const [standardLayoutContent, setStandardLayoutContent] = useState(undefined);

  useEffect(() => {
    fetchStandardLayoutContent(resourceId).then((resp) => {
      if (resp.ok) {
        resp.json().then(setStandardLayoutContent);
      }
    });
  }, [resourceId]);

  let styles = {
    container: {
      marginBottom: 20,
    },
    contacts: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  return (
    <div style={styles.container}>
      <div style={{ textAlign: "center", fontSize: 22 }}>
        Vertrag {contract.number ? "Nr. " + contract.number : ""}
      </div>
      <div
        className={"description"}
        style={{ textAlign: "center", color: "#000000" }}
      >
        zwischen
      </div>
      <div style={styles.contacts}>
        <div style={{ width: 300 }}>
          <ContactInjectWrapper
            contactId={contract.customerId}
            resourceId={resourceId}
          >
            <ContactInfo
              withPartner
              isCustomer
              alias={
                standardLayoutContent
                  ? standardLayoutContent.contractPartyEmployerDescription
                  : "Veranstalter"
              }
            />
          </ContactInjectWrapper>
        </div>
        <div
          className={"description"}
          style={{ color: "#000000", alignSelf: "center" }}
        >
          und
        </div>
        <div style={{ width: 300, textAlign: "right" }}>
          <ContactInjectWrapper
            contactId={contract.contactId}
            resourceId={resourceId}
          >
            <ContactInfo
              alias={
                standardLayoutContent
                  ? standardLayoutContent.contractPartyEmployeeDescription
                  : "Dienstleister (DL)"
              }
            />
          </ContactInjectWrapper>
        </div>
      </div>
    </div>
  );
};

export default ContractParties;
