import React, {useContext, useEffect, useState} from "react";
import {fetchContact} from "../../Service/restapi/contactService";

export default ({contactId, resourceId, children}) => {
    const [contact, setContact] = useState(undefined);

    useEffect(() => {
        fetchContact(contactId, resourceId).then(resp => {
            if (resp.ok) {
                resp.json().then(contact => {
                    setContact(contact);
                })
            }
        })
    }, [contactId, resourceId]);

    if (!contact) {
        return null;
    }

    return (
        React.cloneElement(children, {
            contact: contact
        })
    )
}