import React, {useContext, useState} from 'react';
import {slide as Menu} from 'react-burger-menu'
import './style.scss'
import {WindowContext} from "../../../Service/Context/WindowContext";
import {Icon} from "../../../Atoms/Icons";
import { UserS } from '../../../Service/restapi/UserS';

/**
 * https://github.com/negomi/react-burger-menu
 */

export const MobileMenu = ({whiteBackground, right, items}) => {
    const [isOpen, setOpen] = useState(false);
    const {windowWidth} = useContext(WindowContext);

    if (windowWidth >= 800) {
        return null;
    }

    let links = [];
    items.map((item, index) => {
        links.push(
            <a id={"menu-item-" + index} onClick={() => {item.onClick(); setOpen(false);}} className="menu-item" href="#">{item.text} {item.badge}</a>
        )
    });

    return (
        <div className={(whiteBackground ? "white-bg" : "") + (right ? " right" : "")}>
            <Menu right={right} isOpen={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    {links}
                </div>
                <div style={{display: "flex"}}>
                    <Icon style={{color: "#ffffff", marginTop: 15,}} value={"LOGOUT"} />
                    <a id={"logout"} onClick={() => {UserS.logout(); setOpen(false);}} className="menu-item" href="#">Abmelden</a>
                </div>
            </Menu>

        </div>
    )
};