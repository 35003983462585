import { ajaxActions } from "../AjaxActions";

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

export const fetchFile = (entityType, entityId, fileName) =>
  ajaxActions
    .fileRequest(BASE_URL + "/file", {
      entityType: entityType,
      entityId: entityId,
      fileName: fileName,
    })
    .then((resp) => {
      return resp;
    });

export const fetchLogo = (shareId) =>
  ajaxActions
    .fileRequest(
      BASE_URL + "/branding/logo" + (shareId ? "?shareId=" + shareId : "")
    )
    .then((resp) => {
      return resp;
    });

const fetchSignature = async () => {
  const res = await ajaxActions.fileRequest(BASE_URL + "/branding/signature");
  if (res.ok) {
    return res.blob();
  }
  return null;
};

export const FileShareS = {
  fetchSignature,
};
