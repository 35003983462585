import {ListGroup} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import {WindowContext} from "../../Service/Context/WindowContext";
import {NavigationGroup} from "./NavigationGroup";
import { UserS } from "../../Service/restapi/UserS";

export default ({items, children}) => {
    const [scrollWrap] = useState(React.createRef());
    const [scrollTop, setScrollTop] = useState(undefined);
    const {windowWidth, windowHeight} = useContext(WindowContext);
    const [collapsed, setCollapsed] = useState(windowWidth <= 575 ? true : false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    function handleScroll(e) {
        setScrollTop(e.target.documentElement.scrollTop);
    }

    useEffect(() => {
        if (windowWidth < 800) {
            setCollapsed(true);
        } else {
            setCollapsed(false)
        }
    }, [windowWidth]);

    let listItems = [];

    let styles = {

        pageLayout: {

        },
        contentWrapper: {

        },
        navSidebar: {
            marginLeft: windowWidth > 800 ? 0 : 0,
            width: collapsed ? 50 : (windowWidth > 1000 ? 210 : 170),
            position: "fixed",
            bottom: 0,
            left: 0,
            top: 0,
            zIndex: 1,
            marginTop: 0,
        },
        container: {
            height: "100%",
            width: "100%",
            position: "absolute",
            backgroundColor: "#f5f5f5",
            //boxShadow: "inset -1px 0 0 #e5e5e5",
            //boxShadow: "rgb(229, 229, 229) 0px -9px 9px 2px inset",
            boxShadow: "inset -1px 0 0 #e5e5e5",
        },

        collapseButton: {
           // width: collapsed ? "100%" : 34,
            alignSelf: "flex-end",
            textAlign: "center",
            bottom: 0,
            position: "fixed",
            width: collapsed ? 50 : (windowWidth > 1000 ? 210 : 170),
            borderTop: "1px solid #e5e5e5",
            height: 48,
            zIndex: 2,
        },





        listContainer: {
            flex: 1,
            width: collapsed ? 50 : (windowWidth > 1000 ? 210 : 170),
            transition: "width 0.3s, left 0.3s",
            paddingRight: 5,
            marginBottom: 47,
            marginTop: 0,
            overflowY: collapsed ? "unset" : "scroll",
            height: "100%",// windowHeight - 48 - 79 - 54, // - Einfahren-Button - Modul-Leiste - obere Nav-Leiste
            justifyContent: "space-between"
        },

        item: {
            padding: 0,
            textAlign: collapsed ? "center" : "left",
            borderColor: "#8ca3b5",
            backgroundColor: "transparent !important",
            borderRadius: 0,
        },


        listGroupHeaderLink: {
            display: "flex",
            padding: collapsed ? 6 : 10,
        },




        icon: {
            color: "#afaeae",
            alignSelf: "center",
            marginLeft: collapsed ? "auto" : "unset",
            marginRight: collapsed ? "auto" : "unset",
        },


        subListItemText: {
            padding: "2px 15px",
            marginBottom: 2,
            textAlign: "left",
            backgroundColor: "transparent !important",
        },




        subListItem: {
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
        },

        content: {
            flex: 1,
            marginRight: 5,
            marginLeft: collapsed ? 5 : (windowWidth > 1000 ? 220 : 180),
            maxWidth: windowWidth > 800 ? windowWidth - 90 : "unset",
            paddingTop: 10,
        },


    };



    items.map((value, index) => {

        listItems.push(
            <div key={index}>
                <NavigationGroup collapsed={collapsed} value={value} index={index} />
            </div>

        );
    });

    return (
        <div style={styles.pageLayout}>
            {(windowWidth >= 800 && listItems.length > 0) &&
                <div style={styles.navSidebar}>
                    <div className={"navigation-list"} style={styles.container}>
                        <ListGroup style={styles.listContainer}>

                            <div style={{display: "flex", flexDirection: "column"}}>
                                {listItems}

                                {/*<ListGroupItem id={"collapseMenu"} style={{...styles.item, ...styles.collapseButton}}
                                                onClick={() => setCollapsed(!collapsed)} tag="button" action>
                                    <div key={"collapse-button"} style={styles.listGroupHeaderLink}>
                                        <Icon style={styles.icon}
                                              value={collapsed ? "ANGLE_DOUBLE_RIGHT" : "ANGLE_DOUBLE_LEFT"}/>
                                        {(!collapsed) && <span
                                            style={styles.subListItemText}>{windowWidth > 1000 ? "Leiste einfahren" : "einfahren"}</span>}

                                    </div>
                                </ListGroupItem>*/}
                            </div>
                            <div>
                                <NavigationGroup collapsed={collapsed} value={{text: "Abmelden", icon: "LOGOUT", clickable: true, onClick: UserS.logout}} />
                            </div>

                            {/*<div>
                        <ListGroupItem style={{...styles.item, fontFamily: "RobotoRegular"}} onClick={() => window.open("https://bookitup.de/paket-buchen")} tag="button" action>
                            <div key={"upgrade-button"} style={styles.listGroupHeaderLink}>
                                <Icon style={styles.icon} value={"UPGRADE"}/>
                                {!collapsed && <span style={{ marginLeft: 5}}>{"Upgraden"}</span>}
                            </div>
                        </ListGroupItem>
                    </div>*/}
                        </ListGroup>

                    </div>


                </div>
            }

            <div style={styles.contentWrapper} ref={scrollWrap}>
                <div style={{width: "100%"}} >
                    <div style={styles.content} >
                        {children}
                    </div>
                </div>
            </div>
        </div>


    )
};