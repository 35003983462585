import {ListGroupItem} from "reactstrap";
import React from "react";
import {NavigationGroupIcon} from "./NavigationGroupIcon";
import {NavigationGroupCount} from "./NavigationGroupCount";
import {NavigationGroupText} from "./NavigationGroupText";

export const NavigationGroupParent = ({value, index, collapsed, click, style}) => {

    let styles = {
        // Erstes Element einer Gruppe
        listGroupHeader: {
            fontFamily: "RobotoRegular",
            cursor: "default"
        },
        listGroupHeaderLink: {
            display: "flex",
            padding: collapsed ? 3 : 10,
        },
        listGroupHeaderActive: {
            color: "#8ca3b5",
            fontWeight: 800,
            display: "flex",
            padding: collapsed ? 3 : 10,
        },
    };

    let groupActive = value.active;

    let opts = {};
    if (value.active) {
        opts['active'] = true;
    }
    if (value.disabled) {
        opts['disabled'] = true;
    }

    let groupStyles = {
        ...style,
        ...styles.listGroupHeader
    };
    if (value.clickable) {
        groupStyles = {
            ...groupStyles,
            cursor: "pointer"
        }
    }

    return (
        <ListGroupItem style={groupStyles} {...opts} onClick={() => {click(value)}} tag="button" action>
            <div key={"group-" + index} style={groupActive ? styles.listGroupHeaderActive : styles.listGroupHeaderLink}>
                <NavigationGroupIcon isParent collapsed={collapsed} active={groupActive} value={value.icon}/>

                {!collapsed && <NavigationGroupText value={value.text} isParent/>}

                {(!collapsed) && <NavigationGroupCount value={value.count}/>}
                {(!collapsed && value.badge) && value.badge}
            </div>
        </ListGroupItem>
    )
};