import React, {useContext, useEffect, useState} from "react";
import {fetchContract} from "../../Service/restapi/sharedContractService";
import {LoadingAnimation} from "../../Service/ResourceAdapter";

export default ({resource, children, ...rest}) => {
    const [contract, setContract] = useState(undefined);

    useEffect(() => {
        getContract();
    }, [resource]);

    function getContract() {
        fetchContract(resource.entityId).then(resp => {
            if (resp.ok) {
                resp.json().then(contract => {
                    setContract(contract);
                })
            } else {
                console.error("could not fetch contract " + resource.entityId);
            }
        }).catch(error => {
            console.error({error});
            console.error("could not fetch contract " + resource.entityId);
        })
    }

    if (!contract) {
        return <LoadingAnimation/>
    }

    return (
        React.cloneElement(children, {
            document: contract,
            ...rest
        })
    )
}