import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import {WindowContext} from "../Service/Context/WindowContext";

export default (props) => {
    const {windowWidth} = useContext(WindowContext);

    let styles = {
        headSpace: {
            marginTop: 50,
            paddingTop: 25,
        },
        head: {
            width: "100%",

            /*paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: windowWidth > 800 ? 40 : 15,
            paddingRight: windowWidth > 800 ? 40 : 15,*/

            backgroundColor: "rgb(251, 251, 251)",
            borderBottom: "1px solid #e5e5e5",
            borderTop: "1px solid #dfdfdf",
            //boxShadow: "0px 0px 3px 0px rgb(223, 223, 223)",
            zIndex: 1029,
            height: windowWidth < 800 ? 57 : 0,
            position: windowWidth < 800 ? "sticky" : "unset",
        },
        body: {
            marginLeft: windowWidth > 800 ? 0 : 0,
            marginRight: windowWidth > 800 ? 0 : 0,
        }
    };

    return (
        <div>
            <Helmet title={props.title}/>
            {!props.head && <div style={styles.headSpace}></div>}
            {props.head &&
                <div style={styles.head} className="page-header">
                    {props.head}
                </div>
            }
            <div style={styles.body}>
                {props.body}
            </div>
        </div>
    )
};