import React, {useContext, useEffect, useState} from "react";
import {fetchFile} from "../Service/restapi/fileShareService";
import ImageRender from "./ImageRender";

export default ({entityType, entityId, fileName}) => {
    const [blob, setBlob] = useState(undefined);

    useEffect(() => {
        fetchImage();
    }, [entityType, entityId, fileName]);

    function fetchImage() {
        fetchFile(entityType, entityId, fileName).then((response) => {
            return response.blob();
        }).then((blob) => {
            setBlob(blob);
        });
    }

    if (!blob) {
        return null;
    }

    return (
        <ImageRender blob={blob} />
    )


}