import {TransButtonDefaultWithIcon} from "../Atoms/Buttons";
import React, {useContext} from "react";
import {WindowContext} from "../Service/Context/WindowContext";


export default ({totalPages, currentPage, setCurrentPage}) => {
    const {windowWidth} = useContext(WindowContext);

    return (
        <div style={{display: "flex", justifyContent: "center", marginTop: 20,}}>
                { currentPage !== 1 &&
                    <div style={{marginRight: 10}}>
                            <TransButtonDefaultWithIcon onClickHandler={() => {setCurrentPage(currentPage - 1)}} icon={windowWidth > 500 ? "PREV" : ""} text={"actions.prev.page"}/>
                    </div>
                }


                {
                    currentPage < totalPages &&
                    <div>
                        <TransButtonDefaultWithIcon onClickHandler={() => {setCurrentPage(currentPage + 1)}} icon={windowWidth > 500 ? "NEXT" : ""} text={"actions.next.page"}/>
                    </div>
                }

        </div>
    )
}