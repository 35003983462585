import React, { useContext } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";

const BankAccount = ({inline, documentType}) => {
    const { bankAccount, documentLayout } = useContext(ResourceContext);
    if (!bankAccount) {
        return null;
    }
    const { nameOfBank, iban, bic} = bankAccount;
    const { hideBankAccount } = documentLayout;

    let styles = {
        listStyle: {
            display: inline ? "inline-flex" : "block",
            listStyleType: inline ? "none" : "none",
            marginBottom: 5,
            paddingLeft: 0,
            flexWrap: "wrap",
        },
        itemStyle: {
            marginRight: 20,
            fontSize: 12,
        }
    };
    if (documentType !== 'INVOICE' && documentType !== 'PARTIAL_INVOICE' && hideBankAccount) {
        return null;
    }
    return (
        <ul style={styles.listStyle}>
            {nameOfBank && <li style={{...styles.itemStyle, listStyleType: "none"}}><div>{nameOfBank}</div></li>}
            {iban && <li style={styles.itemStyle}><div>IBAN: {iban}</div></li>}
            {bic && <li style={styles.itemStyle}><div>BIC: {bic}</div></li>}
        </ul>
    )
}

export default BankAccount;