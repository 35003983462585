import React, { useContext } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";
import ContactInfo from "./ContactInfo";

const ProfileContactInfoTopRight = () => {
  const { documentLayout } = useContext(ResourceContext);

  if (documentLayout && documentLayout.infoBoxPlacement !== "RIGHT") {
    return null;
  }
  return <ContactInfo />;
};

export default ProfileContactInfoTopRight;
