import {faArrowAltCircleDown} from "@fortawesome/free-solid-svg-icons/faArrowAltCircleDown";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCalendarAlt,
    faCheckCircle,
    faCircle,
    faClock,
    faClone,
    faComments as faComments,
    faCopy,
    faLightbulb,
    faListAlt,
    faQuestionCircle,
    faSave,
    faStar as faStarRegular,
    faThumbsUp,
    faTimesCircle,
    faTrashAlt
} from '@fortawesome/free-regular-svg-icons'
import {
    faAlignJustify,
    faAlignRight,
    faAngleDoubleLeft,
    faAngleDown,
    faAngleUp,
    faArrowAltCircleUp,
    faArrowLeft,
    faBan,
    faBars,
    faBell,
    faBold,
    faCalendarDay,
    faChartLine,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faClipboard,
    faClipboardList,
    faCode,
    faCog,
    faCogs,
    faColumns,
    faComment,
    faComments as faComments_filled,
    faCopy as faCopySolid,
    faCreditCard,
    faCube,
    faCubes,
    faDownload,
    faEllipsisH,
    faEllipsisV,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFile,
    faStar,
    faFileAlt,
    faFileImage,
    faFileImport,
    faFileInvoice,
    faFilePdf,
    faFileUpload,
    faFilter,
    faFire,
    faFolder,
    faGlobe,
    faGraduationCap,
    faHeadset,
    faHistory,
    faHome,
    faImage,
    faImages,
    faInbox,
    faIndent,
    faInfoCircle,
    faItalic,
    faLink,
    faList,
    faListOl,
    faLongArrowAltRight,
    faMagic,
    faMinus,
    faMinusCircle,
    faOutdent,
    faPaperclip,
    faPaperPlane,
    faParagraph,
    faPaste,
    faPen,
    faPercentage,
    faPhone,
    faPiggyBank,
    faPlus,
    faPlusCircle,
    faPowerOff,
    faQuestion,
    faQuoteRight,
    faReply,
    faReplyAll,
    faRetweet,
    faRocket, faSadTear,
    faSearch,
    faShare,
    faShareAlt,
    faSignature,
    faSignInAlt,
    faSmile,
    faSort,
    faSortNumericDown,
    faSortNumericUp,
    faStar as faStarSolid,
    faSync,
    faSyncAlt,
    faTable,
    faTachometerAlt,
    faUniversity,
    faUser,
    faUsers,
    faVideo,
    faWallet,
    faCloudArrowDown
} from '@fortawesome/free-solid-svg-icons'
import {faCaretSquareDown} from "@fortawesome/free-solid-svg-icons/faCaretSquareDown";
import {faDiceThree} from "@fortawesome/free-solid-svg-icons/faDiceThree";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faAlignLeft} from "@fortawesome/free-solid-svg-icons/faAlignLeft";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {faArrowsAltH} from "@fortawesome/free-solid-svg-icons/faArrowsAltH";
import {faBookmark} from "@fortawesome/free-regular-svg-icons/faBookmark";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import {faFileContract} from "@fortawesome/free-solid-svg-icons/faFileContract";
import {faCalculator} from "@fortawesome/free-solid-svg-icons/faCalculator";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons/faUserEdit";
import {faShieldAlt} from "@fortawesome/free-solid-svg-icons/faShieldAlt";
import {faEuroSign} from "@fortawesome/free-solid-svg-icons/faEuroSign";
import {faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons/faLongArrowAltLeft";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import {faGlobeEurope} from "@fortawesome/free-solid-svg-icons/faGlobeEurope";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faBuilding} from "@fortawesome/free-regular-svg-icons/faBuilding";
import {faAlignCenter} from "@fortawesome/free-solid-svg-icons/faAlignCenter";
import {faRoute} from "@fortawesome/free-solid-svg-icons/faRoute";
import {faStopwatch} from "@fortawesome/free-solid-svg-icons/faStopwatch";
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons/faMapMarkedAlt";
import {faCar} from "@fortawesome/free-solid-svg-icons/faCar";
import {faPalette} from "@fortawesome/free-solid-svg-icons/faPalette";
import {faBorderStyle} from "@fortawesome/free-solid-svg-icons/faBorderStyle";
import {faGoogle} from "@fortawesome/free-brands-svg-icons/faGoogle";
import {faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";
import {faUnderline} from "@fortawesome/free-solid-svg-icons/faUnderline";
import {faGift} from "@fortawesome/free-solid-svg-icons/faGift";
import {faMoneyBillWave} from "@fortawesome/free-solid-svg-icons/faMoneyBillWave";

const React = require('react');

export class Icon extends React.Component {

    render() {
        let icon = undefined;
        let svg = undefined;

        if (this.props.value === "ALIGN_LEFT") {
            icon = faAlignLeft;
        } else if (this.props.value === "LEFT") {
            icon = faAlignLeft;
        } else if (this.props.value === "CENTER") {
            icon = faAlignCenter;
        } else if (this.props.value === "SAD_TEAR") {
            icon = faSadTear;
        } else if (this.props.value === "RIGHT") {
            icon = faAlignRight;
        } else if (this.props.value === "JUSTIFIED") {
            icon = faAlignJustify;
        } else if (this.props.value === "WORKFLOW") {
            icon = faRetweet;
            // sync-alt
        } else if (this.props.value === "POWER_OFF") {
            icon = faPowerOff;
            // sync-alt
        } else if (this.props.value === "SYNC") {
            icon = faSync;
        } else if (this.props.value === "GIFT") {
            icon = faGift;
        } else if (this.props.value === "MONEY") {
            icon = faMoneyBillWave;
        } else if (this.props.value === "EXCHANGE") {
            icon = faExchangeAlt;
        } else if (this.props.value === "THUMPS_UP") {
            icon = faThumbsUp;
        } else if (this.props.value === "GOOGLE") {
            icon = faGoogle;
        } else if (this.props.value === "OPEN_CIRCLE") {
            icon = faQuestionCircle;
        } else if (this.props.value === "OFFERED_CIRCLE") {
            icon = faClock;
        } else if (this.props.value === "BOOKED_CIRCLE") {
            icon = faCheckCircle;
        } else if (this.props.value === "CHECK") {
            icon = faCheck;
        } else if (this.props.value === "BAN") {
            icon = faBan;
        } else if (this.props.value === "CANCELED_CIRCLE") {
            icon = faBan;
        } else if (this.props.value === "CANCELED_CIRCLE") {
            icon = faBan;
        } else if (this.props.value === "QUOTES") {
            icon = faQuoteRight;
        }  else if (this.props.value === "PARAGRAPH") {
            icon = faParagraph;
        } else if (this.props.value === "OPEN") {
            icon = faSignInAlt; //faArrowRight
        } else if (this.props.value === "QUESTION") {
            icon = faQuestionCircle;
        } else if (this.props.value === "QUESTION_MARK") {
            icon = faQuestion;
        } else if (this.props.value === "OFFERED") {
            icon = faReply;
        } else if (this.props.value === "CLOCK") {
            icon = faClock;
        } else if (this.props.value === "BOOKED") {
            icon = faCheck;
        } else if (this.props.value === "CANCELED") {
            icon = faBan;
        } else if (this.props.value === "EARMARKED") {
            icon = faLightbulb;
        } else if (this.props.value === "AVAILABLE") {
            icon = faCheck;
        } else if (this.props.value === "UNAVAILABLE") {
            icon = faTimes;
        } else if (this.props.value === "CIRCLE") {
            icon = faCircle;
        } else if (this.props.value === "TRASH") {
            icon = faTrashAlt;
        } else if (this.props.value === "MESSAGES") {
            icon = faComments;
        } else if (this.props.value === "MESSAGES_FILLED") {
            icon = faComments_filled;
        } else if (this.props.value === "EMAIL") {
            icon = faEnvelope;
        } else if (this.props.value === "WALLET") {
            icon = faWallet;
        } else if (this.props.value === "CREDIT_CARD") {
            icon = faCreditCard;
        } else if (this.props.value === "STAR") {
            icon = faStarRegular;
        } else if (this.props.value === "STAR_FILLED") {
            icon = faStarSolid;
            /*svg = <svg height="100%" version="1.1" viewBox="0 0 32 32" width="100%" xmlns="http://www.w3.org/2000/svg" ><title/><desc/><defs/>
            <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="icon-23-star"><polygon id="star" points="16 22 7 28 11 18 2 12 12 12 16 2 20 12 30 12 21 18 25 28 "/></g></g>
            </svg>*/
        } else if (this.props.value === "UP") {
            icon = faArrowAltCircleUp;
        } else if (this.props.value === "DOWN") {
            icon = faArrowAltCircleDown;
        } else if (this.props.value === "BACK") {
            icon = faArrowLeft;
        } else if (this.props.value === "CARET_SQUARE_DOWN") {
            icon = faCaretSquareDown;
        } else if (this.props.value === "CARET_UP") {
            icon = faAngleUp;
        } else if (this.props.value === "CARET_DOWN") {
            icon = faAngleDown;
        } else if (this.props.value === "ANGLE_DOWN") {
            icon = faAngleDown;
        } else if (this.props.value === "PASTE") {
            icon = faPaste;
        } else if (this.props.value === "USER") {
            icon = faUser;
        } else if (this.props.value === "USERS") {
            icon = faUsers;
        } else if (this.props.value === "LIST") {
            icon = faList;
        } else if (this.props.value === "FILEALT") {
            icon = faFileAlt;
        } else if (this.props.value === "FILE") {
            icon = faFileAlt;
        } else if (this.props.value === "FOLDER") {
            icon = faFolder;
        } else if (this.props.value === "PDF") {
            icon = faFilePdf;
        } else if (this.props.value === "EDIT") {
            icon = faPen;
        } else if (this.props.value === "SAVE") {
            icon = faSave;
        } else if (this.props.value === "SAVED") {
            icon = faSave;
        } else if (this.props.value === "LINK") {
            icon = faLink;
        } else if (this.props.value === "RECOMMENDED") {
            icon = faShare;
        } else if (this.props.value === "SHARE") {
            icon = faShareAlt;
        } else if (this.props.value === "CALENDAR") {
            icon = faCalendarAlt;
        } else if (this.props.value === "LISTALT") {
            icon = faListAlt;
        } else if (this.props.value === "EYE") {
            icon = faEye;
        } else if (this.props.value === "EYE_SLASH") {
            icon = faEyeSlash;
        } else if (this.props.value === "NONE") {
            icon = faEyeSlash;
        } else if (this.props.value === "LAYOUT") {
            icon = faFile;
        } else if (this.props.value === "PLUS") {
            icon = faPlus;
        } else if (this.props.value === "PLUS_CIRCLE") {
            icon = faPlusCircle;
        } else if (this.props.value === "ANGLEUP") {
            icon = faAngleUp;
        } else if (this.props.value === "ANGLEDOWN") {
            icon = faAngleDown;
        } else if (this.props.value === "INFO") {
            icon = faInfoCircle;
        } else if (this.props.value === "BARS") {
            icon = faBars;
        } else if (this.props.value === "FILTER") {
            icon = faFilter;
        } else if (this.props.value === "SEARCH") {
            icon = faSearch;
        } else if (this.props.value === "TACHO") {
            icon = faTachometerAlt;
        } else if (this.props.value === "MESSAGE") {
            icon = faComment;
        } else if (this.props.value === "SETTINGS") {
            icon = faCog;
        } else if (this.props.value === "LOGOUT") {
            //icon = faSignOutAlt;
            icon = faPowerOff
        } else if (this.props.value === "LOGIN") {
            icon = faSignInAlt;
        } else if (this.props.value === "NOTES") {
            icon = faClipboard;
        } else if (this.props.value === "EVENT") {
            icon = faStar;
        } else if (this.props.value === "BANK") {
            icon = faUniversity;
        } else if (this.props.value === "WEBSITES") {
            icon = faGlobe;
        } else if (this.props.value === "ADDRESS") {
            icon = faHome;
        } else if (this.props.value === "SIGNATURE") {
            icon = faSignature;
        } else if (this.props.value === "DOWNLOAD") {
            icon = faCloudArrowDown;
        } else if (this.props.value === "CLOUD_UPLOAD") {
            icon = faCloudUploadAlt;
        } else if (this.props.value === "FILE_UPLOAD") {
            icon = faFileUpload;
        } else if (this.props.value === "FILE_IMPORT") {
            icon = faFileImport;
        } else if (this.props.value === "FILE_DESIGN") {
            icon = faFileInvoice;
        } else if (this.props.value === "LIST_OL") {
            icon = faListOl;
        } else if (this.props.value === "BACK") {
            icon = faChevronLeft;
        } else if (this.props.value === "NEXT") {
            icon = faChevronRight;
        } else if (this.props.value === "ATTACHMENTS") {
            icon = faPaperclip;
        } else if (this.props.value === "SEND") {
            icon = faPaperPlane;
        } else if (this.props.value === "CAUTION") {
            icon = faExclamationCircle;
        } else if (this.props.value === "MAGIC") {
            icon = faMagic;
        } else if (this.props.value === "FILE_IMAGE") {
            icon = faFileImage;
        } else if (this.props.value === "PHONE") {
            icon = faPhone;
        } else if (this.props.value === "DUPLICATE") {
            icon = faClone;
        } else if (this.props.value === "BULB") {
            icon = faLightbulb;
        } else if (this.props.value === "MENU") {
            icon = faEllipsisV;
        } else if (this.props.value === "COPY") {
            icon = faCopy;
        } else if (this.props.value === "COPY_SOLID") {
            icon = faCopySolid;
        } else if (this.props.value === "POINT") {
            icon = faCircle;
        } else if (this.props.value === "SORT") {
            icon = faSort;
        } else if (this.props.value === "SORT_UP") {
            icon = faSortNumericUp;
        } else if (this.props.value === "SORT_DOWN") {
            icon = faSortNumericDown;
        } else if (this.props.value === "HISTORY") {
            icon = faHistory;
        } else if (this.props.value === "FINANCE") {
            icon = faPiggyBank;
        } else if (this.props.value === "RECOMMEND") {
            icon = faThumbsUp;
        } else if (this.props.value === "CALENDARCHECK") {
            icon = faCalendarDay;
        } else if (this.props.value === "CUBE") {
            icon = faCube;
        } else if (this.props.value === "CUBES") {
            icon = faCubes;
        } else if (this.props.value === "MINUS_CIRCLE") {
            icon = faMinusCircle;
        } else if (this.props.value === "MINUS") {
            icon = faMinus;
        } else if (this.props.value === "PERCENT") {
            icon = faPercentage;
        } else if (this.props.value === "DICE_THREE") {
            icon = faDiceThree;
        } else if (this.props.value === "EMAIL") {
            icon = faEnvelope;
        } else if (this.props.value === "WORKFLOW") {
            icon = faClipboardList;
        } else if (this.props.value === "KEY") {
            icon = faKey;
        } else if (this.props.value === "FILE_IMAGE") {
            icon = faFileImage;
        } else if (this.props.value === "ANGLE_DOUBLE_LEFT") {
            icon = faAngleDoubleLeft;
        } else if (this.props.value === "ANGLE_DOUBLE_RIGHT") {
            icon = faAngleDoubleRight;
        } else if (this.props.value === "ARROWS_HORIZONTAL") {
            icon = faArrowsAltH;
        } else if (this.props.value === "BOOKMARK") {
            icon = faBookmark;
        } else if (this.props.value === "OUTDENT") {
            icon = faOutdent;
        } else if (this.props.value === "INDENT") {
            icon = faIndent;
        } else if (this.props.value === "CONTRACT") {
            icon = faFileContract;
        } else if (this.props.value === "QUESTIONNAIRE") {
            icon = faFileAlt;
        } else if (this.props.value === "EVENT_DAY") {
            icon = faCalendarDay;
        } else if (this.props.value === "CALC") {
            icon = faCalculator;
        } else if (this.props.value === "USER_EDIT") {
            icon = faUserEdit;
        } else if (this.props.value === "INBOX") {
            icon = faInbox;
        } else if (this.props.value === "SHIELD") {
            icon = faShieldAlt;
        } else if (this.props.value === "UPGRADE") {
            icon = faRocket;
        } else if (this.props.value === "DOTS") {
            icon = faEllipsisH;
        } else if (this.props.value === "REPLY") {
            icon = faReply;
        } else if (this.props.value === "REPLYALL") {
            icon = faReplyAll;
        } else if (this.props.value === "EURO") {
            icon = faEuroSign;
        } else if (this.props.value === "ARROW_LEFT") {
            icon = faLongArrowAltLeft;
        } else if (this.props.value === "ARROW_RIGHT") {
            icon = faLongArrowAltRight;
        } else if (this.props.value === "MAP_MARKER") {
            icon = faMapMarkerAlt;
        } else if (this.props.value === "GLOBE") {
            icon = faGlobeEurope;
        } else if (this.props.value === "TIMES") {
            icon = faTimes;
        } else if (this.props.value === "TIMES_CIRCLE") {
            icon = faTimesCircle;
        } else if (this.props.value === "ROUTE") {
            icon = faRoute;
        } else if (this.props.value === "STOPWATCH") {
            icon = faStopwatch;
        } else if (this.props.value === "MAP") {
            icon = faMapMarkedAlt;
        } else if (this.props.value === "CAR") {
            icon = faCar;
        } else if (this.props.value === "BIG_ARROW_RIGHT") {
            svg =
                <svg width={"100%"} height={"100%"} enableBackground="new 0 0 50 100" version="1.1" viewBox="0 0 50 50"
                     xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <polygon points="11.8,45.7 10.4,44.3 30.8,24 10.4,3.7 11.8,2.3 33.5,24  " fill={"currentColor"}/>
                    </g>
                </svg>;
        } else if (this.props.value === "VIDEO") {
            icon = faVideo;
        } else if (this.props.value === "STATS") {
            icon = faChartLine;
        } else if (this.props.value === "EXCHANGE") {
            icon = faExchangeAlt;
        } else if (this.props.value === "FIRE") {
            icon = faFire;
        } else if (this.props.value === "COGS") {
            icon = faCogs;
        } else if (this.props.value === "CODE") {
            icon = faCode;
        } else if (this.props.value === "IMAGE") {
            icon = faImage;
        } else if (this.props.value === "IMAGES") {
            icon = faImages;
        } else if (this.props.value === "ATTENTION") {
            icon = faExclamationCircle;
        } else if (this.props.value === "ATTENTION_TRIANGLE") {
            icon = faExclamationTriangle;
        } else if (this.props.value === "REFRESH") {
            icon = faSyncAlt;
        } else if (this.props.value === "BOLD") {
            icon = faBold;
        } else if (this.props.value === "ITALIC") {
            icon = faItalic;
        } else if (this.props.value === "UNDERLINE") {
            icon = faUnderline;
        } else if (this.props.value === "LEARN") {
            icon = faGraduationCap;
        } else if (this.props.value === "EMOJI") {
            icon = faSmile;
        } else if (this.props.value === "HEADSET") {
            icon = faHeadset;
        } else if (this.props.value === "EMAIL_PLANE") {
            icon = faPaperPlane;
        } else if (this.props.value === "BELL") {
            icon = faBell;
        } else if (this.props.value === "COMPANY") {
            icon = faBuilding;
        } else if (this.props.value === "CLIPBOARD") {
            icon = faClipboardList;
        } else if (this.props.value === "PALETTE") {
            icon = faPalette;
        } else if (this.props.value === "BORDER") {
            icon = faBorderStyle;
        } else if (this.props.value === "COLUMNS") {
            icon = faColumns;
        } else if (this.props.value === "PEN") {
            icon = faPen;
        } else if (this.props.value === "TABLE") {
            icon = faTable;
        }

        if (icon) {
            return (<FontAwesomeIcon onClick={this.props.onClick} className={this.props.className} style={this.props.style} icon={icon}/>);
        }

        if (svg) {
            return (
            <div style={this.props.style} className={this.props.className}>
                {svg}
            </div>)

        }
        return null;
    }

}
