import { useContext } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import { join } from "../../Utils/utils";

const LetterHead = ({ inline, isCustomer, withPartner }) => {
  const { documentLayout, contact, customerContact, profile, loading } =
    useContext(ResourceContext);

  const partner = customerContact ? customerContact.partner : undefined;

  if (loading) {
    return <LoadingAnimation />;
  }
  const _contact = isCustomer ? customerContact : contact;
  const isPropDefined = (prop) => prop && prop !== "";

  const styles = {
    listStyle: {
      display: inline ? "inline-flex" : "block",
      listStyleType: inline ? "none" : "none",
      marginBottom: 5,
      paddingLeft: 0,
      flexWrap: "wrap",
    },
    itemStyle: {
      marginRight: inline ? 20 : "unset",
      fontSize: 12,
    },
  };
  const {
    firstName,
    lastName,
    companyName,
    addressSupplement,
    addressStreetAndNo,
    zipCode,
    city,
  } = _contact;
  const { alias } = profile;
  const { contactName, showFounderPersonalName } = documentLayout;
  const displayedContactName = isCustomer
    ? companyName
    : contactName === "NONE"
    ? ""
    : contactName === "COMPANY"
    ? companyName
    : alias;

  return (
    <ul style={styles.listStyle}>
      {isPropDefined(displayedContactName) ? (
        <li style={{ ...styles.itemStyle, listStyleType: "none" }}>
          {displayedContactName}
        </li>
      ) : (
        <br />
      )}
      {(isPropDefined(firstName) || isPropDefined(lastName)) &&
        (showFounderPersonalName || isCustomer) && (
          <li style={styles.itemStyle}>
            {join([firstName, lastName], "")}
            {withPartner && partner && 
              (isPropDefined(partner.firstName) ||
                isPropDefined(partner.lastName)) &&
              ` und ${join([partner.firstName, partner.lastName], "")}`}
          </li>
        )}
      {isPropDefined(addressSupplement) && (
          <li style={styles.itemStyle}>{addressSupplement}</li>
      )}
      {isPropDefined(addressStreetAndNo) && (
        <li style={styles.itemStyle}>{join([addressStreetAndNo], ",")}</li>
      )}
      {(isPropDefined(zipCode) || isPropDefined(city)) && (
        <li style={styles.itemStyle}>
          {join([zipCode, city], "")}
          <br />
        </li>
      )}
    </ul>
  );
};

export default LetterHead;
