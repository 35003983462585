import { useContext } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";

const VatNote = () => {
  const { profile } = useContext(ResourceContext);
  const { smallScaleBusiness, vatStatement } = profile ?? {};

  if (smallScaleBusiness && vatStatement) {
    return <div dangerouslySetInnerHTML={{ __html: vatStatement }} style={{ marginBottom: 20 }}/>;
  }
  return null;
};

export default VatNote;
