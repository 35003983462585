import { Group, Stack, Text } from "@mantine/core";
import { FC } from "react";
import { FormatS } from "../../Service/FormatS";
import BookitupDocument, { TaxEntry } from "../../types/BookitupDocument";

interface Props {
  bookitupDocument: BookitupDocument;
  smallScaleBusiness: boolean;
}

const DocumentFinalSummary: FC<Props> = ({
  bookitupDocument,
  smallScaleBusiness,
}) => {
  const { finalTaxSummary, finalSum } = bookitupDocument;

  const mapTaxEntryUI = (taxEntry: TaxEntry) => {
    const { taxRatePercent, taxAmount } = taxEntry;
    return (
      <Group position="apart">
        <Text>{`Umsatzsteuer (${taxRatePercent}%)`}</Text>
        <Text>{FormatS.formatNumberCurrencyWithUnitSign(taxAmount)}</Text>
      </Group>
    );
  };

  return (
    <Stack spacing={5} mt="md">
      <Group position="apart">
        <Text weight="bolder">Forderungsbetrag</Text>
        <Text weight="bolder">
          {FormatS.formatNumberCurrencyWithUnitSign(finalSum)}
        </Text>
      </Group>
      {!smallScaleBusiness && finalTaxSummary.taxEntries.map(mapTaxEntryUI)}
    </Stack>
  );
};

export default DocumentFinalSummary;
