import React, {useEffect, useState} from "react";

export const WindowContext = React.createContext(undefined);

export const WindowContextProvider = React.memo((props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    });

    function updateDimensions () {
        setWindowHeight(window.innerHeight);
        setWindowWidth(window.innerWidth);
    }

    return (
        <WindowContext.Provider value={{
            windowWidth: windowWidth, windowHeight: windowHeight
        }}>

            {props.children}

        </WindowContext.Provider>
    )
}, () => true);