import React, {useEffect, useState} from "react";
import {InputHelp} from "./InputHelp";
import PropTypes from "prop-types";
import {withI18n} from "@lingui/react";
import {InputField} from "../InputFields";

export const EditableCheckbox = withI18n()(({finishCallback, labelValue, multiple, value: initialValue, placeholder, patchField, values, disabled, style, i18n, labelStyle, ...rest}) => {
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue])

    function toggle() {
        if (multiple) {
            if (values.filter(v => v === labelValue).length > 0) {
                values = values.filter(v => v !== labelValue);
            } else {
                values.push(labelValue);
            }
            values = values.join("##");
        } else {
            values = !value;
            setValue(values);
        }

        setLoading(true);
        finishCallback({[patchField]: values}).catch((error) => setError(error.message)).then(() => setLoading(false));
    }

    let styles = {
        error: {
            borderColor: "red",
        },
        inputContainer: {
            
        },
    };

    return (
        <div style={{...styles.inputContainer, ...style}}>
            <InputField isLoading={loading} checked={multiple ? values.filter(v => v === labelValue).length > 0 : value || false} labelStyle={labelStyle} toggle={toggle}
                        text={i18n._(placeholder || '')} name={patchField} disabled={disabled}/>
            {error ? <InputHelp errorDescription={error}/> : null}
        </div>
    )

});

EditableCheckbox.propTypes = {
    value: PropTypes.bool,
    finishCallback: PropTypes.func,
    placeholder: PropTypes.string,
};