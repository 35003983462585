import { useEffect, useState } from "react";
import { fetchDocument } from "../../Service/restapi/sharedContractService";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import DocumentAccepted from "./DocumentAccepted";
import PdfDownload from "../PdfDownload";
import ContactInfo from "../Contact/ContactInfo";
import DocumentPositionList from "../Positions/DocumentPositionList";
import SubjectDate from "../SubjectDate";
import ProfileContactInfoTopRight from "../Contact/ProfileContactInfoTopRight";
import { ResourceContextProvider } from "../../Service/Context/ResourceContext";
import FooterContact from "../FooterContact";
import DocumentPartialInvoices from "./DocumentPartialInvoices";
import { Space } from "@mantine/core";
import ProfileInjectWrapper from "../Profile/ProfileInjectWrapper";
import VatNote from "./VatNote";

const Document = ({
  resource,
  formSubmission,
  reloadFormSubmission,
  values,
  handleChange,
}) => {
  const [document, setDocument] = useState(undefined);

  useEffect(() => {
    getContract();
  }, [resource]);

  function getContract() {
    fetchDocument(resource.entityId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((contract) => {
            setDocument(contract);
          });
        } else {
          console.error("could not fetch document " + resource.entityId);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error("could not fetch document " + resource.entityId);
      });
  }

  if (!document) {
    return <LoadingAnimation />;
  }

  const { subject, number } = document;
  const finalSubject = `${subject} ${number ? `Nr. ${number}` : ""}`;

  return (
    <ResourceContextProvider
      resourceId={resource.id}
      contactId={document.contactId}
      customerContactId={document.customerId}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ContactInfo addressOnly isCustomer />
        <div style={{ textAlign: "right" }}>
          <ProfileContactInfoTopRight
            contactId={document.contactId}
            resourceId={resource.id}
          />
        </div>
      </div>
      <br />
      <SubjectDate subject={finalSubject} date={document.date} />
      <div dangerouslySetInnerHTML={{ __html: document.headRow }} />

      <DocumentPositionList
        formSubmission={formSubmission}
        resourceId={resource.id}
        values={values}
        handleChange={handleChange}
        documentId={document.id}
        document={document}
      />
      <ProfileInjectWrapper resourceId={resource.id}>
        <DocumentPartialInvoices bookitupDocument={document} />
      </ProfileInjectWrapper>
      <Space h={30} />
      <VatNote />
      <div dangerouslySetInnerHTML={{ __html: document.endRow }} />
      <Space h={50} />
      <FooterContact documentType={document.documentType} />
      {document.documentType === "OFFER" && (
        <DocumentAccepted
          formSubmission={formSubmission}
          reload={reloadFormSubmission}
        />
      )}
      {(formSubmission.lastSubmitted || document.documentType !== "OFFER") && (
        <>
          <Space h="xl" />
          <PdfDownload
            formSubmission={formSubmission}
            text={`${subject} herunterladen`}
          />
        </>
      )}
    </ResourceContextProvider>
  );
};

export default Document;
