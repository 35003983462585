import {ajaxActions} from "../AjaxActions";

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

export const fetchProfile = (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/profile").then(resp => {
    return resp;
});

export const fetchBankAccount = (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/bankAccount").then(resp => {
    return resp;
});

export const fetchDocumentLayout = (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/documentLayout").then(resp => {
    return resp;
});

export const fetchStandardLayoutContent = (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/standardLayoutContent").then(resp => {
    return resp;
});