import React, {useContext, useEffect, useState} from "react";
import HeadBodySkeleton from "../HeadBodySkeleton";
import MasterDetailNav from "../../Organismns/Navigation/MasterDetailNav";
import {fetchResources} from "../../Service/restapi/resourceService";
import Footer from "../Footer";
import {userActions} from "../../Service/UserAccountActions";
import {fetchFormSubmissionOfResource} from "../../Service/restapi/formSubmissionService";
import {Badge} from "reactstrap";
import {Icon} from "../../Atoms/Icons";
import {MobileMenu} from "../Navigation/mobile/MobileMenu";
import ManualAuthRefresh from "../Security/ManualAuthRefresh";
import {ProcessResource} from "../ProcessResources/ProcessResource";
import {withI18n} from "@lingui/react";
import { UserS } from "../../Service/restapi/UserS";

export const Dashboard = withI18n()(({i18n}) => {
    const [resources, setResources] = useState(undefined);
    const [resourceId, setResourceId] = useState(undefined);
    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        loadResources();
    }, []);

    function loadResources() {
        fetchResources().then(resp => {
        if (resp.ok) {
                resp.json().then(resources => {
                    if (resources.length === 0) {
                        console.log("no resources -> logout")
                        UserS.logout();
                        // TODO: WHAT IS THAT??
                        // location.reload();
                    } else {
                        setResources(resources);
                        setResourceId(resources[0].id);
                    }

                })
            } else {
                console.error("error while fetching resources");
                console.log({resp})
                if (resp.status === 404) {
                    UserS.logout();
                }
            }
        });
    }

    function fetchSubmissions() {
        if (resources) {
            Promise.all(resources.map((r) =>
                fetchFormSubmissionOfResource(r.id).then(resp => {
                    if (resp.ok) {
                        return resp.json().then(formSubmission => {
                            return formSubmission;
                        })
                    }
                })
            )).then(results => {
                setSubmissions(results);
            });
        }
    }

    function getLastSubmitted(resource) {
        let submission = submissions.filter(s => (s.entityId === resource.entityId && s.entityType === resource.entityType));
        let submitted = null;
        if (submission.length > 0) {
            submitted = submission[0].lastSubmitted;
        }
        return submitted;
    }

    useEffect(() => {
        fetchSubmissions();
    }, [resources])

    let menuItems = [];

    if (resources) {
        resources.map((res) => {
           let submitted = getLastSubmitted(res);
            menuItems.push({
                onClick: () => setResourceId(res.id),//() => history.push("/resources/" + res.id),
                active: res ? res.id === resourceId : false,
                icon: res.entityType === "contracts" ? 'CONTRACT' : "FILE",
                text: res.label ? res.label : i18n._("resource.entityType." + res.entityType),
                clickable: true,

                badge: submitted ? <Badge color="success" style={{
                    color: "#ffffff",
                    height: 17,
                    marginTop: 2,
                    marginLeft: 5,
                }}> <Icon value={"CHECK"} style={{fontSize: 10}}/></Badge> : null
            })
        })
    }

    return (
        <HeadBodySkeleton
            title={"Portal"}
            head={
                <MobileMenu whiteBackground={true} items={menuItems} />
            }
            body={
                <>
                    <MasterDetailNav items={menuItems}>
                        <ManualAuthRefresh/>
                        <ProcessResource resourceId={resourceId} reloadResourceStates={fetchSubmissions}/>
                    </MasterDetailNav>
                  <Footer/>

                </>

            }
        />
    )
});