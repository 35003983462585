import React, {useContext} from "react";
import {LoadingAnimation} from "../../Service/ResourceAdapter";
import PositionItem from "./PositionItem";
import {WindowContext} from "../../Service/Context/WindowContext";
import PositionSum from "./PositionSum";
import ProfileInjectWrapper from "../Profile/ProfileInjectWrapper";

export default ({formSubmission, resourceId, positions, taxType, isSummable, values, handleChange, document}) => {
    const {windowWidth} = useContext(WindowContext);

    if (!positions) {
        return <LoadingAnimation />
    }

    if (positions.length === 0) {
        return null;
    }

    let styles = {
        container: {
          marginTop: 20,
          marginBottom: 20,
        },
        firstColumn: {
            width: 50,
            display: "flex",
            justifyContent: "center",
        },
        textColumn: {
            flex: 1,
        },
        priceColumn: {
            width: windowWidth < 800 ? 80 : (windowWidth > 1100 ? 100 : 100),
            textAlign: "right"
        }
    };

    return (
        <div style={styles.container}>
            <div style={{display: "flex"}}>
                <div style={styles.firstColumn}/>
                <div style={styles.textColumn}>Beschreibung</div>
                {windowWidth > 800 && <div style={styles.priceColumn}>Anzahl</div>}
                {windowWidth > 800 && <div style={styles.priceColumn}>Preis</div>}
                <div style={styles.priceColumn}>Gesamt</div>
            </div>
            <hr/>
            {positions.map((position, index) => {
                return <>
                        <PositionItem key={index} taxType={taxType} formSubmission={formSubmission} values={values} position={position} handleChange={handleChange} columnStyles={styles} />
                        <hr/>
                    </>
            })}
            <ProfileInjectWrapper resourceId={resourceId}>
                <PositionSum isSummable={isSummable} document={document} resourceId={resourceId} values={values} positions={positions} columnStyles={styles} />
            </ProfileInjectWrapper>
        </div>

    )
}