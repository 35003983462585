import React, { useContext, useState } from "react";
import { TransButtonPrimaryWithSpinner } from "../../Atoms/Buttons";
import { ConfirmDialog, useModalState } from "../../Molecules/Dialogs";
import { Icon } from "../../Atoms/Icons";
import Moment from "react-moment";
import { patchFormSubmission } from "../../Service/restapi/formSubmissionService";
import { Trans } from "@lingui/react";

export default ({ formSubmission, reload }) => {
  const { show, toggle } = useModalState(false);
  const [loading, setLoading] = useState(false);

  function accepted() {
    setLoading(true);
    patchFormSubmission(formSubmission.id, { submitted: true })
      .then((resp) => {
        if (resp.ok) {
          reload();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let styles = {
    container: {
      marginTop: 20,
      marginBottom: 10,
      display: "flex",
    },
  };

  return (
    <>
      <hr /> <br />
      {/*<div>Ich stimme der Datenschutzerklärung zu.</div>*/}
      <div>
        {!formSubmission.lastSubmitted && (
          <center>
            <TransButtonPrimaryWithSpinner
              isLoading={loading}
              style={{ height: 50 }}
              onClickHandler={toggle}
              text={"actions.send.answers"}
            />
          </center>
        )}

        {formSubmission.lastSubmitted && (
          <div style={{ marginLeft: 20, alignSelf: "center", height: 50 }}>
            <Icon value={"BOOKED"} style={{ color: "green" }} />{" "}
            <Trans id={"questionnaire.answered-send"} />{" "}
            <Moment fromNow>{formSubmission.lastSubmitted}</Moment>
          </div>
        )}
        <ConfirmDialog
          text={"questionnaires.confirm"}
          subject={"questionnaires.confirm.head"}
          open={show}
          toggle={toggle}
          handler={accepted}
        />
      </div>
    </>
  );
};
