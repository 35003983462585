import React, {useContext} from "react";
import {WindowContext} from "../../Service/Context/WindowContext";
import {EditableFormAttributeValueProperty} from "../../Atoms/EditableProperties/EditableFormAttributeValueProperty";
import {SimpleEditableText} from "../../Atoms/inputs/SimpleEditableText";
import {StringFormatter} from "../../Atoms/Formatter/StringFormatter";
import {EditableTextBlockWrapper} from "../../Atoms/inputs/EditableTextBlockWrapper";
import {TextBlockFormatter} from "../../Atoms/Formatter/TextBlockFormatter";
import {EditableTimeInput} from "../../Atoms/inputs/EditableTimeInput";
import {TimeFormatter} from "../../Atoms/Formatter/TimeFormatter";
import {EditableDateInput} from "../../Atoms/inputs/EditableDateInput";
import {DateFormatter} from "../../Atoms/Formatter/DateFormatter";
import {EditableDecimalNumber} from "../../Atoms/inputs/EditableDecimalNumber";
import {DecimalNumberFormatter} from "../../Atoms/Formatter/DecimalNumberFormatter";
import {NumberFormatter} from "../../Atoms/Formatter/NumberFormatter";
import {EditableNumber} from "../../Atoms/inputs/EditableNumber";
import {EditableFileUpload} from "../../Atoms/inputs/EditableFileUpload";
import AnswerSingleChoice from "./AnswerSingleChoice";
import AnswerMultipleChoice from "./AnswerMultipleChoice";
import { EditableSelectField } from "../../Atoms/inputs/EditableSelectField";
import { useLingui } from "@lingui/react";

export default ({formSubmission, resourceId, additionalAnswer, answers, patchField, questionId, attributeType, questionnaireId, values, handleChange}) => {
    const {windowWidth} = useContext(WindowContext);
    const { i18n } = useLingui();

    let styles = {
        container: {
          marginTop: 10,
          marginBottom: 10,
        },
        firstColumn: {
            width: 100,
            display: "flex",
            justifyContent: "center",
        },
        textColumn: {
            flex: 1,
        },
        priceColumn: {
            width: windowWidth < 800 ? 80 : (windowWidth > 1100 ? 150 : 100),
            textAlign: "right"
        }
    };

    const booleanOptions = [
        { label: i18n._('common.yes'), value: true },
        { label: i18n._('common.no'), value: false },
        { label: 'Keine', value: null },
      ];

    return (
        <div style={styles.container}>
            {attributeType === "SINGLE_CHOICE" &&
                <AnswerSingleChoice
                    handleChange={handleChange}
                    patchField={patchField}
                    formSubmissionId={formSubmission.id}
                    lastSubmitted={formSubmission.lastSubmitted}
                    additionalAnswer={additionalAnswer}
                    answers={answers}
                    values={values}
                />
            }
            {attributeType === "MULTIPLE_CHOICE" &&
                <AnswerMultipleChoice
                    handleChange={handleChange}
                    patchField={patchField}
                    formSubmissionId={formSubmission.id}
                    lastSubmitted={formSubmission.lastSubmitted}
                    additionalAnswer={additionalAnswer}
                    answers={answers}
                    values={values}
                    questionId={questionId}
                />
            }

            {attributeType === "STRING" &&
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmission.id}
                    patchField={patchField}
                    changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                    entity={values}
                    editFormatter={SimpleEditableText}
                    labelFormatter={StringFormatter}
                    disabled={formSubmission.lastSubmitted}
                />
            }
            {attributeType === "TEXT" &&
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmission.id}
                    patchField={patchField}
                    changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                    entity={values}
                    big
                    editFormatter={EditableTextBlockWrapper}
                    labelFormatter={TextBlockFormatter}
                    disabled={formSubmission.lastSubmitted}
                />
            }
            {attributeType === "BOOLEAN" &&
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmission.id}
                    options={booleanOptions}
                    patchField={patchField}
                    changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                    entity={values}
                    big={true}
                    editFormatter={EditableSelectField}
                    editOnly={true}
                    disabled={formSubmission.lastSubmitted}
                />
            }
            {attributeType === "TIME" &&
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmission.id}
                    patchField={patchField}
                    changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                    entity={values}
                    big={true}
                    editFormatter={EditableTimeInput}
                    labelFormatter={TimeFormatter}
                    disabled={formSubmission.lastSubmitted}
                />
            }
            {attributeType === "DATE" &&
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmission.id}
                    patchField={patchField}
                    changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                    entity={values}
                    big={true}
                    editFormatter={EditableDateInput}
                    labelFormatter={DateFormatter}
                    disabled={formSubmission.lastSubmitted}
                />
            }
            {attributeType === "NUMBER" &&
            <EditableFormAttributeValueProperty
                formSubmissionId={formSubmission.id}
                patchField={patchField}
                changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                entity={values}
                editFormatter={EditableNumber}
                labelFormatter={NumberFormatter}
                disabled={formSubmission.lastSubmitted}
            />
            }
            {attributeType === "DECIMAL" &&
            <EditableFormAttributeValueProperty
                formSubmissionId={formSubmission.id}
                patchField={patchField}
                changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                entity={values}
                editFormatter={EditableDecimalNumber}
                labelFormatter={DecimalNumberFormatter}
                disabled={formSubmission.lastSubmitted}
            />
            }
            {attributeType === "FILE_UPLOAD" &&
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmission.id}
                    patchField={patchField}
                    changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                    entity={values}
                    editFormatter={EditableFileUpload}
                    fileName={values[patchField]}
                    editOnly={true}
                    disabled={formSubmission.lastSubmitted}
                />
            }
        </div>

    )
}