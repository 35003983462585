import {Dropdown} from "../../Atoms/Dropdowns";
import { useNavigate } from "react-router-dom";

export const NavigationChildMenu = ({value, click, style, iconStyle}) => {
    const navigate = useNavigate();
    let active = false;

    let items = [];
    if (value.sub) {
        value.sub.forEach(c => {
            if (c.active && (value.active === undefined || value.active === true)) {
                active = true;
            }
            let onClick;
            if (typeof c.onClick === "function") {
                onClick = c.onClick;
            } else {
                onClick = () => navigate(c.to);
            }
            items.push({text: c.text, icon: c.icon, onClick: onClick});
        });
    }
    let styles = {
        button: {
            display: "flex",
            ...iconStyle,
            fontSize: "0.95rem",
            backgroundColor: "transparent",
            color: active ? "#8ca3b5" : "#afaeae",
            padding: 0,
        },
        buttonContainer: {
            borderLeft: active ? "4px solid #8ca3b5" : "4px solid transparent",
        },
        icon: {
            width: "100%",
        },
        dropdown: {
            left: 50,
            top: 0,
        },
    };

    return (
        <Dropdown caret={false} icon={value.icon} buttonContainer={styles.buttonContainer} style={styles.button} iconStyle={styles.icon} buttonDropdownStyle={{display: "flex"}} dropdownMenu={styles.dropdown} value={""}
                  options={{items}}/>
    )
};