import React, { useContext } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";

const ProfileWeb = ({ inline }) => {
  const { profile } = useContext(ResourceContext);

  let styles = {
    listStyle: {
      display: inline ? "inline-flex" : "block",
      listStyleType: inline ? "none" : "none",
      marginBottom: 5,
      paddingLeft: 0,
      flexWrap: "wrap",
    },
    itemStyle: {
      marginRight: 20,
      fontSize: 12,
    },
  };

  let { addressOfWebsite, addressOfFacebook, addressOfInstagram } = profile;
  return (
    <ul style={styles.listStyle}>
      {addressOfWebsite && (
        <li style={{ ...styles.itemStyle, listStyleType: "none" }}>
          <a target={"_blank"} href={addressOfWebsite}>
            Webseite
          </a>
          <br />
        </li>
      )}
      {addressOfFacebook && (
        <li style={styles.itemStyle}>
          <a target={"_blank"} href={addressOfFacebook}>
            Facebook
          </a>
          <br />
        </li>
      )}
      {addressOfInstagram && (
        <li style={styles.itemStyle}>
          <a target={"_blank"} href={addressOfInstagram}>
            Instagram
          </a>
        </li>
      )}
    </ul>
  );
};

export default ProfileWeb;
