import React, { useContext, useEffect, useState } from "react";
import { fetchQuestionnaire } from "../../Service/restapi/sharedContractService";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import QuestionnaireSubmit from "./QuestionnaireSubmit";
import PdfDownload from "../PdfDownload";
import { InputHelp } from "../../Atoms/inputs/InputHelp";
import QuestionBundleList from "./QuestionBundleList";
import Moment from "react-moment";
import { ModalDialog, useModalState } from "../../Molecules/Dialogs";
import { ButtonDefault, TransButtonDefault } from "../../Atoms/Buttons";
import { Group, Stack } from "@mantine/core";
import { FormSubmissionS } from "../../Service/FormSubmissionS";

// eslint-disable-next-line import/no-anonymous-default-export
const Questionnaire = ({
  resource,
  formSubmission,
  reloadFormSubmission,
  values,
  handleChange,
}) => {
  const [questionnaire, setQuestionnaire] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { show, toggle } = useModalState(false);

  useEffect(() => {
    if (formSubmission && questionnaire) {
      const { lastSubmitted, entityId } = formSubmission;
      const { id: questionnaireId } = questionnaire;
      // eslint-disable-next-line eqeqeq
      if (!show && entityId == questionnaireId && !lastSubmitted) {
        toggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmission, questionnaire]);

  useEffect(() => {
    getQuestionnaire();
  }, [resource]);

  function getQuestionnaire() {
    setLoading(true);
    fetchQuestionnaire(resource.entityId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((questionnaire) => {
            setQuestionnaire(questionnaire);
            setLoading(false);
            if (questionnaire.locale) {
              //                         cookies.set("locale", questionnaire.locale);
              Moment.globalLocale = questionnaire.locale;
            }
          });
        } else {
          setLoading(false);
          console.error("could not fetch questionnaire " + resource.entityId);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error("could not fetch questionnaire " + resource.entityId);
        setLoading(false);
      });
  }

  if (loading) {
    return <LoadingAnimation />;
  }
  if (!loading && !questionnaire) {
    return (
      <InputHelp
        errorDescription={
          "Questionnaire " + resource.entityId + " could not be loaded."
        }
      />
    );
  }

  let styles = {
    subject: {
      fontSize: 20,
      fontWeight: 800,
      marginBottom: 10,
    },
  };

  const openQuestionnaire = () => {
    const { id } = formSubmission;
    FormSubmissionS.reopenQuestionnaire(id).then(
      (ok) => ok && reloadFormSubmission()
    );
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={styles.subject}>{questionnaire.subject}</div>
      </div>

      <div dangerouslySetInnerHTML={{ __html: questionnaire.headRow }} />
      <QuestionBundleList
        questionBundles={questionnaire.questionBundles}
        formSubmission={formSubmission}
        resourceId={resource.id}
        values={values}
        handleChange={handleChange}
        questionnaireId={questionnaire.id}
      />
      <div dangerouslySetInnerHTML={{ __html: questionnaire.endRow }} />
      <QuestionnaireSubmit
        formSubmission={formSubmission}
        reload={reloadFormSubmission}
      />
      {formSubmission.lastSubmitted && (
        <Stack>
          <PdfDownload
            formSubmission={formSubmission}
            text="Fragebogen herunterladen"
          />
          <center>
            <TransButtonDefault
              onClickHandler={openQuestionnaire}
              style={{ height: 50, marginLeft: 10, maxWidth: 400 }}
              text="Fragebogen bearbeiten"
            />
          </center>
        </Stack>
      )}
      <ModalDialog
        show={show}
        toggle={toggle}
        header={"Hinweis"}
        body={
          <div>
            Sie müssen den Fragebogen nicht auf einmal ausfüllen. Ihre Antworten
            werden automatisch gespeichert und Sie können das Ausfüllen des
            Fragebogens auch zu einem anderen Zeitpunkt fortführen.
          </div>
        }
        footer={
          <div>
            <ButtonDefault
              text={"OK, habe ich verstanden"}
              onClickHandler={() => toggle()}
            />
          </div>
        }
      />
    </>
  );
};

export default Questionnaire;
