import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardBody, CardFooter, Col, FormGroup, Row, Input} from 'reactstrap';
import {Trans} from "@lingui/react";
import {Helmet} from "react-helmet";
import './LoginArea.scss'

class TermsAndConditions extends React.Component {
    render() {
        return (
            <div>
                <Helmet title={"Allgemeine Geschäftsbedingungen"}></Helmet>

                <br/><br/><br/>
                <Row>
                    <br/>
                    <Col xs={12} sm={{size: 8, offset: 2}} lg={{size: 10, offset: 1}}>
                        <Card>
                            <CardBody>

                                <div style={{textAlign: 'center'}}><img style={{width: '150px'}} src="/img/logo.png"/></div>

                                <h1>Allgemeine Geschäftsbedingungen</h1>
                                <h2>1. Vertragsgegenstand</h2>
                                <p>1.1  Diese Vertragsbedingungen gelten für die Nutzung der Software bookitup gemäß der aktuellen Produktbeschreibung und dem Leistungsvertrag als Software as a Service („SaaS“) bzw. Cloud-Angebot.</p>
                                <p>1.2  Die Software wird vom Anbieter als SaaS- bzw. Cloud-Lösung betrieben. Dem Kunden wird ermöglicht, die auf den Servern des Anbieters bzw. eines vom Anbieter beauftragten Dienstleisters gespeicherte und ablaufende Software über eine Internetverbindung während der Laufzeit dieses Vertrags für eigene Zwecke zu nutzen und seine Daten mit ihrer Hilfe zu speichern und zu verarbeiten.</p>
                                <p>1.3 Diese Vertragsbedingungen gelten ausschließlich. Vertragsbedingungen des Kunden finden keine Anwendung. Gegenbestätigungen des Kunden unter Hinweis auf seine eigenen Geschäftsbedingungen wird ausdrücklich widersprochen.</p>
                                <br/>
                                <h2>2. Art und Umfang der Leistung</h2>

                                <p>2.1 Der Anbieter stellt dem Kunden die Software in der jeweils vereinbarten Version am Routerausgang des Rechenzentrums, in dem der Server mit der Software steht („Übergabepunkt“), zur Nutzung bereit. Die Software, die für die Nutzung erforderliche Rechenleistung und der erforderliche Speicher- und Datenverarbeitungsplatz werden vom Anbieter bereitgestellt. Der Anbieter schuldet nicht die Herstellung und Aufrechterhaltung der Datenverbindung zwischen den IT-Systemen des Kunden und dem beschriebenen Übergabepunkt.</p>
                                <p>2.2 Die bookitup UG hat das Recht die Software jederzeit zu modifizieren und eine neuere Version bereitzustellen.</p>
                                <br/>
                                <h2>3. Verfügbarkeit der Systeme</h2>

                                <p>3.1 Der Anbieter weist den Kunden darauf hin, dass Einschränkungen oder Beeinträchtigungen der erbrachten Dienste entstehen können, die außerhalb des Einflussbereichs des Anbieters liegen. Hierunter fallen insbesondere Handlungen von Dritten, die nicht im Auftrag des Anbieters handeln, vom Anbieter nicht beeinflussbare technische Bedingungen des Internets sowie höhere Gewalt. Auch die vom Kunden genutzte Hard- und Software und technische Infrastruktur kann Einfluss auf die Leistungen des Anbieters haben. Soweit derartige Umstände Einfluss auf die Verfügbarkeit oder Funktionalität der vom Anbieter erbrachten Leistung haben, hat dies keine Auswirkung auf die Vertragsgemäßheit der erbrachten Leistungen.</p>
                                <p>Die bookitup UG gewährleistet dem Kunden gegeüber eine Verfügbarkeit der Anwendung von 99,9% des jeweiligen Produkts im Jahresmittel. Ausgenommen sind davon Zeiten in denen technische oder sonstige Störungen aufgrund von Dritter aufkommen. Mitgeteilte Wartungsfenster, welche zur Ausfall führen, gelten als Verfügbar.</p>
                                <h2>4. Rechte zur Datenverarbeitung, Datensicherung</h2>

                                <p>4.1 Der Anbieter hält sich an die gesetzlichen Datenschutzbestimmungen.</p>

                                <p>4.2 Der Kunde räumt dem Anbieter für die Zwecke der Vertragsdurchführung das Recht ein, die vom Anbieter für den Kunden zu speichernden Daten vervielfältigen zu dürfen, soweit dies zur Erbringung der nach diesem Vertrag geschuldeten Leistungen erforderlich ist. Der Anbieter ist auch berechtigt, die Daten in einem Ausfallsystem bzw. separaten Ausfallrechenzentrum vorzuhalten. Zur Beseitigung von Störungen ist der Anbieter ferner berechtigt, Änderungen an der Struktur der Daten oder dem Datenformat vorzunehmen.</p>

                                <p>4.3 Der Anbieter sichert die Daten des Kunden auf dem vom Anbieter verantworteten Server regelmäßig auf einem externen Backup-Server. Der Kunde kann diese Daten, soweit technisch möglich, jederzeit zu Sicherungszwecken exzerpieren und ist verpflichtet, dies in regelmäßigen üblichen Abständen zu tun. Soweit dies nicht möglich ist, stellt der Anbieter dem Kunden die Daten einmal monatlich als Backup zur Verfügung.</p>

                                <p>4.4 Wenn und soweit der Kunde auf vom Anbieter technisch verantworteten IT-Systemen personenbezogenen Daten verarbeitet oder verarbeiten lässt, ist eine Auftragsdatenverarbeitungsvereinbarung abzuschließen.</p>

                                <h2>5 Support</h2>

                                <p>5.1 Ein Supportfall liegt vor, wenn die Software die vertragsgemäßen Funktionen gemäß der Produktbeschreibung nicht erfüllt. Der Umfang der Supportleistungen richtet sich nach dem Leistungsvertrag.</p>

                                <p>5.2 Meldet der Kunde einen Supportfall, so hat er eine möglichst detaillierte Beschreibung der jeweiligen Funktionsstörung zu liefern, um eine möglichst effiziente Fehlerbeseitigung zu ermöglichen.</p>

                                <p>5.3 Die Parteien können eine gesonderte Vereinbarung über die Zurverfügungstellung von Support-, Wartungs- und Pflegeleistungen treffen.</p>

                                <h2>6 Vergütung</h2>

                                <p>6.1 Zahlungszeitraum und Höhe der Vergütung richten sich ebenso wie die Zahlungsweise nach dem Leistungsvertrag.</p>

                                <p>6.2 Verzögert der Kunde die Zahlung einer fälligen Vergütung um mehr als vier Wochen, ist der Anbieter nach vorheriger Mahnung mit Fristsetzung und Ablauf der Frist zur Sperrung des Zugangs zur Software berechtigt. Der Vergütungsanspruch des Anbieters bleibt von der Sperrung unberührt. Der Zugang zur Software wird nach Begleichung der Rückstände unverzüglich wieder freigeschaltet. Das Recht zur Zugangssperrung besteht als milderes Mittel auch dann, wenn der Anbieter ein Recht zur außerordentlichen Kündigung nach Ziffer 11.2 hat.</p>

                                <p>6.3 Der Anbieter kann nach Ablauf der Erstlaufzeit gemäß dem Leistungsvertrag die Preise wie auch die Sätze für eine vereinbarte Vergütung nach Aufwand der allgemeinen Preisentwicklung anpassen. Beträgt die Entgelterhöhung mehr als 5% kann der Kunde das Vertragsverhältnis zum Ende des laufenden Vertragsmonats kündigen.</p>

                                <p>6.4 Die Vergütung sonstiger Leistungen richtet sich nach dem jeweils gültigen Preisblatt des Anbieters.</p>

                                <h2>7 Mitwirkungspflichten des Kunden</h2>
                                <p>7.1 Der Kunde wird den Anbieter bei der Erbringung der vertraglichen Leistungen in angemessenem Umfang unterstützen.</p>
                                <p>7.2 Die ordnungsgemäße und regelmäßige Sicherung seiner Daten obliegt dem Kunden. Das gilt auch für dem Anbieter im Zuge der Vertragsabwicklung überlassene Unterlagen.
                                </p>
                                <p>7.3 Für die Nutzung der Software müssen die sich aus der Produktbeschreibung bzw. dem Leistungsvertrag ergebenden Systemvoraussetzungen beim Kunden erfüllt sein. Der Kunde trägt hierfür selbst die Verantwortung.</p>
                                <p>7.4 Der Kunde hat die ihm zur Verfügung gestellten Zugangsdaten geheim zu halten und dafür zu sorgen, dass etwaige Mitarbeiter, denen Zugangsdaten zur Verfügung gestellt werden, dies ebenfalls tun. Die Leistung des Anbieters darf Dritten nicht zur Verfügung gestellt werden, soweit das nicht von den Partei- en ausdrücklich vereinbart wurde.
                                </p>

                                <h2>8 Gewährleistung</h2>
                                <p>Es gelten grundsätzlich die gesetzlichen Regelungen zur Gewährleistung. Die §§ 536b (Kenntnis des Mieters vom Mangel bei Vertragsschluss oder Annahme), 536c (Während der Mietzeit auftretende Mängel; Mängelan- zeige durch den Mieter) BGB finden Anwendung. Die Anwendung des § 536a Abs. 2 (Selbstbeseitigungsrecht des Mieters) ist jedoch ausgeschlossen. Ausgeschlossen ist auch die Anwendung von § 536a Abs. 1 BGB (Schadensersatzpflicht des Vermieters), soweit die Norm eine verschuldensunabhängige Haftung vorsieht.
                                    5</p>

                                <h2>9 Haftung und Schadensersatz</h2>
                                <p>9.1 Der Anbieter haftet für Schäden des Kunden, die vorsätzlich oder grob fahrlässig verursacht wurden, die Folge des Nichtvorhandenseins einer garantierten Beschaffenheit sind, die auf einer schuldhaften Verlet- zung wesentlicher Vertragspflichten (so genannte Kardinalpflichten) beruhen, die Folge einer schuldhaf- ten Verletzung der Gesundheit, des Körpers oder des Lebens sind, oder für die eine Haftung nach dem Produkthaftungsgesetz vorgesehen ist, nach den gesetzlichen Bestimmungen.</p>
                                <p>9.2 Kardinalpflichten sind solche vertraglichen Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf, und deren Verletzung auf der anderen Seite die Erreichung des Vertragszwecks gefährdet.
                                </p>
                                <p>9.3 Bei Verletzung einer Kardinalpflicht ist die Haftung – soweit der Schaden lediglich auf leichter Fahrlässig- keit beruht – beschränkt auf solche Schäden, mit deren Entstehung beim Einsatz der vertragsgegenständ- lichen Software typischerweise gerechnet werden muss.</p>
                                <p>9.4 Im Übrigen ist die Haftung – gleich aus welchem Rechtsgrund – ausgeschlossen.</p>
                                <p>9.5 Resultieren Schäden des Kunden aus dem Verlust von Daten, so haftet der Anbieter hierfür nicht, soweit die Schäden durch eine regelmäßige und vollständige Sicherung aller relevanten Daten durch den Kunden vermieden worden wären. Der Kunde wird eine regelmäßige und vollständige Datensicherung selbst oder durch einen Dritten durchführen bzw. durchführen lassen und ist hierfür allein verantwortlich.</p>

                                <h2>10 Kundendaten und Freistellung von Ansprüchen Dritter</h2>
                                <p>10.1 Der Anbieter speichert als technischer Dienstleister Inhalte und Daten für den Kunden, die dieser bei der Nutzung der Software eingibt und speichert und zum Abruf bereitstellt. Der Kunde verpflichtet sich gegenüber dem Anbieter, keine strafbaren oder sonst absolut oder im Verhältnis zu einzelnen Dritten rechtswidrigen Inhalte und Daten einzustellen und keine Viren oder sonstige Schadsoftware enthaltenden Programme im Zusammenhang mit der Software zu nutzen. Der Kunde bleibt im Hinblick auf personenbezogene Daten verantwortliche Stelle und hat daher stets zu prüfen, ob die Verarbeitung solcher Daten über die Nutzung der Software von entsprechenden Erlaubnistatbestän- den getragen ist.</p>
                                <p>10.2 Der Kunde ist für sämtliche von verwendeten Inhalte und verarbeiteten Daten sowie die hierfür etwa erforderlichen Rechtspositionen allein verantwortlich. Der Anbieter nimmt von Inhalten des Kunden keine Kenntnis und prüft die vom Kunden mit der Software genutzten Inhalte grundsätzlich nicht.
                                </p>
                                <p>10.3 Der Kunde verpflichtet sich in diesem Zusammenhang, den Anbieter von jeder Haftung und jeglichen Kos- ten, einschließlich möglicher und tatsächlicher Kosten eines gerichtlichen Verfahrens, freizustellen, falls der Anbieter von Dritten, auch von Mitarbeitern des Kunden persönlich, infolge von behaupteten Handlungen oder Unterlassungen des Kunden in Anspruch genommen wird. Der Anbieter wird den Kunden über die Inanspruchnahme unterrichten und ihm, soweit dies rechtlich möglich ist, Gelegenheit zur Abwehr des geltend gemachten Anspruchs geben. Gleichzeitig wird der Kunde dem Anbieter unverzüglich alle ihm verfügbaren Informationen über den Sachverhalt, der Gegenstand der Inanspruchnahme ist, vollständig mitteilen.</p>
                                <p>10.4 Darüber hinausgehende Schadensersatzansprüche des Anbieters bleiben unberührt.
                                </p>

                                <h2>11 Vertragslaufzeit und Beendigung des Vertrags</h2>
                                <p>11.1 Die Vertragslaufzeit richtet sich nach dem Angebotsblatt.</p>
                                <p>11.2 Die außerordentliche Kündigung aus wichtigem Grund bleibt beiden Parteien bei Vorliegen der gesetz- lichen Voraussetzungen vorbehalten. Ein wichtiger Grund für den Anbieter liegt insbesondere dann vor, wenn der Kunde trotz Mahnung mehr als zwei Monate mit der Zahlung einer fälligen Vergütung in Verzug ist. Sofern der Kunde den Kündigungsgrund zu vertreten hat, ist der Kunde verpflichtet, dem An- bieter die vereinbarte Vergütung abzüglich von vom Anbieter ersparter Aufwendungen bis zu dem Termin zu zahlen, an dem der Vertrag bei einer ordentlichen Kündigung frühestens enden würde.</p>
                                <p>11.3 Kündigungserklärungen bedürfen zu ihrer Wirksamkeit der Textform. Die Einhaltung dieser Form ist Voraus- setzung für die Wirksamkeit der Kündigung. Telefax und E-Mail genügen dem Schriftformerfordernis nicht.
                                </p>
                                <p>11.4  Nach Beendigung des Vertrags hat der Anbieter sämtliche vom Kunden überlassenen und sich noch im Besitz des Anbieters befindlichen Unterlagen sowie Datenträger, die im Zusammenhang mit dem vorlie- genden Vertrag stehen, an den Kunden zurückzugeben und die beim Anbieter gespeicherten Daten zu löschen, soweit keine Aufbewahrungspflichten oder –rechte bestehen.</p>

                                <h2>12 Vertraulichkeit</h2>
                                <p>12.1 Die Parteien sind verpflichtet, alle ihnen im Zusammenhang mit diesem Vertrag bekannt gewordenen oder bekannt werdenden Informationen über die jeweils andere Partei, die als vertraulich gekennzeichnet werden oder anhand sonstiger Umstände als Geschäfts- und Betriebsgeheimnisse (im Folgenden: „vertrauliche Informationen“) erkennbar sind, dauerhaft geheim zu halten, nicht an Dritte weiterzugeben, aufzuzeichnen oder in anderer Weise zu verwerten, sofern die jeweils andere Partei der Offenlegung oder Verwendung nicht ausdrücklich und schriftlich zugestimmt hat oder die Informationen aufgrund Geset- zes, Gerichtsentscheidung oder einer Verwaltungsentscheidung offengelegt werden müssen.</p>
                                <p>12.2 Die Informationen sind dann keine vertraulichen Informationen im Sinne dieser Ziffer 12, wenn sie</p>
                                <ul>
                                    <li>der anderen Partei bereits zuvor bekannt waren, ohne dass die Informationen einer Vertraulichtkeitsverpflichtung unterlegen hätten,</li>
                                    <li>allgemein bekannt sind oder ohne Verletzung der übernommenen Vertraulichkeitsverpflichtungen bekannt werden,</li>
                                    <li>der anderen Partei ohne Verletzung einer Vertraulichkeitsverpflichtung von einem Dritten offenbart werden.</li>
                                </ul>
                                <p>12.3 Die Verpflichtungen nach dieser Ziffer 12 überdauern das Ende dieser Vereinbarung.</p>
                                <p>12.4 Als Kunde können jederzeit alle, zu seiner Person gespeicherten Daten, unentgeltlich angefordert werden.</p>
                                <h2>13 Übertragung der Rechte und Pflichten
                                </h2>
                                <p>Die Abtretung der Rechte und Pflichten aus diesem Vertrag ist nur mit vorheriger schriftlicher Zustimmung des An- bieters zulässig. Der Anbieter ist berechtigt, Dritte mit der Erfüllung der Pflichten aus diesem Vertrag zu betrauen.
                                </p>

                                <h2>14 Sonstiges</h2>
                                <p>14.1 Diese Vereinbarung und ihre Änderungen sowie alle vertragsrelevanten Erklärungen, Mitteilungs- und Dokumentationspflichten bedürfen der Schriftform, soweit nicht eine andere Form vereinbart oder gesetzlich vorgeschrieben ist.</p>

                                <p>14.2 Der Vertrag untersteht dem Recht der Bundesrepublik Deutschland unter Ausschluss des Übereinkom- mens der Vereinten Nationen über Verträge über den internationalen Warenkauf. Gerichtsstand ist der Sitz des Anbieters, soweit der Kunde Kaufmann, juristische Person des öffentlichen Rechts oder öffent- lich-rechtliches Sondervermögen ist.</p>

                                <p>14.3 Sollten einzelne Bestimmung dieser Vereinbarung unwirksam sein, wird hierdurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Die Parteien werden in diesem Fall zusammenwirken, um unwirksame Regelungen durch solche Regelungen zu ersetzen, die den unwirksamen Bestimmungen soweit wie möglich entsprechen.</p>

                                <h2>15 Salvatorische Klausel</h2>
                                <p>Sollten einzelne Bestimmungen dieses Vertrages unwirksam oder undurchführbar sein oder nach Vertragsschluss unwirksam oder undurchführbar werden, bleibt davon die Wirksamkeit des Vertrages im Übrigen unberührt. An die Stelle der unwirksamen oder undurchführbaren Bestimmung soll diejenige wirksame und durchführbare Regelung treten, deren Wirkungen der wirtschaftlichen Zielsetzung am nächsten kommen, die die Vertragsparteien mit der unwirksamen bzw. undurchführbaren Bestimmung verfolgt haben. Die vorstehenden Bestimmungen gelten entsprechend für den Fall, dass sich der Vertrag als lückenhaft erweist.</p>

                            </CardBody>
                            <CardFooter>
                                <Link to="/register" className="btn btn-link"><Trans id={"actions.register"}/></Link>
                            </CardFooter>
                        </Card>
                        <br/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export const TermsAndConditionsProxy = TermsAndConditions;