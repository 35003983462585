import { FC } from "react";
import { Divider, Group, Stack, Text } from "@mantine/core";
import BookitupDocument, { PartialInvoice } from "../../types/BookitupDocument";
import { FormatS } from "../../Service/FormatS";
import DocumentFinalSummary from "./DocumentFinalSummary";
import { Profile } from "../../types/Profile";

interface Props {
  bookitupDocument: BookitupDocument;
  profile: Profile;
}

const DocumentPartialInvoices: FC<Props> = ({ bookitupDocument, profile }) => {
  const { partialInvoices } = bookitupDocument;
  const { smallScaleBusiness } = profile;

  if (!partialInvoices || partialInvoices.length === 0) {
    return null;
  }

  const mapPartialInvoiceUI = (partialInvoice: PartialInvoice) => {
    const { label, net, gross, vat } = partialInvoice;
    return (
      <>
        <Text weight="bolder">{label}</Text>
        {!smallScaleBusiness && (
          <>
            <Group position="apart">
              <Text>Netto:</Text>
              <Text>{FormatS.formatNumberCurrencyWithUnitSign(net)}</Text>
            </Group>
            <Group position="apart">
              <Text>Umsatzsteuer:</Text>
              <Text>{FormatS.formatNumberCurrencyWithUnitSign(vat)}</Text>
            </Group>
          </>
        )}
        <Group position="apart">
          <Text>{smallScaleBusiness ? "Gesamtbetrag" : "Brutto"}:</Text>
          <Text>- {FormatS.formatNumberCurrencyWithUnitSign(gross)}</Text>
        </Group>
      </>
    );
  };

  return (
    <>
      <Divider mb="md" />
      <Stack spacing={5}>{partialInvoices.map(mapPartialInvoiceUI)}</Stack>
      <Divider mt="md" />
      <DocumentFinalSummary
        bookitupDocument={bookitupDocument}
        smallScaleBusiness={smallScaleBusiness}
      />
    </>
  );
};

export default DocumentPartialInvoices;
