import LetterHead from "./LetterHead";
import ContactMailPhone from "./ContactMailPhone";

const ContactInfo = ({ description, addressOnly, alias, isCustomer, withPartner }) => {
  const styles = {
    additionalInfo: {
      fontSize: 12,
    },
    name: {
      fontSize: "0.9rem",
      color: "#000000",
      textAlign: "left",
      height: "unset",
    },
  };

  return (
    <>
      <LetterHead isCustomer={isCustomer} withPartner={withPartner} />
      {!addressOnly && <ContactMailPhone isCustomer={isCustomer} withPartner={withPartner} />}
      <br />
      {!addressOnly && description && (
        <span style={styles.additionalInfo}>
          <div style={{ marginBottom: 5 }}>{description}</div>
        </span>
      )}
      {/* {withPartner && <ContactPartnerInfo />} */}
      {alias && (
        <div style={{ marginTop: 10 }}>Im Folgenden {alias} genannt.</div>
      )}
    </>
  );
};

export default ContactInfo;
