import { useContext } from "react";
import TaxNumbers from "./Contact/TaxNumbers";
import BankAccount from "./Contact/BankAccount";
import LetterHead from "./Contact/LetterHead";
import ContactMailPhone from "./Contact/ContactMailPhone";
import ProfileWeb from "./Contact/ProfileWeb";
import { WindowContext } from "../Service/Context/WindowContext";
import { ResourceContext } from "../Service/Context/ResourceContext";
import { LoadingAnimation } from "../Service/ResourceAdapter";

const FooterContact = ({ documentType, contract }) => {
  const { documentLayout, loading } = useContext(ResourceContext);
  const { windowWidth } = useContext(WindowContext);

  if (loading) {
    return <LoadingAnimation />;
  }

  const { infoBoxPlacement } = documentLayout;
  const footerStyles = contract ? contract.contractFooter : infoBoxPlacement;

  if (!footerStyles || footerStyles === "NONE" || footerStyles === "RIGHT") {
    return null;
  }

  let styles = {
    blockStyle: {
      display: "flex",
      justifyContent: "space-evenly",
      textAlign: "justify",
      fontSize: 12,
      flexDirection: windowWidth > 550 ? "row" : "column",
      marginTop: 30,
    },
    centerStyle: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  };

  const blocks = footerStyles === "BLOCKS" || infoBoxPlacement === "JUSTIFIED";

  return (
    <div style={blocks ? styles.blockStyle : styles.centerStyle}>
      <div style={{ paddingLeft: 10 }}>
        <LetterHead inline={!blocks} />
      </div>
      <div style={{ paddingLeft: 10 }}>
        <ContactMailPhone inline={!blocks} />
        <ProfileWeb inline={!blocks} />
      </div>
      <div style={{ paddingLeft: 10 }}>
        <TaxNumbers inline={!blocks} />
      </div>
      <div style={{ paddingLeft: 10 }}>
        <BankAccount inline={!blocks} documentType={documentType} />
      </div>
    </div>
  );
};

export default FooterContact;
