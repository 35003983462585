import React, { useState } from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import { Trans, withI18n } from "@lingui/react";
import { useToasts } from "react-toast-notifications";
import { Icon } from "../Icons";
import { TransButtonPrimary } from "../Buttons";
import { deleteFileOfSubmission } from "../../Service/restapi/formSubmissionService";
import TooltipButton from "../buttons/TooltipButton";
import { ConfirmDialog, useModalState } from "../../Molecules/Dialogs";
import { useDropzone } from "react-dropzone";

export const EditableFileUpload = withI18n()(
  ({
    i18n,
    finishCallback,
    value: initialValue,
    fileName,
    disabled,
    formSubmissionId,
    placeholder,
    patchField,
    style,
  }) => {
    const [toastIds, setToastIds] = useState({});
    const { addToast, removeAllToasts } = useToasts();

    function toastCallback(id, filename) {
      toastIds[filename] = id;
      setToastIds(toastIds);
    }

    function onDrop(files) {
      let serverUrl =
        process.env.REACT_APP_SHARE_SERVICE_URL +
        "/formSubmissions/" +
        formSubmissionId +
        "/files/" +
        patchField;
      // This will upload the file after having read it
      files.forEach((file) => {
        addToast(
          i18n._("actions.upload-file-loading", { 0: file.name }),
          { appearance: "info" },
          (id) => {
            toastCallback(id, file.name);
          }
        );

        let data = new FormData();
        data.append("file", file);
        fetch(serverUrl, {
          // Your POST endpoint
          method: "PUT",
          credentials: "include",
          body: data, // This is your file object
        })
          .then((response) => {
            if (response.ok) {
              removeAllToasts();
              addToast(
                i18n._("actions.upload-file-success", { 0: file.name }),
                {
                  autoDismiss: true,
                  appearance: "success",
                }
              );
              let newValue = file.name;
              if (fileName) {
                newValue = fileName + "##" + newValue;
              }
              finishCallback({ [patchField]: newValue });
              //reloadCallback(file.name);
            } else {
              console.error("could not upload file " + file.name);
              removeAllToasts();
              addToast(i18n._("actions.upload-file-error", { 0: file.name }), {
                autoDismiss: true,
                appearance: "error",
              });
            }
          })
          .catch((error) => {
            addToast(i18n._("actions.upload-file-error", { 0: file.name }), {
              autoDismiss: true,
              appearance: "error",
            });
            removeAllToasts();
            console.error({ error });
            console.error("could not upload file " + file.name);
          });
      });
    }

    let styles = {
      container: {
        marginBottom: 5,
        marginTop: 5,
        display: "flex",
        justifyContent: "center",
      },
      dropZone: {
        backgroundColor: "#f6f8f9", //"#f5f7f8"
        width: "100% !important",
        maxWidth: 550,
        height: "150px !important",
        maxHeight: 200,
        border: "none !important",
        textAlign: "center",
        padding: 20,
        cursor: "pointer",
        borderRadius: 2,
      },
      icon: {
        width: 30,
        height: 30,
      },
      button: {
        marginTop: 10,
      },
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <div style={styles.inputContainer}>
        <ShowFiles
          fileName={fileName}
          formSubmissionId={formSubmissionId}
          finishCallback={finishCallback}
          patchField={patchField}
          disabled={disabled}
        />
        {!disabled && (
          <div
            {...getRootProps()}
            className="dropzone"
            style={styles.container}
          >
            <input {...getInputProps()} />
            <center>
              <Icon value={"FILE"} style={styles.icon} />
              <div>
                <Trans id={"actions.drop-files-here"} />
              </div>
              <TransButtonPrimary
                onClickHandler={() => {}}
                style={styles.button}
                text={"actions.select-file"}
              />
            </center>
          </div>
        )}
        {placeholder && (
          <Label sm={12} style={styles.label}>
            <Trans id={placeholder || ''} />
          </Label>
        )}
      </div>
    );
  }
);

const ShowFiles = withI18n()(
  ({
    i18n,
    formSubmissionId,
    fileName,
    finishCallback,
    patchField,
    disabled,
  }) => {
    if (!fileName) {
      return null;
    }
    const { show, toggle } = useModalState(false);
    const [deletingFileName, setDeletingFileName] = useState(undefined);
    let files = fileName.split("##");

    function deleteFile() {
      if (deletingFileName) {
        deleteFileOfSubmission(formSubmissionId, deletingFileName)
          .then((resp) => {
            if (resp.ok) {
              let newFiles = files.filter((n) => n !== deletingFileName);
              finishCallback({ [patchField]: newFiles.join("##") });
            }
          })
          .finally(() => {
            setDeletingFileName(undefined);
          });
      }
    }

    let fileItems = [];
    files.map((file, index) => {
      fileItems.push(
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <Icon value={"CHECK"} style={{ marginRight: 5 }} />
          <div style={{ marginRight: 5 }}>
            {i18n._("actions.upload-file-success", { 0: file })}
          </div>
          {!disabled && (
            <TooltipButton
              onClick={() => {
                setDeletingFileName(file);
                toggle();
              }}
              id={"deleteFile-" + index}
              text={i18n._("actions.delete-file")}
              icon={"TIMES"}
            />
          )}
        </div>
      );
    });
    return (
      <div style={{ marginBottom: 20 }}>
        {fileItems}
        <ConfirmDialog
          subject={i18n._("actions.delete-file-?")}
          text={i18n._("actions.delete-file-confirm", { 0: deletingFileName })}
          open={show}
          toggle={toggle}
          handler={deleteFile}
        />
      </div>
    );
  }
);

EditableFileUpload.propTypes = {
  value: PropTypes.number,
  finishCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
};

EditableFileUpload.defaultProps = {
  autoFocus: true,
};
