export const ajaxActions = {
    getResourceAsJson,
    getResponse,
    getBlobResponse,
    fileRequest,
    post,
    patch,
    del,
    exchange,
    get,
    put
};

function getResourceAsJson(url) {
    return getResponse(url).then((resp) => {
        return resp.json()
            .then((value) => {
                return value;
            })
            .catch((value) =>
                console.error("Failed to read json: ", value)
            )
    });
}

function getJson(resp) {
    return resp.json()
        .then((value) => {
            return value;
        })
        .catch((value) =>
            console.error("Failed to read json: ", value)
        )
}

function fileRequest(url, data) {
    return fetch(url, {
        method: data ? 'POST' : "GET",
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/octet-stream'},
        credentials: "include",
        body: data ? JSON.stringify(data) : undefined
    }).then(resp => {
        if (resp.ok) {
            return resp;
        } else {
            console.error("Response not OK (url: " + url + ")");
            throw resp;
        }
    }).catch(resp => {
        console.error("Failed to get data at " + url);
        throw resp;
    });
}

function getBlobResponse(url) {
    return fetch(url, {
        method: 'GET',
        credentials: "include",
        responseType: 'blob'
    }).then(resp => {
        if (resp.ok) {
            return resp;
        } else {
            console.error("Response not OK (url: " + url + ")");
            throw resp;
        }
    }).catch(resp => {
        console.error("Failed to get data at " + url);
        throw resp;
    });
}

function getResponse(url) {
    return fetch(url, {
        method: 'GET',
        headers: {'Accept': 'application/json'},
        credentials: "include"
    }).then(resp => {
        if (resp.ok) {
            return resp;
        } else {
            console.error("Response not OK (url: " + url + ")");
        }
    }).catch(reason => {
        console.error("Failed to get data at " + url + " because of a network error");
    });
}

function get(url) {
    return fetch(url, {
        method: 'GET',
        headers: {'Accept': 'application/json'},
        credentials: "include"
    }).then(resp => {
        return resp;
    }).catch(resp => {
        throw resp;
    });
}

function post(url, data) {
    return fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify(data)
    }).then(resp => {
        return resp;
    }).catch(resp => {
        console.error("Failed to sent data to " + url);
        throw resp;
    });
}

function exchange(method, url, data) {
    let request = {
        method: method,
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
    };

    if (data) {
        request.body = JSON.stringify(data);
    }

    return fetch(url, request).then(resp => {
        return resp;
    }).catch(resp => {
        console.error("Failed to sent data to " + url);
        throw resp;
    });
}

function put(url, data) {
    let request = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify(data),
    };

    return fetch(url, request).then(resp => {
        return resp;
    }).catch(resp => {
        console.error("Failed to sent data to " + url);
        throw resp;
    });
}

function patch(url, data) {
    return fetch(url, {
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify(data)
    }).then(resp => {
        return resp;
    }).catch((resp) => {
        console.error("Failed to sent data to " + url + " because of a network error");
        throw resp;
    });
}

function del(url, data) {
    return fetch(url, {
        method: 'DELETE',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'},
        credentials: "include",
        body: JSON.stringify(data)
    }).then(resp => {
        if (!resp.ok) {
            console.error("Response not OK (url: " + url + ")");

        }
        return resp;
    }).catch(reason => {
        console.error("Failed to delete data at " + url + " because of a network error");
    });
}