import React, {useContext, useState} from 'react';
import {Trans, withI18n} from "@lingui/react";
import {Alert, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {TransButtonDefault, TransButtonPrimary, TransButtonPrimaryWithSpinner} from "../Atoms/Buttons"
import {TransTextInputField} from "../Atoms/InputFields"
import {Icon} from "../Atoms/Icons";
import TooltipButton from "../Atoms/buttons/TooltipButton";
import {InputHelp} from "../Atoms/inputs/InputHelp";
import {NavigationGroup} from "../Organismns/Navigation/NavigationGroup";
import {WindowContext} from "../Service/Context/WindowContext";

export const useModalState = (initialState) => {
    const [show, setShow] = useState(initialState ? initialState : false);
    const toggle = () => setShow(!show);
    return {show, toggle};
};

export const ModalFlow = ({show, toggle, currentStep, menuItems, stepBodyComponents, stepHeaderComponents, next, prev, error, isLoading, canBeFinished, finish, finishButtonText}) => {
    const {windowWidth} = useContext(WindowContext);

    let styles = {
        masterDetail: {
            display: "flex",
            minHeight: 300,
        },
        footer: {
            paddingBottom: 10,
            marginTop: 20,
            display: "flex",
            justifyContent: "flex-end",
        }
    }
    return (
        <ModalDialog className={"modal-xl"} modalBodyStyle={{padding: windowWidth > 800 ? 0 : "1rem", paddingRight: 10}}
                     footerInBody={true}
                     header={
                         <>
                             {React.cloneElement(stepHeaderComponents[currentStep])}
                         </>
                     } body={
            <div style={styles.masterDetail}>
                {windowWidth > 800 && <FlowStepper menuItems={menuItems}/>}
                <div style={{
                    flex: 1,
                    paddingTop: 10,
                    paddingRight: 10,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}>
                    {React.cloneElement(stepBodyComponents[currentStep])}
                    {error ? <InputHelp errorDescription={error}/> : null}

                    <div style={styles.footer}>
                        <TransButtonDefault style={{marginRight: 5}} onClickHandler={toggle} text={"actions.cancel"}/>
                        {currentStep + 1 > 1 &&
                        <TransButtonPrimary style={{marginRight: 5}} onClickHandler={prev} text={"actions.prev"}/>}
                        {currentStep + 1 < stepBodyComponents.length &&
                        <TransButtonPrimary style={{marginRight: 5}} onClickHandler={next} text={"actions.next"}/>}
                        <TransButtonPrimaryWithSpinner isLoading={isLoading} disabled={!canBeFinished}
                                                       onClickHandler={finish}
                                                       text={finishButtonText ? finishButtonText : "common.finish"}/>

                    </div>
                </div>
            </div>
        } show={show} toggle={toggle}/>
    );
};

const FlowStepper = ({menuItems}) => {
    if (!menuItems) {
        return null;
    }
    let styles = {
        container: {
            backgroundColor: "rgb(245, 245, 245)",
            marginRight: 10,
            borderRight: "1px solid rgb(229, 229, 229)",
            borderBottom: "1px solid rgb(229, 229, 229)",
            minWidth: 155,
        },
        step: {},
    }

    let listItems = [];

    menuItems.map((value, index) => {

        listItems.push(
            <div key={index}>
                <NavigationGroup collapsed={false} value={value}/>
            </div>
        );
    });

    return (
        <div style={styles.container}>
            {listItems}
        </div>
    )
}

export const ModalDialog = ({show, className, toggle, header, help, alert, modalBodyStyle, body, footer}) => {
    let styles = {
        headContainer: {
            display: "flex",
            justifyContent: "space-between",
        }
    };

    let helpId = help;
    if (help) {
        helpId = helpId.replace(new RegExp(".", 'g'), "-");
    }

    return (
        <Modal backdrop={true} isOpen={show} className={className}>
            {header && <ModalHeader toggle={toggle}>
                <div style={styles.headContainer}>
                    <div style={{flex: 1, display: "flex", justifyContent: "space-between"}}>{header}</div>
                    {help && <TooltipButton icon="QUESTION" id={helpId} text={help}/>}
                </div>
            </ModalHeader>}
            <ModalBody style={modalBodyStyle}>
                {alert !== undefined &&
                <Alert color="danger">
                    <Icon value={"CAUTION"}/> <Trans id={alert}/>
                </Alert>
                }
                {body}
            </ModalBody>
            {footer && <ModalFooter>
                {footer}
            </ModalFooter>}
        </Modal>
    );
};

export const TransModalDialogWithTextField = withI18n()((props) => {
    return <ModalDialogWithTextField {...props} subject={props.i18n._(props.subject || '')} text={props.i18n._(props.text || '')} placeholder={props.i18n._(props.placeholder || '')}/>
});

export const ConfirmDialog = ({subject, children, isLoading, text, help, handler, toggle, open, confirmText, disableConfirm}) => {

    let styles = {
        container: {
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
        },
        rightActions: {
            flex: 1,
            display: "flex",
            justifyContent: "flex-end"
        }
    };
    let opts = {};
    if (disableConfirm) {
        opts['disabled'] = true;
    }

    return (
        <ModalDialog header={
            <Trans id={subject}/>
        } help={help} body={
            <Trans id={text}/>
        } footer={
            <div style={styles.container}>
                <div>
                    {children}
                </div>
                <div style={styles.rightActions}>
                    <TransButtonPrimaryWithSpinner isLoading={isLoading} {...opts} onClickHandler={() => {toggle(); handler()}}
                                        text={confirmText ? confirmText : "actions.yes"}/>&nbsp;
                    <TransButtonDefault onClickHandler={toggle}
                                          text={"actions.cancel"}/>
                </div>
            </div>
        } show={open} toggle={toggle}/>
    );
};


const ModalDialogWithTextField = (props) => {
    let opts = {};
    if (props.takenIdentifier !== undefined) {
        if (props.takenIdentifier.includes(props.value)) {
            opts['disabled'] = true;
        }
    }
    let primaryButtonText = props.primaryButton;
    if (primaryButtonText === undefined) {
        primaryButtonText = "actions.save";
    }
    return (
        <Modal isOpen={props.show} toggle={props.toggle} className={props.className}>
            <ModalHeader toggle={props.toggle}>{props.subject}</ModalHeader>
            <ModalBody>
                {props.text !== undefined ? props.text : ""}
                <TransTextInputField value={props.value} name={props.name} onChange={props.onChange} placeholder={props.placeholder}/>
            </ModalBody>
            <ModalFooter>
                <TransButtonPrimary onClickHandler={props.confirm} {...opts} text={primaryButtonText}>Ja</TransButtonPrimary>{' '}
                <TransButtonDefault onClickHandler={props.toggle} text={"actions.cancel"}/>
            </ModalFooter>
        </Modal>
    );
};