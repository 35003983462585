import React, {useContext, useEffect, useState} from 'react';
import {userActions} from '../../Service/UserAccountActions';
import {PasswordInputField, TransTextInputField} from "../../Atoms/InputFields";
import {Card, CardBody, Col, FormGroup, Row} from 'reactstrap';
import {Trans} from "@lingui/react";
import {Helmet} from "react-helmet";
import './LoginArea.scss'
import {ButtonPrimary} from "../../Atoms/Buttons";
import AuthenticationAlert from "./AuthenticationAlert"
import {useQuery} from "../../Helpers/UrlQuery";
import ImageLogo from "../../Atoms/ImageLogo";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserS } from '../../Service/restapi/UserS';

const LoginPage = (props) => {
    let query = useQuery();
    const [seachParams] = useSearchParams();
    const navigate = useNavigate();
    const [shareId, setShareId] = useState(seachParams.get("shareId"));
    const [password, setPassword] = useState(seachParams.get("key"));
    const [submitted, setSubmitted] = useState(false);
    const [reason, setReason] = useState(seachParams.get('reason'));
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (shareId && password) {
            handleSubmit();
        }
    }, []);

    function handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        //alert("Aufgrund von Wartungsarbeiten steht dieser Service erst ab ca. 20 Uhr wieder wie gewohnt zur Verfügung. Vielen Dank für Ihr Verständnis!")

        setSubmitted(true);

        if (shareId && password) {
            setLoading(true);
            UserS.login(shareId, password).then(response => {
                if (response.ok) {
                    navigate('/')
                }
                if (response.status === 403) {
                    setReason("wrongcredentials");
                } 
            }).catch(() => {
                setReason("serverunavailable");
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const {loggingIn} = props;

    let styles = {
        button: {
            marginTop: 10,
        },
        logo: {
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
        }
    };

    return (
        <div>
            <Helmet title={"Anmelden"}/>
            <br/><br/><br/>

            <Row style={{margin: "0px 5px"}}>
                <br/>
                <Col xs={12} sm={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                    <Card>
                        <CardBody>

                            {!shareId && <div style={{textAlign: 'center'}}><img style={{width: '150px'}} src="/img/logo.png" alt={"Logo"}/>
                            </div>}
                            {shareId && <div style={styles.logo}><div style={{maxWidth: 250,}}><ImageLogo shareId={shareId}/></div></div>}

                            <AuthenticationAlert reason={reason}/>

                            <form name="form">
                            {!query.get("shareId") && <FormGroup>
                                <TransTextInputField saveValue={() => {}} name="shareId" onChange={(e) => {setShareId(e.target.value)}} value={shareId} placeholder={"share-id"}/>
                                {submitted && !shareId &&
                                <div className="help-block"><Trans id={"validation.login.share-id"}/></div>
                                }
                            </FormGroup>}
                            <FormGroup>
                                <PasswordInputField saveValue={() => {}} name="password" onChange={(e) => {setPassword(e.target.value)}}  value={password} placeholder={"Password"}/>
                                {submitted && !password &&
                                <div className="help-block"><Trans id={"validation.login.share-password"}/><br/><br/></div>
                                }
                            </FormGroup>
                            <FormGroup style={styles.button}>
                                <ButtonPrimary type={"submit"} isLoading={isLoading} onClickHandler={handleSubmit} icon="LOGIN" text={"Login"} />

                                {loggingIn &&
                                <img alt={""}
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                }
                            </FormGroup>

                        </form>
                        </CardBody>
                    </Card>
                    <br/>
                </Col>
            </Row>
        </div>



    );

}

export const LoginPageProxy = LoginPage;