import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.jsx';
import {I18nProvider} from "@lingui/react";
import {messages as enMessages} from './locales/en/messages';
import {messages as deMessages} from './locales/de/messages';
import { i18n } from "@lingui/core";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18n.load({
  en: enMessages,
  de: deMessages
})
i18n.activate('de')

root.render(
  <React.StrictMode>
    <I18nProvider i18n={i18n}>
      <App />
    </I18nProvider>
  </React.StrictMode>
);