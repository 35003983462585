import {ajaxActions} from "../AjaxActions";

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

export const fetchFormSubmissionValues = (formSubmissionId) => ajaxActions.get(BASE_URL + "/formSubmissions/" + formSubmissionId + "/values").then(resp => {
    return resp;
});

export const patchFormSubmissionValues = (formSubmissionId, patch) => ajaxActions.patch(BASE_URL + "/formSubmissions/" + formSubmissionId + "/values", patch).then(resp => {
    return resp;
});

export const patchFormSubmission = (formSubmissionId, patch) => ajaxActions.patch(BASE_URL + "/formSubmissions/" + formSubmissionId, patch).then(resp => {
    return resp;
});

export const fetchFormSubmissionOfResource = async (resourceId) => ajaxActions.get(BASE_URL + "/resources/" + resourceId + "/formSubmissions").then(resp => {
    return resp;
});

export const deleteFileOfSubmission = (formSubmissionId, fileName) => ajaxActions.del(BASE_URL + "/formSubmissions/" + formSubmissionId + "/files/" + fileName).then(resp => {
    return resp;
});

export const deleteFormSubmissionValue = async (formSubmissionId, name) => {
    const res = await ajaxActions.del(`${BASE_URL}/formSubmissions/${formSubmissionId}/values/${name}`);
    return res.status === 204;
}
