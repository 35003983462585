import { useEffect, useState } from "react";
import Contract from "../Contract/Contract";
import { fetchResource } from "../../Service/restapi/resourceService";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import { Pane } from "../../Molecules/Pane";
import FormWrapper from "../FormWrapper";
import Document from "../Document/Document";
import ContractInjectWrapper from "../Contract/ContractInjectWrapper";
import Questionnaire from "../Questionnaire/Questionnaire";
import FrontBackPageHelper from "../FrontBackPageHelper";
import QuestionnaireInjectWrapper from "../Questionnaire/QuestionnaireInjectWrapper";
import PDFViewer from "../../Molecules/PDFViewer";

export const ProcessResource = ({ resourceId, reloadResourceStates }) => {
  const [resource, setResource] = useState(undefined);

  useEffect(() => {
    if (resourceId) {
      fetchResource(resourceId).then((resp) => {
        if (resp.ok) {
          resp.json().then((resource) => {
            setResource(resource);
          });
        }
      });
    }
  }, [resourceId]);

  if (!resource) {
    return <LoadingAnimation />;
  }

  let RenderResourceComponent;
  if (resource.entityType === "documents") {
    RenderResourceComponent = <Document resource={resource} />;
  } else if (resource.entityType === "contracts") {
    RenderResourceComponent = (
      <ContractInjectWrapper resource={resource}>
        <FrontBackPageHelper resource={resource}>
          <Contract />
        </FrontBackPageHelper>
      </ContractInjectWrapper>
    );
  } else if (resource.entityType === "questionnaires") {
    RenderResourceComponent = (
      <QuestionnaireInjectWrapper resource={resource}>
        <FrontBackPageHelper resource={resource}>
          <Questionnaire />
        </FrontBackPageHelper>
      </QuestionnaireInjectWrapper>
    );
  } else {
    RenderResourceComponent = (
      <>
        <PDFViewer
          entityType={resource.entityType}
          entityId={resource.entityId}
          fileName={resource.label}
        />
      </>
    );
  }

  return (
    <Pane
      style={{ marginBottom: 100, maxWidth: 850 }}
      className={"paper"}
      component={
        <>
          <FormWrapper
            resourceId={resource.id}
            reloadResourceStates={reloadResourceStates}
          >
            {RenderResourceComponent}
          </FormWrapper>
        </>
      }
    />
  );
};
