import { useContext, useEffect, useState } from "react";
import { TransTextInputField } from "../../Atoms/InputFields";
import { ConfirmDialog, useModalState } from "../../Molecules/Dialogs";
import { Icon } from "../../Atoms/Icons";
import Moment from "react-moment";
import {
  patchFormSubmission,
  patchFormSubmissionValues,
} from "../../Service/restapi/formSubmissionService";
import PdfDownload from "../PdfDownload";
import BookingConfirmedConfetti from "../../Atoms/BookingConfirmedConfetti/BookingConfirmedConfetti";
import ContractDrawSign from "./ContractDrawSign";
import { ResourceContext } from "../../Service/Context/ResourceContext";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import { TransButtonPrimaryWithSpinner } from "../../Atoms/Buttons";
import { isDefined } from "../../Utils/utils";
import { Group, Paper, Space } from "@mantine/core";
import ProviderSignature from "./ProviderSignature";
import moment from "moment";

const ContractSign = ({
  formSubmission,
  values,
  resource,
  customerSignType,
  reload,
}) => {
  const { customerSignedAt, customerPartnerSignedAt } = formSubmission;
  const { show, toggle } = useModalState(false);
  const [loading, setLoading] = useState(false);

  const [confettiShoot, setConfettiShoot] = useState(false);
  const {
    loading: _loading,
    customerContact,
    partnerIncluded,
  } = useContext(ResourceContext);

  const [sign, setSign] = useState(values.sign);
  const [partnerSign, setPartnerSign] = useState(values.partnerSign);

  const [signImage, setSignImage] = useState(values.signImage);
  const [partnerSignImage, setPartnerSignImage] = useState(
    values.partnerSignImage
  );

  useEffect(() => {
    setSign(values.sign);
    setPartnerSign(values.partnerSign);
  }, [values]);

  if (_loading) {
    return <LoadingAnimation />;
  }

  const collectSignatures = () => {
    if (customerSignType === "TEXT_FIELD") {
      return {
        sign,
        partnerSign: partnerIncluded ? partnerSign : undefined,
      };
    }
    return {
      signImage,
      partnerSignImage: partnerIncluded ? partnerSignImage : undefined,
    };
  };

  const signed = async () => {
    setLoading(true);
    await patchFormSubmissionValues(formSubmission.id, collectSignatures());
    patchFormSubmission(formSubmission.id, { submitted: true })
      .then((resp) => {
        if (resp.ok) {
          reload();
        }
      })
      .finally(() => {
        setLoading(false);
        setConfettiShoot(true);
      });
  };

  let styles = {
    container: {
      marginTop: 20,
      marginBottom: 10,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    sign: {
      fontFamily: "SafiarSignature, Courier",
      // paddingTop: 0,
      fontSize: 32,
      height: 50,
      maxWidth: 250,
    },
  };

  const signatureCompleted = () => {
    if (customerSignType === "TEXT_FIELD") {
      return partnerIncluded ? sign && partnerSign : sign;
    }
    return partnerIncluded
      ? isDefined(signImage) && isDefined(partnerSignImage)
      : isDefined(signImage);
  };

  return (
    <>
      <hr />

      <div>
        Mit folgender Unterzeichnung erklärst Du Dich mit obigen Bedingungen
        einverstanden.
      </div>

      <div style={styles.container}>
        {customerSignType && customerSignType === "TEXT_FIELD" && (
          <div>
            <Group position="center">
              <TransTextInputField
                disabled={formSubmission.lastSubmitted !== null}
                style={{ marginRight: 10 }}
                inputStyles={styles.sign}
                onChange={(e) => {
                  setSign(e.target.value);
                }}
                name="sign"
                value={sign}
                placeholder={`${customerContact.firstName} ${customerContact.lastName}`}
              />
              {formSubmission.lastSubmitted && (
                <p>{`Unterzeichnet am: ${moment(customerSignedAt).format(
                  "L"
                )}`}</p>
              )}
            </Group>
            {partnerIncluded && (
              <>
                <Group position="center">
                  <TransTextInputField
                    disabled={formSubmission.lastSubmitted !== null}
                    style={{ marginRight: 10 }}
                    inputStyles={styles.sign}
                    onChange={(e) => {
                      setPartnerSign(e.target.value);
                    }}
                    name="partnerSign"
                    value={partnerSign ? partnerSign : ""}
                    placeholder={`${customerContact.partner.firstName} ${customerContact.partner.lastName}`}
                  />
                  {formSubmission.lastSubmitted && (
                    <p>{`Unterzeichnet am: ${moment(
                      customerPartnerSignedAt
                    ).format("L")}`}</p>
                  )}
                </Group>
                <hr style={{ marginBottom: 40 }} />
              </>
            )}
          </div>
        )}

        {customerSignType && customerSignType === "BASE64" && (
          <>
            <h5 style={{ marginBottom: 0 }}>{`${
              customerContact.firstName ?? ""
            }  ${customerContact.lastName ?? ""}`}</h5>

            {!formSubmission.lastSubmitted && (
              <ContractDrawSign onChange={setSignImage} />
            )}
            {formSubmission.lastSubmitted && (
              <>
                <div
                  style={{ marginLeft: 20, alignSelf: "center", height: 50 }}
                >
                  <Icon value={"BOOKED"} style={{ color: "green" }} />{" "}
                  Unterzeichnet am{" "}
                  <Moment format="L">{customerSignedAt}</Moment>
                </div>
                <Paper shadow="xl" mb="xl">
                  <img
                    alt="customerSign"
                    style={{ width: "100%" }}
                    src={signImage}
                  />
                </Paper>
              </>
            )}

            {partnerIncluded && customerContact.partner && (
              <>
                <h5
                  style={{ marginBottom: 0, marginTop: 10 }}
                >{`${customerContact.partner.firstName} ${customerContact.partner.lastName}`}</h5>

                {!formSubmission.lastSubmitted && (
                  <ContractDrawSign onChange={setPartnerSignImage} />
                )}
                {formSubmission.lastSubmitted && (
                  <>
                    <div
                      style={{
                        marginLeft: 20,
                        alignSelf: "center",
                        height: 50,
                      }}
                    >
                      <Icon value={"BOOKED"} style={{ color: "green" }} />{" "}
                      Unterzeichnet am{" "}
                      <Moment format="L">{customerSignedAt}</Moment>
                    </div>
                    <Paper shadow="xl" mb="xl">
                      <img
                        alt="customerSign"
                        style={{ width: "100%" }}
                        src={partnerSignImage}
                      />
                    </Paper>
                  </>
                )}
              </>
            )}
          </>
        )}

        {!formSubmission.lastSubmitted && (
          <>
            <TransButtonPrimaryWithSpinner
              isLoading={loading}
              disabled={!signatureCompleted() || loading}
              style={{
                height: 40,
                width: 300,
                minWidth: 200,
                maxWidth: 300,
                alignSelf: "center",
                marginTop: 25,
              }}
              onClickHandler={toggle}
              text={"Unterzeichneten Vertrag absenden"}
            />
            {!signatureCompleted() && (
              <p
                style={{ marginTop: 10, color: "gray", fontSize: 12 }}
                align="center"
              >
                Der Vertrag muss vor der Bestätigung von allen Teilnehmern
                unterzeichnet werden
              </p>
            )}
          </>
        )}
        <BookingConfirmedConfetti active={confettiShoot} />
        {formSubmission.lastSubmitted && (
          <PdfDownload
            formSubmission={formSubmission}
            text="Vertrag herunterladen"
          />
        )}
        <ConfirmDialog
          text={"contract.confirm"}
          subject={"contract.confirm.head"}
          open={show}
          toggle={toggle}
          handler={signed}
        />
      </div>
      <Space h="xl" />
      <ProviderSignature signedAt={resource.lastModified} />
    </>
  );
};

export default ContractSign;
