import {getTokenExpDuration} from "../../Helpers/AuthorizationToken";
import { useEffect, useState} from "react";
import {ModalDialog} from "../../Molecules/Dialogs";
import {ButtonPrimary} from "../../Atoms/Buttons";
import { useJwtCookie } from "../../Service/CookiesProvider";
import { UserS } from "../../Service/restapi/UserS";

const ManualAuthRefresh = () => {
    const jwtCookie = useJwtCookie();
    const [loading, setLoading] = useState(false);
    const [seconds, setSeconds] = useState(jwtCookie ? Math.floor(getTokenExpDuration(jwtCookie) / 1000) : undefined);
    const secondsToDisplay = 60;

    useEffect(() => {
        if (loading && seconds) {
            if (seconds > secondsToDisplay) {
                setLoading(false);
            }
        }
        counter();
    }, [seconds]);



    function counter() {
        setTimeout(() => {
            setSeconds(Math.floor(getTokenExpDuration(jwtCookie) / 1000));
        }, 1000)
    }

    if (!seconds && seconds < 0) {
        UserS.logout();
        return null;
    }


    return (
        <ModalDialog body={
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h5 style={{margin: 20}}>Diese Sitzung läuft in {seconds} Sekunden ab.</h5>
                <ButtonPrimary isLoading={loading} id={"refreshAuth"} text={"Sitzung verlängern"}
                                               icon={"REFRESH"} onClickHandler={() => {
                    setLoading(true);
                    UserS.refreshToken();
                }}/>
            </div>
        } footer={
            <div>
            </div>
        } show={seconds < secondsToDisplay} toggle={() => {}}/>
    )
}

export default ManualAuthRefresh;