import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  NavigateFunction,
  useSearchParams,
} from "react-router-dom";
import { RoutingS } from "../../Service/RoutingS";
import { useJwtCookie } from "../../Service/CookiesProvider";
import { UserS } from "../../Service/restapi/UserS";

export const useRoutesAuthCheck = () => {
  const jwt = useJwtCookie();
  const [seachParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const { pathname } = useLocation();
  const authInProcess = seachParams.get("shareId") && seachParams.get("key");

  useEffect(() => {
    if (jwt) {
      UserS.refreshToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (jwt) {
      if (!authInProcess && RoutingS.isUnprotectedRoute(pathname)) {
        navigate("/");
      }
    } else {
      if (!RoutingS.isUnprotectedRoute(pathname)) {
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);
};
