export default ({blob}) => {
    if (!blob) { return null; }

    let styles = {
        img: {
            width: "100%",
            display: "block"
        }
    }

    return (
        <img style={styles.img} id="imgPreview" src={URL.createObjectURL(blob)}/>
    )


}