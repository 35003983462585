import React, {useContext} from "react";
import {LoadingAnimation} from "../../Service/ResourceAdapter";
import {WindowContext} from "../../Service/Context/WindowContext";
import QuestionList from "./QuestionList";
import TooltipButton from "../../Atoms/buttons/TooltipButton";

export default ({formSubmission, questionBundles, values, handleChange}) => {
    const {windowWidth} = useContext(WindowContext);

    if (!questionBundles) {
        return <LoadingAnimation />
    }



    let styles = {
        container: {
          marginTop: 20,
          marginBottom: 20,
        },
        firstColumn: {
            width: 100,
            display: "flex",
            justifyContent: "center",
        },
        textColumn: {
            flex: 1,
        },
        priceColumn: {
            width: windowWidth < 800 ? 80 : (windowWidth > 1100 ? 150 : 100),
            textAlign: "right"
        }
    };

    return (
        <div style={styles.container}>
            {questionBundles.map((bundle, index) => {
                return <div key={index}>
                       
                            <div style={{fontSize: 20}} dangerouslySetInnerHTML={{__html: bundle.title}}/>
                            {(bundle.description && bundle.description !== "") && <p style={{ color: "gray", fontSize: 12 }} dangerouslySetInnerHTML={{ __html: bundle.description }} />}
                        
                        <QuestionList questions={bundle.questions} key={index} formSubmission={formSubmission} values={values} handleChange={handleChange} columnStyles={styles} />
                    </div>
            })}
        </div>

    )
}