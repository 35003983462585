import React, {useContext, useEffect, useState} from "react";
import {fetchProfile} from "../../Service/restapi/profileService";

export default ({resourceId, children}) => {
    const [profile, setProfile] = useState(undefined);

    useEffect(() => {
        if (resourceId) {
            fetchProfile(resourceId).then(resp => {
                if (resp.ok) {
                    resp.json().then(profile => {
                        setProfile(profile);
                    })
                }
            })
        } else {
            console.error("resourceId is undefined");
        }

    }, [resourceId]);

    if (!profile) {
        return null;
    }

    return (
        React.cloneElement(children, {
            profile: profile
        })
    )
}