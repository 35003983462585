import {ListGroupItem} from "reactstrap";
import React from "react";
import {NavigationGroupIcon} from "./NavigationGroupIcon";
import {NavigationGroupCount} from "./NavigationGroupCount";
import {NavigationGroupText} from "./NavigationGroupText";

export const NavigationGroupChild = ({subIndex, collapsed, subValue, click, style}) => {
    let styles = {
        subListItemText: {
            padding: "2px 15px",
            marginBottom: 2,
            textAlign: "left",
            backgroundColor: "transparent !important",
        },
        subListItem: {
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
        },
    };

    return (
        <ListGroupItem key={"ListGroupItem-" + subIndex} className={"list-group-item-sub"} active={subValue.active} style={style} onClick={() => {click(subValue)}} tag="button" action>

            <div key={"sub-" + subIndex}  style={{padding: 5}}>
                {subValue.text && <div style={styles.subListItem} key={subIndex}>
                    {!collapsed &&  <NavigationGroupIcon collapsed={collapsed} active={subValue.active} value={subValue.icon}/>}

                    {!collapsed && <NavigationGroupText value={subValue.text}/>}

                    {!collapsed && <NavigationGroupCount value={subValue.count}/>}
                    {collapsed &&  <NavigationGroupIcon collapsed={collapsed} active={subValue.active} value={subValue.icon}/>}
                </div>}
            </div>
        </ListGroupItem>
    )
}