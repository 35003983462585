import React, {useState} from "react";
import {InputHelp} from "./InputHelp";
import PropTypes from "prop-types";
import {withI18n} from "@lingui/react";
import {RadioBox} from "./RadioBox";

export const EditableRadiobox = withI18n()(({finishCallback, value, placeholder, patchField, disabled, style, i18n, labelStyle, options, ...rest}) => {
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    function toggle(val) {
        setLoading(true);
        finishCallback({[patchField]: val}).catch((error) => setError(error.message)).then(() => setLoading(false));
    }

    let styles = {
        error: {
            borderColor: "red",
        },
        inputContainer: {
            marginBottom: 9,
        },
    };

    options.forEach(o => {
        o.onClick = () => toggle(o.label);
        o.disabled = disabled;
    });

    return (
        <div style={{...styles.inputContainer, ...style}}>
            <RadioBox options={options}/>
            {error ? <InputHelp errorDescription={error}/> : null}
        </div>
    )
});

EditableRadiobox.propTypes = {
    value: PropTypes.string,
    finishCallback: PropTypes.func,
    placeholder: PropTypes.string,
};