import {useEffect, useState, createContext, FC, ReactNode, useContext } from "react";
import { CookiesS } from "./CookiesS";
import { decode } from 'js-base64';

interface JwtCookieInterface {
  shareId: string,
  exp: number
}

export const CookieContext = createContext<string | null>(null);

export const CookieProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [payload, setPayload] = useState<string | null>(CookiesS.getCookiePayload());

  useEffect(() => {
    const tick = setInterval(() => {
      const cookiePayload = CookiesS.getCookiePayload();
      if (cookiePayload !== payload) {
        setPayload(cookiePayload);
      }
    }, 1000);
    return () => clearInterval(tick);
  }, [payload]);

  return (
    <CookieContext.Provider value={payload}>
      { children }
    </CookieContext.Provider>
  );
};

export const useJwtCookie = (): JwtCookieInterface | null => {
  const payload = useContext(CookieContext);
  if (payload) {
    const decodedBase64 = decode(payload);
    if (decodedBase64) {
      return JSON.parse(decodedBase64) as JwtCookieInterface;
    }
  }
  return null;
}
