import {ajaxActions} from "../AjaxActions";

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

export const fetchContract = (contractId) => ajaxActions.get(BASE_URL + "/contracts/" + contractId).then(resp => {
    return resp;
});

export const fetchDocument = (documentId) => ajaxActions.get(BASE_URL + "/documents/" + documentId).then(resp => {
    return resp;
});

export const fetchQuestionnaire = (questionnaireId) => ajaxActions.get(BASE_URL + "/questionnaires/" + questionnaireId).then(resp => {
    return resp;
});