import React, {useContext} from "react";
import {WindowContext} from "../../Service/Context/WindowContext";
import {NavigationGroupParent} from "./NavigationGroupParent";
import {NavigationGroupChild} from "./NavigationGroupChild";
import {NavigationChildMenu} from "./NavigationChildMenu";
import { useNavigate } from "react-router-dom";

export const NavigationGroup = ({value, index, collapsed}) => {
    const {windowWidth} = useContext(WindowContext);
    const navigate = useNavigate();

    function click(item) {
        if (typeof item.onClick === 'function') {
            item.onClick();
            if (windowWidth < 1000) {
                //setCollapsed(!collapsed);
            }
        } else if (item.to) {
            navigate(item.to);
            if (windowWidth < 1000) {
                //setCollapsed(!collapsed);
            }
        } else {
            //setCollapsed(!collapsed);
        }
    }

    let styles = {
        item: {
            padding: 0,
            textAlign: collapsed ? "center" : "left",
            borderColor: "#8ca3b5",
            backgroundColor: "transparent !important",
            borderRadius: 0,
            marginBottom: 4,
        },
        icon: {
            color: "#afaeae",
            alignSelf: "center",
            marginLeft: collapsed ? "auto" : "unset",
            marginRight: collapsed ? "auto" : "unset",
        },
    };

    return (
        <>
            {(!collapsed || !value.sub) && <NavigationGroupParent index={index} value={value} collapsed={collapsed} click={click} style={styles.item}
                                   iconStyle={styles.icon}/>}

            {(collapsed && value.sub && !value.inline) && <NavigationChildMenu key={index} value={value} click={click} style={styles.item}
                                                              iconStyle={styles.icon}/>}

            {value.sub ? value.sub.map((subValue, subIndex) => {
                if (!collapsed || subValue.icon) {
                    return (
                        <NavigationGroupChild collapsed={collapsed} subIndex={subIndex} subValue={subValue}
                                              click={click} style={styles.item}/>
                    )
                }
            }) : null}

        </>
    )
};