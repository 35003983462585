import React, { useContext } from "react";
import { ResourceContext } from "../../Service/Context/ResourceContext";


const TaxNumbers = ({ inline }) => {
    const { profile } = useContext(ResourceContext);

    let {taxNumber, taxOffice, vatId} = profile;

    let styles = {
        listStyle: {
            display: inline ? "inline-flex" : "block",
            listStyleType: inline ? "none" : "none",
            marginBottom: 5,
            paddingLeft: 0,
            flexWrap: "wrap",
        },
        itemStyle: {
            marginRight: 20,
            fontSize: 12,
        }
    };
    return (
        <ul style={styles.listStyle}>
            {vatId && <li style={{...styles.itemStyle, listStyleType: "none"}}><div>USt.Id: {vatId}</div></li>}
            {taxOffice && <li style={styles.itemStyle}><div>{taxOffice}</div></li>}
            {taxNumber && <li style={styles.itemStyle}><div>St.Nr.: {taxNumber}</div></li>}

        </ul>
    )
}

export default TaxNumbers;