import React from "react";
import {withI18n} from "@lingui/react";

import {Button, DropdownItem, NavLink, Spinner} from 'reactstrap';
import {Icon} from './Icons'
import {Link} from "react-router-dom";

export const TransNavLink = withI18n()((props) => {
    return <NavLink id={props.id} to={props.to} style={props.style} onClick={props.onClick} tag={Link}>{props.i18n._(props.text || '')}</NavLink>
});

export const TransNavLinkWithIcon = withI18n()((props) => {
    return <NavLink to={props.to} onClick={props.onClick} tag={Link}><Icon value={props.icon}/>{props.i18n._(props.text || '')}</NavLink>
});

export const TransButtonPrimary = withI18n()((props) => {
    return <ButtonPrimary {...props} text={props.i18n._(props.text || '')}/>
});

export const TransButtonPrimaryWithIcon = withI18n()((props) => {
    return <ButtonPrimaryWithIcon {...props}  text={props.i18n._(props.text || '')}/>
});

export const TransButtonPrimaryWithSpinner = withI18n()((props) => {
    return <ButtonPrimaryWithSpinner {...props}  text={props.i18n._(props.text || '')}/>
});

export const TransButtonSecondary = withI18n()((props) => {
    return <ButtonSecondary {...props}  text={props.i18n._(props.text || '')}/>
});

export const TransButtonSecondaryWithIcon = withI18n()((props) => {
    return <ButtonSecondaryWithIcon {...props} title={props.i18n._(props.title || '')} text={props.i18n._(props.text || '')}/>
});

export const TransButtonDefault = withI18n()((props) => {
    let color = "default";
    if (props.color !== undefined) {
        color = props.color;
    }
    return <ButtonDefault {...props} color={color} text={props.i18n._(props.text || '')}/>
});

export const TransButtonDefaultWithIcon = withI18n()((props) => {
    return <ButtonDefaultWithIcon {...props}  text={props.i18n._(props.text || '')}/>
});

export const TransButtonDefaultWithSpinner = withI18n()((props) => {
    return <ButtonDefaultWithSpinner {...props}  text={props.i18n._(props.text || '')}/>
});

export const TransButtonLink = withI18n()((props) => {
    return <ButtonLink {...props}  text={props.i18n._(props.text || '')}/>
});

export const TransButtonLinkWithIcon = withI18n()((props) => {
    return <ButtonLinkWithIcon {...props}  title={props.i18n._(props.title || '')} text={props.i18n._(props.text || '')}/>
});

export const TransDropdownItem = withI18n()((props) => {
    return <DropdownItem onClick={props.onClick} >{props.i18n._(props.text || '', props.values || {})}</DropdownItem>
});

export const TransDropdownItemWithIcon = withI18n()((props) => {
    return <DropdownItem onClick={props.onClick} disabled={props.disabled} style={props.style} >{props.icon !== undefined ? <Icon
        value={props.icon}/> : ''} <span>{props.i18n._(props.text || '', props.values || {})}</span></DropdownItem>
});

export const ButtonPrimary = (props) => {
    return (
        <Button type="button" data-testid={"button"} style={props.style} disabled={props.disabled} id={props.id} className={props.className} onClick={(e) => props.onClickHandler(e)}
                color="primary">{props.isLoading ? <Spinner size="sm" color="light" /> : null} {props.text}</Button>
    );
};

class ButtonPrimaryWithIcon extends React.Component {
    render() {
        return (
            <Button type={this.props.type !== undefined ? this.props.type : "button"} id={this.props.id} className={"icon-and-text " + this.props.className} disabled={this.props.disabled}
                    style={this.props.style} onClick={this.props.onClickHandler} color="primary"><Icon
                value={this.props.icon}/> {this.props.text}</Button>
        );
    }
}

class ButtonPrimaryWithSpinner extends React.Component {
    render() {
        return (
            <Button data-testid={this.props.testId} type={this.props.type !== undefined ? this.props.type : "button"} id={this.props.id} className={"icon-and-text " + this.props.className} disabled={this.props.disabled}
                    style={this.props.style} onClick={this.props.onClickHandler} color="primary">{this.props.isLoading ? <Spinner size="sm" color="light" /> : <Icon
                value={this.props.icon}/>} {this.props.text}</Button>
        );
    }
}

export class ButtonSecondary extends React.Component {
    render() {
        return (
            <Button type="button" className={this.props.className} disabled={this.props.disabled} style={this.props.style}
                    onClick={this.props.onClickHandler}
                    color="secondary">{this.props.isLoading ? <Spinner size="sm" color="light"/> : <Icon
                value={this.props.icon}/>} {this.props.text}</Button>
        );
    }
}

class ButtonSecondaryWithIcon extends React.Component {
    render() {
        return (
            <Button type="button" className={"icon-and-text " + this.props.className} title={this.props.title} style={this.props.style} onClick={this.props.onClickHandler}
                    color="secondary"><Icon
                value={this.props.icon}/> {this.props.text}</Button>
        );
    }
}

export const ButtonDefault = (props) => {

    let color = "default";
    if (props.color !== undefined) {
        color = props.color;
    }

    return (
        <Button type="button" id={props.id} disabled={props.disabled} className={"icon-and-text " + props.className} onMouseDown={props.onMouseDown} style={props.style} onClick={props.onClickHandler}
                color={color}>{props.text}</Button>
    );

};

const ButtonDefaultWithIcon = (props) => {
    let color = "default";
    if (props.color) {
        color = props.color;
    }
    return (
        <Button type="button" className={props.text !== undefined ? "icon-and-text " : "" + props.className} id={props.id} style={props.style} onClick={props.onClickHandler}
                color={color} >{props.isLoading ? <Spinner size="sm" color="dark" /> : <Icon
            value={props.icon}/>}{props.text}</Button>
    );
};

class ButtonDefaultWithSpinner extends React.Component {
    render() {
        return (
            <Button type="button" disabled={this.props.disabled} className={this.props.text !== undefined ? "icon-and-text " : "" + this.props.className} id={this.props.id} style={this.props.style} onClick={this.props.onClick}
                    color="default">{this.props.isLoading ? <Spinner size="sm" color="dark" /> : <Icon
                value={this.props.icon}/>}{this.props.text}</Button>
        );
    }
}

export const ButtonLink = ({style, className, text, onClick, isLoading, icon, disabled}) => {
    return (
        <Button color="link" style={style} className={className} disabled={disabled} onClick={onClick}>{isLoading ?
            <Spinner size="sm" color="dark"/> : (icon ? <Icon
                value={icon}/> : null)} {text}</Button>
    );
};

export class ButtonLinkWithIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {color : "default"};
        if (this.props.color !== undefined) {
            this.state.color = this.props.color;
        }
    }

    render() {
        return (
            <Button className={this.props.className}  data-tour={this.props.dataTour} title={this.props.title} color={"icon"} disabled={this.props.disabled}
                    onClick={this.props.onClick} style={this.props.style}>{this.props.isLoading ? <Spinner size="sm" color="dark" /> : <Icon
                value={this.props.icon}/>} {this.props.text}</Button>
        );
    }
}
