interface ParsedCookies {
  [name: string]: string
}

const getCookiePayload = (name: string = 'jwt_share_decoded'): string | null => {
  const cookies = document.cookie;
  if (cookies === '') {
    return null;
  }
  return parseCookies(cookies)[name];
}

const parseCookies = (cookies: string): ParsedCookies => {
  return cookies.split(';')
  .map(v => v.split('='))
  .reduce((acc: any, v) => {
    acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
    return acc;
  }, {});
}

export const CookiesS = {
  getCookiePayload
}