import {Trans} from "@lingui/react";
import {Alert} from "reactstrap";
import React from "react";

export default ({reason}) => {

    if (reason === undefined || reason === null) {
        return null;
    }

    let color;
    switch (reason) {
        case "logoutsuccessful":
            color = "success";
            break;
        case "wrongcredentials":
            color = "danger";
            break;
        case "wrongemail":
            color = "warning";
            break;
        default:
            color = "warning"
    }

    return (
        <Alert color={color}>
            <Trans id={'alert.' + reason}/>
        </Alert>
    )
}
