import React, {useEffect, useState} from "react";
import {InputHelp} from "./InputHelp";
import PropTypes from "prop-types";
import {withI18n} from "@lingui/react";
import {TransSelectField} from "../InputFields";

export const EditableSelectField = withI18n()(({finishCallback, value: initialValue, placeholder, patchField, options, ...rest}) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    function finish(option) {
        setLoading(true);
        setValue(value);
        finishCallback({[patchField]: option}).catch((error) => setError(error.message)).then(() => setLoading(false));
    }

    let styles = {
        error: {
            borderColor: "red",
        },
        input: {
            marginBottom: 0,
            height: "100%",
        },
        label: {
            color: "#8ca3b5",
            fontWeight: 800,
        },
        inputContainer: {
        },
        emailAddress: {
            height: "100%",
            minHeight: 34,
        }
    };

    let selectedValue = options.find(o => o.value === value);
    return (
        <div style={styles.inputContainer}>
            <TransSelectField className={loading ? "loading" : ""} options={options} value={selectedValue} onChangeHandler={finish} placeholder={placeholder} {...rest}/>
            {error ? <InputHelp errorDescription={error}/> : null}

        </div>
    )

});

EditableSelectField.propTypes = {
    value: PropTypes.string,
    finishCallback: PropTypes.func,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
};