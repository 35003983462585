import React from "react";
import ImageLogo from "../Atoms/ImageLogo";


export default ({documentLayout}) => {
    let styles = {
        logo: {
            width: getLogoWidth(documentLayout.logoSize),
            marginBottom: 20,
            alignSelf: documentLayout.logoPosition > 160 ? "flex-end" : (documentLayout.logoPosition < 60 ? "center" : "center"),
        }
    };

    return (
        <div style={styles.logo}><ImageLogo /></div>
    )
}

function getLogoWidth(logoSize) {
    if (logoSize === "EXTRA_SMALL") return 150;
    if (logoSize === "SMALL") return 220;
    if (logoSize === "MEDIUM") return 300;
    if (logoSize === "BIG") return 450;
    if (logoSize === "FULLSIZE") return "100%";
}