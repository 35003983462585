import React from "react";
import ImageLoadAndRender from "../../Atoms/ImageLoadAndRender";

export default ({textBlock}) => {

    let styleFirstColumn = textBlock.styleFirstColumn;
    let styleSecondColumn = textBlock.styleSecondColumn;

    let styles = {
        container: {
            marginBottom: 10,
            display: "flex",
            flexWrap: "wrap",
        },
        heading: {
            fontSize: 16,
        }
    }
    if (styleFirstColumn) {
        styles = {
            ...styles,
            firstColumn: {
                width: styleSecondColumn ? "50%" : "100%",
                minWidth: 230,
                textAlign: styleFirstColumn.horizontalAlignment,
                backgroundColor: styleFirstColumn.backgroundColor ? styleFirstColumn.backgroundColor : undefined,
                color: styleFirstColumn.fontColor ? styleFirstColumn.fontColor : undefined,
                border: styleFirstColumn.borderColor ? "1px solid " + styleFirstColumn.borderColor : undefined,
                borderRadius: styleFirstColumn.borderRadius,
                paddingTop: styleFirstColumn.paddingTop,
                paddingBottom: styleFirstColumn.paddingBottom,
                paddingLeft: styleFirstColumn.paddingLeft,
                paddingRight: styleFirstColumn.paddingRight,
                marginBottom: 10,
            },
        }
    }

    if (styleSecondColumn) {
        styles = {
            ...styles,
            secondColumn: {
                width: "50%",
                minWidth: 230,
                textAlign: styleSecondColumn.horizontalAlignment,
                backgroundColor: styleSecondColumn.backgroundColor ? styleSecondColumn.backgroundColor : undefined,
                color: styleSecondColumn.fontColor ? styleSecondColumn.fontColor : undefined,
                border: styleSecondColumn.borderColor ? "1px solid " + styleSecondColumn.borderColor : undefined,
                borderRadius: styleSecondColumn.borderRadius,
                paddingTop: styleSecondColumn.paddingTop,
                paddingBottom: styleSecondColumn.paddingBottom,
                paddingLeft: styleSecondColumn.paddingLeft,
                paddingRight: styleSecondColumn.paddingRight,
                marginBottom: 10,
            }
        }
    }

    return (

        <div style={styles.container}>
            <div style={styles.firstColumn}>
                {textBlock.imageFileNameFirstColumn && <ImageLoadAndRender entityType={textBlock.imageEntityTypeFirstColumn} entityId={textBlock.imageEntityIdFirstColumn} fileName={textBlock.imageFileNameFirstColumn}/>}
                {textBlock.headerFirstColumn && <div dangerouslySetInnerHTML={{__html: textBlock.headerFirstColumn}}/>}
                <div dangerouslySetInnerHTML={{__html: textBlock.contentFirstColumn}} />
            </div>
            {textBlock.contentSecondColumn && <div style={styles.secondColumn}>
                {textBlock.imageFileNameSecondColumn && <ImageLoadAndRender entityType={textBlock.imageEntityTypeSecondColumn} entityId={textBlock.imageEntityIdSecondColumn} fileName={textBlock.imageFileNameSecondColumn}/>}
                {textBlock.headerSecondColumn && <div style={styles.heading} dangerouslySetInnerHTML={{__html: textBlock.headerSecondColumn}}/>}
                <div dangerouslySetInnerHTML={{__html: textBlock.contentSecondColumn}}/>
            </div>}
        </div>
    )
}