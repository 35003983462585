import React from "react";
import PropTypes from "prop-types";

/**
 * StringFormatter is a formatter which simply passes strings trough, but
 * returns a dash for empty or null values.
 */
export const TextBlockFormatter = ({value, disabled}) => (
    <textarea onChange={() => {}} rows={14} className={"form-control " + (disabled ? "disabled" : "")} value={(value === "" || value === null || value === undefined) ? "-" : value}/>
);

TextBlockFormatter.propTypes = {
    value: PropTypes.string,
};