import {
  createContext,
  ReactNode,
  FC,
  useState,
  useMemo,
  useEffect,
} from "react";
import { Contact } from "../../types/Contact";
import { DocumentLayout } from "../../types/DocumentLayout";
import { Profile } from "../../types/Profile";
import {
  fetchBankAccount,
  fetchDocumentLayout,
  fetchProfile,
} from "../restapi/profileService";
import { fetchContact } from "../restapi/contactService";
import { BankAccount } from "../../types/BankAccount";

interface ResourceContextInterface {
  profile: Profile | undefined;
  contact: Contact | undefined;
  customerContact: Contact | undefined;
  documentLayout: DocumentLayout | undefined;
  bankAccount: BankAccount | undefined;
  loading: boolean;
  partnerIncluded: boolean;
}

export const ResourceContext = createContext({} as ResourceContextInterface);

export const ResourceContextProvider: FC<{
  children: ReactNode;
  resourceId: number;
  contactId: number;
  customerContactId: number;
}> = ({ children, resourceId, contactId, customerContactId }) => {
  const [profile, setProfile] = useState<Profile>();
  const [contact, setContact] = useState<Contact>();
  const [customerContact, setCustomerContact] = useState<Contact>();
  const [documentLayout, setDocumentLayout] = useState<DocumentLayout>();
  const [bankAccount, setBankAccount] = useState<BankAccount>();
  const [loading, setLoading] = useState<boolean>(true);
  const partnerIncluded = customerContact !== undefined && customerContact.partner !== undefined && customerContact.partner !== null; 

  useEffect(() => {
    if (resourceId) {
      fetchDocumentLayout(resourceId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setDocumentLayout);
        }
      });
      fetchProfile(resourceId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setProfile);
        }
      });
      fetchBankAccount(resourceId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setBankAccount);
        }
      });
    }
  }, [resourceId]);

  useEffect(() => {
    if (resourceId && customerContactId) {
      fetchContact(customerContactId, resourceId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setCustomerContact);
        }
      });
    }
  }, [resourceId, customerContactId]);

  useEffect(() => {
    if (resourceId && contactId) {
      fetchContact(contactId, resourceId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setContact);
        }
      });
    }
  }, [resourceId, contactId]);

  useEffect(() => {
    if (profile && contact && documentLayout && customerContact) {
      setLoading(false);
    }
  }, [profile, contact, customerContact, documentLayout, customerContactId]);

  const value = useMemo(
    () => ({
      profile,
      contact,
      customerContact,
      documentLayout,
      bankAccount,
      loading,
      partnerIncluded,
    }),
    [profile, contact, customerContact, documentLayout, bankAccount, loading, partnerIncluded]
  );

  return (
    <ResourceContext.Provider value={value}>
      {children}
    </ResourceContext.Provider>
  );
};
