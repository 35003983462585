import {EditableFormAttributeValueProperty} from "../../Atoms/EditableProperties/EditableFormAttributeValueProperty";
import {EditableRadiobox} from "../../Atoms/inputs/EditableRadiobox";
import {EditableTextBlockWrapper} from "../../Atoms/inputs/EditableTextBlockWrapper";
import {TextBlockFormatter} from "../../Atoms/Formatter/TextBlockFormatter";
import React, {useEffect, useState} from "react";
import {withI18n} from "@lingui/react";

export default withI18n()(({i18n, handleChange, formSubmissionId, lastSubmitted, values, patchField, answers, additionalAnswer}) => {
    const [additionalAnswerSingleChoiceValue, setAdditionalAnswerSingleChoiceValue] = useState(getAdditionalValue());

    useEffect(() => {
        setAdditionalAnswerSingleChoiceValue(getAdditionalValue());
    }, [additionalAnswer, values, patchField])

    function getAdditionalValue() {
        let findAnswer = answers.filter(a => a.value === values[patchField]);
        if (findAnswer.length === 0) {
            if (values[patchField]) {
                console.log("no predefined option with value " + values[patchField]);
                if (values[patchField] === i18n._("question.answer.other")) {
                    return "";
                }
                return values[patchField];
            } else {
                console.log("no option selected");
                return undefined;
            }
        } else {
            console.log("found predefined option with value " + values[patchField]);
            return undefined;
        }
    }

    let options = [];
    answers.forEach(a => {
        options.push({id: a.id, label: a.value, checked: values[patchField] === a.value, onClick: () => {
                setAdditionalAnswerSingleChoiceValue(undefined);
            }});
    })
    if (additionalAnswer) {
        options.push({id: additionalAnswer.id, label: i18n._("question.answer.other"), checked: additionalAnswerSingleChoiceValue !== undefined , onClick: () => {}});
    }

    return (
        <>
            <EditableFormAttributeValueProperty
                formSubmissionId={formSubmissionId}
                patchField={patchField}
                changeHandler={(p) => {
                    if (p[patchField] !== i18n._("question.answer.other")) {
                        setAdditionalAnswerSingleChoiceValue(undefined);
                    }
                    handleChange(patchField, p[patchField])
                }}
                entity={{patchField: values[patchField]}}
                editFormatter={EditableRadiobox}
                options={options}
                editOnly={true}
                disabled={lastSubmitted}
            />

            {additionalAnswerSingleChoiceValue !== undefined &&
                <div style={{marginTop: 10,}}>
                    <EditableFormAttributeValueProperty
                        formSubmissionId={formSubmissionId}
                        patchField={patchField}
                        changeHandler={(p) => {
                            setAdditionalAnswerSingleChoiceValue(p[patchField]);
                            handleChange(patchField, p[patchField])
                        }}
                        entity={{[patchField]: additionalAnswerSingleChoiceValue}}
                        big
                        editFormatter={EditableTextBlockWrapper}
                        labelFormatter={TextBlockFormatter}
                        disabled={lastSubmitted}
                    />
                </div>
            }
        </>
    )


});
