import {Input} from "reactstrap";
import React from "react";
import {ButtonGroup} from "reactstrap";
import {Trans} from "@lingui/react";

export const RadioBox = ({options}) => {

    let styles = {
        container: {
            display: "flex",
            flexDirection: "column",
        }
    };

    let items = [];

    options.map((val, index) => {
        items.push(
            <label key={index} className={"radiobox-radiobox"} data-tour={val.dataTour} style={{display: "flex"}}>
                <Input type="radio" className={"radiobox-radioboxInput"} disabled={val.disabled} onClick={val.onClick} name={val.name} onChange={() => {}} checked={val.checked} />
                <span className={"ua-radiobox-radioboxLabel radiobox-radioboxLabel " + (val.disabled ? "disabled" : "")} style={{flex: 1}}>
                        <div dangerouslySetInnerHTML={{__html: val.label}}/>
                    </span>
            </label>
        )
    });

    return (
        <ButtonGroup style={styles.container}>
            {items}
        </ButtonGroup>
    )
};