import React, {useContext, useEffect, useState} from "react";
import {fetchLogo} from "../Service/restapi/fileShareService";
import ImageRender from "./ImageRender";
import {LoadingAnimation} from "../Service/ResourceAdapter";

export default ({shareId}) => {
    const [blob, setBlob] = useState(undefined);
    const [logoUnavailable, setLogoUnavailable] = useState(false);

    useEffect(() => {
        fetchImage();
    }, []);

    function fetchImage() {
        fetchLogo(shareId).then((response) => {
            return response.blob();
        }).then((blob) => {
            setBlob(blob);
        }).catch(() => {
            console.error("no logo")
            setLogoUnavailable(true);
        });
    }

    if (logoUnavailable) {
        return null; {
        /*<div style={{textAlign: "center"}}>
            <b>Dein LOGO hier</b>
            <div>Füge es in den <a target={"_blank"}
                                   href={"https://app.bookitup.de/settings/documents"}>Einstellungen</a> hinzu.
            </div>
        </div>*/
    }
    }

    if (!blob) {
        return <LoadingAnimation/>;
    }

    return (
        <ImageRender blob={blob} />
    )


}