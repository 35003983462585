import { useContext } from "react";
import Moment from "react-moment";
import { ResourceContext } from "../Service/Context/ResourceContext";

const SubjectDate = ({ subject, date }) => {
  const { contact } = useContext(ResourceContext);
  let styles = {
    subject: {
      fontSize: 20,
      fontWeight: 800,
      marginBottom: 10,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <div
        style={styles.subject}
        dangerouslySetInnerHTML={{ __html: subject }}
      />
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        {contact && contact.city && <div>{contact.city},&nbsp;</div>}
        {date && <Moment format="L">{date}</Moment>}
      </div>
    </div>
  );
};

export default SubjectDate;
