import React, {useContext, useEffect, useState} from "react";
import {fetchDocumentLayout} from "../../Service/restapi/profileService";

export default ({resourceId, children}) => {
    const [documentLayout, setDocumentLayout] = useState(undefined);

    useEffect(() => {
        fetchDocumentLayout(resourceId).then(resp => {
            if (resp.ok) {
                resp.json().then(documentLayout => {
                    setDocumentLayout(documentLayout);
                })
            }
        })
    }, [resourceId]);

    if (!documentLayout) {
        return null;
    }

    return (
        React.cloneElement(children, {
            documentLayout: documentLayout
        })
    )
}