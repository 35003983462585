const UNPROTECTED_ROUTES: string[] = [
  '/login', 
  '/termsandconditions',
  '/privacypolicy'
];

const isUnprotectedRoute = (routePath: string): boolean => {
  return UNPROTECTED_ROUTES.includes(routePath);
}

export const RoutingS = {
  isUnprotectedRoute
}