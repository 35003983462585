import React, {useContext, useEffect, useState} from "react";
import {fetchFormSubmissionValues} from "../Service/restapi/formSubmissionService";
import {fetchResourceFormSubmission} from "../Service/restapi/resourceService";
import {LoadingAnimation} from "../Service/ResourceAdapter";
import {WindowContext} from "../Service/Context/WindowContext";
import DocumentLayoutLogo from "./DocumentLayoutLogo";
import DocumentLayoutInjectWrapper from "./Profile/DocumentLayoutInjectWrapper";

const FormWrapper = ({resourceId, children, reloadResourceStates}) => {
    const [values, setValues] = useState(undefined);
    const [formSubmission, setFormSubmission] = useState(undefined);
    const {windowWidth} = useContext(WindowContext);

    useEffect(() => {
        getValues();
    }, [formSubmission]);

    useEffect(() => {
        getFormSubmission();
    }, [resourceId]);

    function handleChange(name, value) {
        let tmp = Object.assign({}, values);
        if (value !== undefined && value !== null) {
            tmp[name] = value;
        } else {
            delete tmp[name];
        }

        setValues(tmp);
    }

    function getFormSubmission() {
        reloadResourceStates()
        if (resourceId) {
            fetchResourceFormSubmission(resourceId).then(resp => {
                if (resp.ok) {
                    resp.json().then(formSubmission => {
                        setFormSubmission(formSubmission)
                    })
                } else {
                    console.error("could not fetch formSubmission of resource " + resourceId);
                }
            }).catch(error => {
                console.error({error});
                console.error("could not fetch formSubmission of resource " + resourceId);
            })
        }
    }

    function getValues() {
        if (formSubmission) {
            fetchFormSubmissionValues(formSubmission.id).then(resp => {
                if (resp.ok) {
                    resp.json().then(values => {
                        setValues(values);
                    })
                } else {
                    console.error("could not fetch formSubmissionValues of formSubmission " + formSubmission.id);
                }
            }).catch(error => {
                console.error({error});
                console.error("could not fetch formSubmissionValues of formSubmission " + formSubmission.id);
            })
        }
    }

    if (!formSubmission || !values) {
        return <LoadingAnimation/>
    }

    let styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            padding: windowWidth > 500 ? 50 : 5,
        },
    };

    return <div style={styles.container}>
        <DocumentLayoutInjectWrapper resourceId={resourceId}>
            <DocumentLayoutLogo />
        </DocumentLayoutInjectWrapper>

        {React.cloneElement(children, {
            values: values,
            formSubmission: formSubmission,
            reloadFormSubmission: getFormSubmission,
            handleChange: handleChange
        })}
    </div>
}

export default FormWrapper;