import React from "react";

export const NavigationGroupCount = ({value}) => {

    let styles = {
        count: {
            fontWeight: 800,
            color: "rgb(175, 174, 174)",
            fontFamily: "RobotoRegular",
            alignSelf: "center",
            marginRight: 5,
            marginLeft: 5,
            flex: 1,
            textAlign: "right"
        },
    };

    if (!value && value !== 0) {
        return null;
    }

    return (
        <span style={styles.count}>{value}</span>
    )
};