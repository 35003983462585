import React, {useContext, useEffect, useState} from "react";
import {fetchDocument} from "../../Service/restapi/sharedContractService";
import PositionList from "./PositionList";


export default ({formSubmission, resourceId, documentId, values, handleChange, document}) => {
    const [positions, setPositions] = useState(undefined);
    const [sum, setSum] = useState(undefined);
    const [isSummable, setSummable] = useState(undefined);
    const [taxType, setTaxType] = useState(undefined);

    useEffect(() => {
        fetchDocument(documentId).then(resp => {
            if (resp.ok) {
                resp.json().then(document => {
                    setPositions(document.positions);
                    setSum(document.sum);
                    setSummable(document.summable);
                    setTaxType(document.taxType);
                })
            } else {
                console.error("could not fetch positions of document " + documentId);
            }
        }).catch(error => {
            console.error("could not fetch positions of document " + documentId);
            console.error({error});
        });
    }, [documentId]);

    return <PositionList resourceId={resourceId} document={document} positions={positions} isSummable={isSummable} taxType={taxType} formSubmission={formSubmission} values={values} handleChange={handleChange} />
}