import {Document, Page} from "react-pdf";
import {LoadingAnimation} from "../Service/ResourceAdapter";
import {Trans} from "@lingui/react";
import React, {useContext, useEffect, useState} from "react";
import {fetchFile} from "../Service/restapi/fileShareService";


const PdfViewerExternNavigation = ({entityType, entityId, fileName, currentPage}) => {
    const [blob, setBlob] = useState(undefined);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        fetchFile(entityType, entityId, fileName).then(resp => {
            if (resp.ok) {
                resp.blob().then(blob => {
                    setBlob(blob);
                })
            }
        });
    }, []);

    function onDocumentLoadSuccess(numPages) {
        setTotalPages(numPages._pdfInfo.numPages);
    }

    return (
        <>
            {blob && <Document
                file={blob}
                loading={<LoadingAnimation/>}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(e) => {
                    console.error(e);
                }}
                error={<Trans id={"document.generate.error"}/>}
            >
                <Page pageNumber={currentPage} width={1000} />
            </Document>}
        </>
    )

}

export default PdfViewerExternNavigation;

