import {ProcessResource} from "./ProcessResources/ProcessResource";
import React from "react";
import { useParams } from "react-router-dom";


export default () => {
    const { id: resourceId }  = useParams();
    
    /*function toNextResource() {
        let currentIndex = resources.indexOf(resource);
        if (hasNextResource()) {
            setResource(resources[currentIndex+1]);
        }
    }

    function hasNextResource() {
        let currentIndex = resources.indexOf(resource);
        return currentIndex !== resources.length-1;
    }

    function getNextResource() {
        let currentIndex = resources.indexOf(resource);
        if (hasNextResource()) {
            return resources[currentIndex+1];
        }
        return null;
    }

    function getLastSubmitted(resource) {
        let submission = submissions.filter(s => (s.entityId === resource.entityId && s.entityType === resource.entityType));
        let submitted = null;
        if (submission.length > 0) {
            submitted = submission[0].lastSubmitted;
        }
        return submitted;
    }*/

    return (
        <div>
            <ProcessResource resourceId={resourceId} reloadResourceStates={() => {}} />

        </div>
    )
}