import Moment from 'react-moment/dist/index';
import {Route, Routes, BrowserRouter } from "react-router-dom";
import {ToastProvider} from 'react-toast-notifications';
import {UserAgentProvider} from '@quentin-sommer/react-useragent'
import {LoginPageProxy} from "./Templates/Security/Login";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TermsAndConditionsProxy} from "./Templates/Security/TermsAndConditions";
import {PrivacyPolicyProxy} from "./Templates/Security/PrivacyPolicy";
import {DndProvider} from "react-dnd";
import {WindowContextProvider} from "./Service/Context/WindowContext";
import {FocusedNodeContextProvider} from "./Service/Context/FocusedNode";
import {Helmet} from "react-helmet";
import {Dashboard} from "./Templates/Dashboard/Dashboard";
import Footer from "./Templates/Footer";
import Resource from "./Templates/Resource";
import PageContainer from "./Molecules/PageContainer";
import './app.scss'
import { useRoutesAuthCheck } from "./Templates/Security/useRoutesAuthCheck";
import { CookieProvider } from "./Service/CookiesProvider";
import 'moment/locale/de';
import moment from "moment";

const App = () =>  {
    return (
    <UserAgentProvider ua={window.navigator.userAgent}>
      <CookieProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </CookieProvider>
    </UserAgentProvider>
  )
}

const AppRoutes = () => {
  useRoutesAuthCheck();
  Moment.globalLocale = 'de';
  moment().locale('de')

  return (
    <PageContainer>
      <Helmet title={"bookitup"}>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="../static/img/favicon.ico"
        />
      </Helmet>
      <WindowContextProvider>
        <FocusedNodeContextProvider>
            <DndProvider backend={HTML5Backend}>
              <ToastProvider placement="bottom-center">
                <Routes>
                  <Route path="/login" element={ <LoginPageProxy />}/>
                  <Route path="/termsandconditions" element={<TermsAndConditionsProxy />}/>
                  <Route path="/privacypolicy" element={<PrivacyPolicyProxy />}/>
                  <Route path="" element={<Dashboard />} />
                  <Route path="/resources/:id" element={<Resource />} />
                </Routes>
                <Footer />
              </ToastProvider>
            </DndProvider>
        </FocusedNodeContextProvider>
      </WindowContextProvider>
    </PageContainer>
  );
};

export default App;