import { ajaxActions } from "./AjaxActions"

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

const reopenQuestionnaire = async (id: string): Promise<boolean> => {
  const resp = await ajaxActions.patch(`${BASE_URL}/formSubmissions/${id}`, { submitted: null });
  return resp.ok;
}

export const FormSubmissionS = {
  reopenQuestionnaire,
}