import React from "react";
import TextBlock from "../TextBlock/TextBlock";
import ContractSign from "./ContractSign";
import PositionList from "../Positions/PositionList";
import SubjectDate from "../SubjectDate";
import ContractHead from "./ContractHead";
import FooterContact from "../FooterContact";
import { ResourceContextProvider } from "../../Service/Context/ResourceContext";
import {Space} from "@mantine/core";
import VatNote from "../Document/VatNote";

const Contract = ({
  resource,
  document,
  formSubmission,
  values,
  handleChange,
  reloadFormSubmission,
}) => {
  return (
    <ResourceContextProvider
      resourceId={resource.id}
      contactId={document.contactId}
      customerContactId={document.customerId}
    >
      <ContractHead contract={document} resource={resource} />
      <SubjectDate subject={document.subject} date={document.date} />
      {document.aboveTextBlocks.map((textBlock, index) => {
        return (
          <TextBlock key={"aboveTextBlock" + index} textBlock={textBlock} />
        );
      })}
      <PositionList
        resourceId={resource.id}
        taxType={document.taxType}
        positions={document.positions}
        isSummable={document.summable}
        formSubmission={formSubmission}
        values={values}
        handleChange={handleChange}
        document={document}
      />
      {
        (document.positions && document.positions.length !== 0) &&
          <>
            <Space h={30} />
            <VatNote />
          </>
      }
      {document.belowTextBlocks.map((textBlock, index) => {
        return (
          <TextBlock key={"belowTextBlock" + index} textBlock={textBlock} />
        );
      })}
      <FooterContact contract={document} />
      {document.contractSign !== "NONE" && (
        <ContractSign
          formSubmission={formSubmission}
          customerSignType={document.customerSignType}
          values={values}
          handleChange={handleChange}
          resource={resource}
          reload={reloadFormSubmission}
        />
      )}
    </ResourceContextProvider>
  );
};

export default Contract;
