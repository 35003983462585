
export const dateFormatService = {
    formatDate,
    getDateOfDeLocale
};

function getDateOfDeLocale(germanDateString) {
    let day = germanDateString.substr(0,2);
    let month = germanDateString.substr(3,2);
    let year = germanDateString.substr(6,4);
    return new Date(year, month-1, day, 2); // set hours to 2
}

function formatDate(date, format, splitChar) {
    if (!splitChar) {
        splitChar = '/';
    }

    let dayPlacement = format.toLowerCase().indexOf("dd");
    let monthPlacement = format.toLowerCase().indexOf("mm");
    let yearPlacement = format.toLowerCase().indexOf("yy");

    let month = ((date.getMonth() > 8) ?
        (date.getMonth() + 1) :
        ('0' + (date.getMonth() + 1)));

    let day = ((date.getDate() > 9) ?
        date.getDate() :
        ('0' + date.getDate()));

    let year = date.getFullYear();
    if (!format.includes("yyyy")) {
        year = year.toString().substring(2, 4);
    }

    let compound;

    if (dayPlacement < monthPlacement) {
        if (monthPlacement < yearPlacement) {
            compound = day + splitChar + month + splitChar + year;
        } else {
            if (dayPlacement < yearPlacement) {
                compound = day + splitChar + year + splitChar + month;
            } else {
                compound = year + splitChar + month + splitChar + day;
            }
        }
    } else {
        if (yearPlacement < monthPlacement) {
            compound = year + splitChar + month + splitChar + day;
        } else {
            compound = month + splitChar + year + splitChar + day;
        }
    }

    return compound;

}