import { Group, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { DocumentService } from "../../Service/DocumentService";
import { FormatS } from "../../Service/FormatS";

// eslint-disable-next-line import/no-anonymous-default-export
const PositionSum = ({ isSummable, profile, values, positions, document }) => {
  const { taxType } = document;
  const { smallScaleBusiness } = profile;
  const [summary, setSummary] = useState();

  useEffect(() => {
    setSummary(DocumentService.getDocumentSummary(document, positions, values));
  }, [document, positions, values]);

  if (!summary) {
    return null;
  }
  const {
    sum,
    discountAmount,
    sumAfterDiscount,
    vats,
    discountType,
    discountValue,
    discountDescription,
    finalNetto,
    finalBrutto,
  } = summary;

  return (
    <>
      {isSummable && (
        <Stack spacing={5}>
          {!smallScaleBusiness && (
            <Group position="apart">
              <Text>
                {`Zwischensumme (${taxType === "gross" ? "brutto" : "netto"})`}
              </Text>
              <Text>{FormatS.formatNumberCurrencyWithUnitSign(sum)}</Text>
            </Group>
          )}
          {discountAmount > 0 && (
            <>
              <Group position="apart">
                <Text>
                  {" "}
                  Rabatt {discountType === "PERCENT" && `${discountValue} %`}
                  {discountDescription && <Text span>{`(${discountDescription})`}</Text>}
                </Text>
                <Text>
                  - {FormatS.formatNumberCurrencyWithUnitSign(discountAmount)}
                </Text>
              </Group>
              <Group position="apart">
                <Text>
                  {" "}
                  Gesamtbetrag{" "}
                  {taxType === "gross" ? "inkl. Rabatt" : "(netto)"}
                </Text>
                <Text>
                  {FormatS.formatNumberCurrencyWithUnitSign(sumAfterDiscount)}
                </Text>
              </Group>
            </>
          )}
          {vats &&
            Object.keys(vats).map((key) => {
              return (
                <Group position="apart">
                  <Text>Umsatzsteuer ({key}%)</Text>
                  <Text>
                    {FormatS.formatNumberCurrencyWithUnitSign(vats[key][0])}
                  </Text>
                </Group>
              );
            })}
          {taxType === "gross" && !smallScaleBusiness && (
            <Group position="apart">
              <Text>Gesamtbetrag (netto)</Text>
              <Text>
                {FormatS.formatNumberCurrencyWithUnitSign(finalNetto)}
              </Text>
            </Group>
          )}
          <Group position="apart">
            <Text weight="bolder">Gesamtbetrag</Text>
            <Text weight="bolder">
              {FormatS.formatNumberCurrencyWithUnitSign(
                taxType === "net" ? finalBrutto : sumAfterDiscount
              )}
            </Text>
          </Group>
        </Stack>
      )}
    </>
  );
};

export default PositionSum;
