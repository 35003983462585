import React, { useContext } from "react";
import { LoadingAnimation } from "../../Service/ResourceAdapter";
import { WindowContext } from "../../Service/Context/WindowContext";
import QuestionItem from "./QuestionItem";

export default ({ formSubmission, questions, values, handleChange }) => {
  const { windowWidth } = useContext(WindowContext);

  if (!questions) {
    return <LoadingAnimation />;
  }

  let styles = {
    container: {
      marginTop: 20,
      marginBottom: 20,
    },
    firstColumn: {
      width: 100,
      display: "flex",
      justifyContent: "center",
    },
    textColumn: {
      flex: 1,
    },
    priceColumn: {
      width: windowWidth < 800 ? 80 : windowWidth > 1100 ? 150 : 100,
      textAlign: "right",
    },
  };

  return (
    <div style={styles.container}>
      {questions.map((question, index) => {
        return (
          <QuestionItem
            key={index}
            formSubmission={formSubmission}
            values={values}
            question={question}
            handleChange={handleChange}
            columnStyles={styles}
          />
        );
      })}
    </div>
  );
};
