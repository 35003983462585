import {Label} from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import {Trans} from "@lingui/react";

export const SimpleLabelWrapper = ({startEditCallback, children, placeholder, ...rest}) => (
    <div className="editable" onClick={startEditCallback} onFocus={startEditCallback}
         style={{}} {...rest}>
        {children}
        {placeholder && <Label sm={12}><Trans id={placeholder || ''}/></Label>}
    </div>
);

SimpleLabelWrapper.propTypes = {
    startEditCallback: PropTypes.func,
    children: PropTypes.node,
};