import {EditableFormAttributeValueProperty} from "../../Atoms/EditableProperties/EditableFormAttributeValueProperty";
import {EditableCheckbox} from "../../Atoms/inputs/EditableCheckbox";
import {EditableTextBlockWrapper} from "../../Atoms/inputs/EditableTextBlockWrapper";
import {TextBlockFormatter} from "../../Atoms/Formatter/TextBlockFormatter";
import React, {useEffect, useState} from "react";


export default ({questionId, handleChange, formSubmissionId, lastSubmitted, values, patchField, answers, additionalAnswer}) => {
    const [additionalValueSelected, setAdditionalValueSelected] = useState(additionalAnswer ? (values[additionalAnswer.id] !== undefined && values[additionalAnswer.id]) : false);
    useEffect(() => {
        setAdditionalValueSelected(additionalAnswer ? (values[additionalAnswer.id] !== undefined && values[additionalAnswer.id]) : false)
    }, [additionalAnswer, values, patchField])


    return (
        <>
        {answers.map((answer, index) => {
            return <div key={index}>
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmissionId}
                    patchField={patchField}
                    placeholder={answer.value}
                    changeHandler={(p) => {handleChange(patchField, p[patchField])}}
                    entity={values}
                    multiple={true}
                    values={typeof values[questionId] === "string"  ? values[questionId].split("##") : []}
                    editFormatter={EditableCheckbox}
                    labelValue={answer.value}
                    editOnly={true}
                    disabled={lastSubmitted}
                />
            </div>
        })}
        {additionalAnswer &&
            <>
                <EditableFormAttributeValueProperty
                    formSubmissionId={formSubmissionId}
                    patchField={additionalAnswer.id}
                    placeholder={"question.answer.other"}
                    changeHandler={(p) => {
                        setAdditionalValueSelected(!additionalValueSelected)
                        handleChange(additionalAnswer.id, !additionalValueSelected)
                    }}
                    entity={values}
                    value={additionalValueSelected}
                    editFormatter={EditableCheckbox}
                    labelValue={"question.answer.other"}
                    editOnly={true}
                    disabled={lastSubmitted}
                />
                {additionalValueSelected &&
                    <div style={{marginTop: 10,}}>
                        <EditableFormAttributeValueProperty
                            formSubmissionId={formSubmissionId}
                            patchField={additionalAnswer.id}
                            changeHandler={(p) => {
                                handleChange(additionalAnswer.id, p[additionalAnswer.id])
                            }}
                            entity={values[additionalAnswer.id] ? {[additionalAnswer.id]: (values[additionalAnswer.id] === true ? "" : values[additionalAnswer.id])} : undefined}
                            big
                            editFormatter={EditableTextBlockWrapper}
                            labelFormatter={TextBlockFormatter}
                            disabled={lastSubmitted}
                        />
                    </div>
                }
            </>
        }
    </>
    )
}